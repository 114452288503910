import React from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

const TableOfContents = () => {
   const contents = [
      'Net Metering and Incentives',
      'Next Steps',
      'Conclusion',
      'Executive Summary',
      'Client Current Electricity Consumption',
      'A Sub Heading Can Go Here',
      'Second Sub Heading Here',
      'Another Section Heading here',
      'Sub Heading Here',
      'Another Heading For this New Page',
      'Sub Heading For some kind of Contact Maybe',
   ];

   return (
      <List>
         <Typography variant='h4' color='steelblue'>
            Executive Summary
         </Typography>
         {contents.map((content, index) => (
            <>
               <ListItem key={index}>
                  <ListItemText
                     primary={
                        <div
                           style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              width: '100%',
                           }}
                        >
                           <span>{content}</span>
                           <span style={{ fontWeight: 'bold' }}>
                              {index + 2}
                           </span>
                        </div>
                     }
                  />
               </ListItem>
               <Divider variant='middle' />
            </>
         ))}
      </List>
   );
};

export default TableOfContents;
