import { Container, Grid, Typography, Button, Stack } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import Tab from "@mui/material/Tab";
import SliderWIthThumbnail from "../../components/SliderWIthThumbnail/SliderWIthThumbnail";
import { Navigate, useNavigate, useParams } from "react-router";
import { axiAuth } from "../../utils/axiosInstance";
import { toast } from "react-toastify";
import { DownloadChip } from "../../components/CustomerDetailsDrawer/customerDetailsDrawer.style";
import { useScrollPosition } from "../../hooks/useScrollPosition";
import PrimaryButton from "../../components/Custom/PrimaryButton/PrimaryButton";
import noImage from "../../assets/no-image.jpeg";
import PageTitle from "../../components/PageTitle/PageTitle";
import EmbedYTVideo from "../../components/EmbedYTVideo/EmbedYTVideo";
import EnquiryProductDetails from "../EnquiryPage/EnquiryProductDetails";
import {
  DocumentBox,
  TabPanelDoc,
  ProductFeatures,
  FeatureItemBox,
  FeatureBox,
} from "../EnquiryPage/enquiryPage.style";
import { useDispatch, useSelector } from "react-redux";

import SolrufModal from "../../components/Custom/SolrufModal/SolrufModal";
import CheckoutModalContent from "../../components/CheckoutModalContent/CheckoutModalContent";
import { addToCart } from "../../redux/slices/newCartSlice/newCartSlice";
import { openLoginModal } from "../../redux/slices/loginModalSlice";

const noImages = [noImage, noImage, noImage, noImage, noImage, noImage];

const PurchasePage = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { newCart } = useSelector((state) => state.newCart);

  console.log(newCart);

  const [documentTab, setDocumentTab] = useState(0);
  const handleDocumentTabChange = (_event, newValue) => {
    setDocumentTab(newValue);
  };

  // const { productSlug } = useParams();
  const { productId } = useParams();
  const [productDetails, setProductDetails] = useState({});
  const [productLoading, setProductLoading] = useState(true);
  const [viewAll, setViewAll] = useState(false);
  const scrollPosition = useScrollPosition();

  const [buyModalOpen, setBuyModalOpen] = useState(false);
  const toggleBuyModal = (e, reason) => {
    if (reason === "backdropClick") return;
    setBuyModalOpen(!buyModalOpen);
  };

  const navigate = useNavigate()
  useEffect(() => {
    setProductLoading(true);
    axiAuth(`api/products/get/details/${productId}`)
      .then((res) => {
        setProductDetails(res?.data?.product);
        setProductLoading(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setProductLoading(false);
      });
  }, [productId]);

  const existInCart = newCart.find(
    (cartItem) => cartItem.product_id === productDetails.product_id
  );

  const addToCartHandler = async () => {
    if (!user) {
      dispatch(openLoginModal());
      return;
    }

    try {
      const { status } = await axiAuth.post("/api/shoppingcarts", {
        carts: [
          {
            product_id: productDetails.product_id,
            vendor_id: 87,
            quantity: 1,
          },
        ],
      });

      if (status === 200) {
        dispatch(addToCart({ ...productDetails, quantity: 1 }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box sx={{ mb: 4, background: "#f3f3f3", pb: 3 }}>
      <PageTitle
        title={productDetails?.product_name ? productDetails?.product_name : ""}
      />
      <Container maxWidth="xl">
        <Box>
          <>
            <Grid
              container
              spacing={5}
              sx={{
                mt: {
                  xs: "0",
                  sm: "0",
                },
              }}
            >
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box sx={{ mb: 3, maxWidth: 500, mx: "auto" }}>
                  <SliderWIthThumbnail
                    images={
                      productDetails?.images?.length > 0
                        ? productDetails?.images.map((img) => img.image_url)
                        : noImages
                    }
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                {productDetails && !productLoading && (
                  <EnquiryProductDetails productDetails={productDetails} />
                )}
              </Grid>
            </Grid>

            {/* features */}

            <Typography variant="h4" sx={{ my: 2 }}>
              Features
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "start",
                gap: 5,
                flexWrap: "wrap",
                mt: [5, 3],
                width: "100%",
              }}
            >
              {productDetails?.features?.map((feature) => (
                <Box
                  key={feature.id}
                  sx={{
                    display: "flex",
                    alignItems: "start",
                    flexDirection: "row",
                    justifyContent: "start",
                    gap: 2,
                    "& img": {
                      width: "1.5rem",
                    },
                    width: "100%",
                    // maxWidth: "10rem",
                  }}
                >
                  <img src={feature.icon} alt="Icon" />
                  <Typography
                    sx={{
                      fontSize: "0.8rem",
                      fontWeight: 600,
                      textAlign: "start",
                    }}
                  >
                    {feature.title}
                  </Typography>
                </Box>
              ))}
            </Box>

            <Stack
              sx={{
                mt: 3,
                width: "100%",
                maxWidth: "800px",
                mx: "auto",
              }}
              direction="row"
              gap={1}
            >
              {!existInCart && (
                <PrimaryButton
                sx={{
                  mx: "auto",
                  width: 300,

                  py: 2,
                  px: 5,
                }}
                // disabled={!!existInCart}
                onClick={addToCartHandler}
              >
                Buy Now
              </PrimaryButton>
              )}
              {existInCart && (
                <PrimaryButton
                sx={{
                  mx: "auto",
                  width: 300,
                  py: 2,
                  px: 5,
                }}
                onClick={() => navigate('/checkout')}
              >
                Go to Cart
              </PrimaryButton>
              )}
              {/* <PrimaryButton
                sx={{
                  mx: "auto",
                  width: 300,
                  py: 2,
                  px: 5,
                }}
                onClick={() => setBuyModalOpen(true)}
              >
                Buy Now
              </PrimaryButton>
              <PrimaryButton
                sx={{
                  mx: "auto",
                  width: 300,

                  py: 2,
                  px: 5,
                }}
                disabled={!!existInCart}
                onClick={addToCartHandler}
              >
                Add To Cart
              </PrimaryButton> */}
            </Stack>

            {/* video section */}
            {productDetails?.video_url && (
              <EmbedYTVideo
                videoUrl={productDetails?.video_url}
                sx={{ mt: 6 }}
              />
            )}

            {/*  =========  document section ========= */}
            <Box
              sx={{
                background: "#fff",
                p: 2,
                borderRadius: "8px",
                boxShadow: "0px 4px 24px  0 rgba(0, 69, 184, 0.15)",
                mt: 6,
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                  color: "#000",
                  mb: 2,
                }}
              >
                Other Details
              </Typography>

              <Grid container>
                <Grid item xs={12} md={6} lg={3}>
                  <Box>
                    <TabPanelDoc
                      value={documentTab}
                      onChange={handleDocumentTabChange}
                      centered
                      orientation={
                        window.innerWidth < 600 ? "horizontal" : "vertical"
                      }
                    >
                      <Tab label="Product Document" />
                      <Tab label="Warranty Card" />
                    </TabPanelDoc>
                  </Box>
                </Grid>
                <Grid item md={6} lg={9}>
                  <DocumentBox>
                    <Box sx={{ p: 2 }}>
                      {documentTab === 0 && (
                        <Box>
                          {productDetails?.my_documents?.length > 0 &&
                            productDetails.my_documents
                              .filter((doc) => doc.doc_type === "product")
                              .map(({ doc_name, doc_id, doc_url }) => (
                                <DownloadChip
                                  key={doc_id}
                                  label={doc_name}
                                  sx={{ mr: 1 }}
                                  onClick={() => {}}
                                  component="a"
                                  href={doc_url}
                                  target="_blank"
                                />
                              ))}
                          <Typography
                            variant="body1"
                            sx={{ mt: 2, textAlign: "left" }}
                          >
                            <strong>Description: </strong>{" "}
                            {/* {productDetails.product_description} */}
                          </Typography>

                          <Typography
                            variant="body1"
                            sx={{ mt: 2 }}
                            dangerouslySetInnerHTML={{
                              __html: productDetails.product_description,
                            }}
                          ></Typography>
                        </Box>
                      )}
                      {documentTab === 1 && (
                        <Box>
                          {productDetails.my_documents?.length > 0 &&
                            productDetails.my_documents
                              .filter((doc) => doc.doc_type === "warranty")
                              .map(({ doc_name, doc_id, doc_url }) => (
                                <DownloadChip
                                  key={doc_id}
                                  label={doc_name}
                                  sx={{ mr: 1 }}
                                  onClick={() => {}}
                                  component="a"
                                  href={doc_url}
                                  target="_blank"
                                />
                              ))}

                          <Typography variant="body1" sx={{ mt: 2 }}>
                            <strong>Description: </strong>{" "}
                            <Typography
                              variant="body1"
                              sx={{ mt: 2 }}
                              dangerouslySetInnerHTML={{
                                __html:
                                  productDetails.product_warranty_description,
                              }}
                            ></Typography>
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </DocumentBox>
                </Grid>
              </Grid>
            </Box>
            {/* product feature section */}
            <ProductFeatures>
              <Box className="featuresHeader">
                <Typography variant="h6">Product Features</Typography>
                <Button
                  sx={{ color: "#2448FC" }}
                  onClick={() => {
                    setViewAll(!viewAll);
                  }}
                >
                  {viewAll ? "View Less" : "View All"}
                </Button>
              </Box>
              <FeatureBox>
                {productDetails.attributes?.length > 0 && (
                  <>
                    {viewAll
                      ? productDetails.attributes
                          .filter(
                            (attribute) =>
                              attribute?.attribute_values[
                                attribute?.attribute_values?.length - 1
                              ]?.views?.product_feature_list?.visibility
                          )
                          .map((attribute, index) => (
                            <FeatureItemBox key={index} i={index}>
                              <Typography variant="body">
                                {attribute.name}
                              </Typography>
                              <Typography variant="body">
                                {`${attribute.attribute_values[0].value} ${
                                  attribute.attribute_values[0]?.value_unit ===
                                  "-"
                                    ? ""
                                    : attribute.attribute_values[0]?.value_unit
                                }`}
                              </Typography>
                            </FeatureItemBox>
                          ))
                      : productDetails.attributes
                          .filter(
                            (attribute) =>
                              attribute?.attribute_values[
                                attribute?.attribute_values?.length - 1
                              ]?.views?.product_feature_list?.visibility
                          )
                          .slice(0, 4)
                          .map((attribute, index) => (
                            <FeatureItemBox key={index} i={index}>
                              <Typography variant="body">
                                {attribute.name}
                              </Typography>
                              <Typography variant="body">
                                {`${attribute.attribute_values[0].value} ${
                                  attribute.attribute_values[0]?.value_unit ===
                                  "-"
                                    ? ""
                                    : attribute.attribute_values[0]?.value_unit
                                }`}
                              </Typography>
                            </FeatureItemBox>
                          ))}
                  </>
                )}
              </FeatureBox>
            </ProductFeatures>
          </>
        </Box>
      </Container>
      {/* bottom fixed button */}
      {scrollPosition > 1500 && (
        <PrimaryButton
          onClick={() => {
            // scroll to top y position
            window.scrollTo(0, 800);
          }}
          sx={{
            width: "100%",
            position: "fixed",
            bottom: 0,
            left: 0,
            zIndex: 1,
            display: {
              xs: "block",
              sm: "none",
            },
          }}
        >
          Place Order
        </PrimaryButton>
      )}

      <SolrufModal
        modal
        open={buyModalOpen}
        onClose={toggleBuyModal}
        sx={{
          maxWidth: 800,
          maxHeight: "90%",
          overflow: "scroll",
        }}
        disableEscapeKeyDown={true}
      >
        <CheckoutModalContent productDetails={productDetails} />
      </SolrufModal>
    </Box>
  );
};

export default PurchasePage;
