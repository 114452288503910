import React from "react";
import SolarCalculator from "../../components/SolarCalculator/SolarCalculator";
import { Container, Typography, Box } from "@mui/material";
import PrimaryButton from "../../components/Custom/PrimaryButton/PrimaryButton";
import { useNavigate } from "react-router";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const SolarCalculatorPage = () => {
  const navigate = useNavigate();
  return (
    <Container>
      {/* <Typography variant="h2" component="h1" textAlign="center" mt={5}>
        Solar EMI Calculator
      </Typography>
      <PrimaryButton
        onClick={() => {
          navigate("/solar-enquiry-form");
        }}
        sx={{
          mx: "auto",
          mt: 10,
          width: "100%",
          py: 2,
          fontSize: "1.5rem",
        }}
        IconEnd={OpenInNewIcon}
      >
        Create Your Solar Product Enquiry
      </PrimaryButton> */}
      <SolarCalculator />
      {/* <Box sx={{ my: 2 }}>
        <Typography textAlign={"center"} variant="h4">
          The calculator is being cooked!
        </Typography>
      </Box> */}
    </Container>
  );
};

export default SolarCalculatorPage;
