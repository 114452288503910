import React from 'react';
import { Box, Typography } from '@mui/material';
// import ReviewSlider from './ReviewSlider';
import ReviewSliderResponsive from './ReviewSliderResponsive';



const ReviewSection = () => {
   return (
      <Box sx={{ pt: [0,10], pb: 10 }}>
         <Box
            sx={{
               padding: '20px',
               borderRadius: '8px',
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               backgroundImage: `url(${'https://i.ibb.co/pn7CVFP/100-Image-2.png'})`, 
               // backgroundImage: `url(${number})`, 
               backgroundSize: 'contain',
               backgroundRepeat: 'no-repeat',
               backgroundPosition: 'center',
               minHeight: 300,
            }}
         >
            <Typography
               variant='h4'
               sx={{
                  position: 'relative', // To place the text above the background image
                  zIndex: 1, // To place the text above the background image
                  margin: 0,
                  fontWeight: 600
               }}
            >
               100 + Happy Customers
            </Typography>
         </Box>
       <ReviewSliderResponsive />
      </Box>
   );
};

export default ReviewSection;
