import React from 'react';
import { TypeAnimation } from 'react-type-animation';
import { Box, Typography, Divider, Stack, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const HomeSectionTitle = ({ fixed, sequence = [], route = '/' }) => {
   const matchSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));

   return (
      <Box sx={{ mb: 2 }}>
         <Stack direction='row' justifyContent={'space-between'}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
               <Typography
                  variant='h4'
                  sx={{
                     fontSize: {
                        xs: 16,
                        md: 30,
                     },
                  }}
               >
                  {fixed}
               </Typography>
               {sequence.length !== 0 && !matchSm && (
                  <TypeAnimation
                     sequence={sequence}
                     wrapper='span'
                     speed={20}
                     style={{
                        fontSize: matchSm ? '0.8rem' : '2rem',
                        display: 'inline-block',
                        fontWeight: 600,
                     }}
                     repeat={Infinity}
                  />
               )}
            </Box>

            <Stack direction='row' gap={1} alignItems='center'>
               <Typography
                  variant='body'
                  fontWeight={600}
                  sx={{
                     '& a': { color: 'primary.dark' },
                     fontSize: {
                        xs: 12,
                        sm: 16,
                        md: 18,
                     },
                  }}
               >
                  <Link to={route}>View All</Link>
               </Typography>
               <ArrowForwardIosIcon sx={{ width: 15 }} />
            </Stack>
         </Stack>

         <Divider
            sx={{
               position: 'relative', // Set position relative for absolute positioning of ::after
               // '&::after': {
               //    content: '""',
               //    position: 'absolute',
               //    top: 0,
               //    left: 0,
               //    border: '2px solid #000000', // Yellow border
               //    width: '10%',
               //    boxSizing: 'border-box', // Include border in the dimensions
               //    //   width: 'calc(100% + 8px)', // 100% + 2 times border width
               //    height: 'calc(100% + 4px)', // 100% + 2 times border width
               //    margin: '-2px', // Negative margin to make the ring effect
               // },
            }}
         />
      </Box>
   );
};

export default HomeSectionTitle;
