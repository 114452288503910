import React from 'react';
import AnalysisForm from '../home/NewHomePage/AnalysisForm';

const ContactUsPage = () => {
   return (
      <div>
         {/* <ContactUs /> */}
         <AnalysisForm />
      </div>
   );
};

export default ContactUsPage;
