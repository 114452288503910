import axios from 'axios';
import { axiAuth } from '../../utils/axiosInstance';

export const loginToShipRocket = async () => {
   const {
      data: { token },
   } = await axios.post('https://apiv2.shiprocket.in/v1/external/auth/login', {
      email: 'mizanmahi24@gmail.com',
      password: '541990Mizan@',
   });

   return token;
};

export const makeCodApiCall = async (
   price,
   formData,
   couponInfo,
   minFreightCharge,
   productDetails,
   grandTotal
) => {
   try {
      const { data } = await axiAuth.post('api/product-orders', {
         product_id: productDetails.product_id,
         payment_method: 'cod',
         payment_status: 'unpaid',
         delivery_status: '',
         price: price,
         discount: couponInfo.less,
         coupon: couponInfo.title,
         delivery_charge: minFreightCharge,
         grand_total: Math.round(grandTotal + minFreightCharge),
         customer_name: formData.name,
         customer_mobile: formData.phone,
         pincode: formData.pincode,
         city: formData.city,
         state: formData.state,
         address: formData.address,
      });

      return data.data;
   } catch (error) {
      console.error(error);
   }
};
