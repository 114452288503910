import React, { useState, useEffect } from 'react';
import { axiAuth } from '../../../utils/axiosInstance';
import { DownloadChip } from '../../../components/CustomerDetailsDrawer/customerDetailsDrawer.style';

function VendorDocuments({ vendorId }) {
   const [documents, setDocuments] = useState([]);
   const [loading, setLoading] = useState(true);
   const [error, setError] = useState('');

   useEffect(() => {
      if (!vendorId) return;

      async function fetchDocuments() {
         try {
            const { status, data } = await axiAuth(
               `/api/nbfc/nbfc-documents?vendor_id=${vendorId}`
            );
            if (status === 200) {
               setDocuments(data.data);
               setLoading(false);
            }
         } catch (error) {
            setError('Error fetching documents.');
            setLoading(false);
         }
      }
      fetchDocuments();
   }, [vendorId]);

    

   if (loading) {
      return <div>Loading...</div>;
   }

   if (error) {
      return <div>{error}</div>;
   }

   return (
      <div>
         {documents.map(({ id, name, url }) => (
            // <div key={document.id}>{document.name}</div>
            <DownloadChip
               key={id}
               label={name}
               sx={{ mr: 1 }}
               onClick={() => {}}
               component='a'
               href={url}
               target='_blank'
            />
         ))}
      </div>
   );
}

export default VendorDocuments;
