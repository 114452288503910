import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
// https://i.ibb.co/SX6p2d1/Rectangle-33-2.png
// https://i.ibb.co/ckpg60z/Rectangle-33-1.png
const TrendingCard = ({ title, desc, route, img, timeAgo, author }) => {
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        borderRadius: "10px",
        boxShadow: 0,
        width: 400,
        maxWidth: "100%",
      }}
    >
      <CardMedia
        component="img"
        height="255"
        image={img}
        alt="Image Alt Text"
        sx={{ borderRadius: "10px 10px 0 0", objectFit: "cover" }}
      />
      <CardContent sx={{ p: 0, mt: 2, width: "100%" }}>
        <Box sx={{ width: "100%" }}>
          <Typography variant="body2" fontWeight={600} color="#6E6E6E">
            {author} &nbsp;&nbsp;&nbsp; - {timeAgo}
          </Typography>
          <Typography variant="h6" fontWeight={600}>
            {title.length > 28 ? title.slice(0, 28) + "..." : title}
          </Typography>

          <Typography variant="body2" fontWeight={300} color={"#6E6E6E"}>
            {desc.length > 260 ? desc.slice(0, 260) + "..." : desc}
          </Typography>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            sx={{ mt: 2 }}
          >
            {/* <Typography variant="body2" fontWeight={600} color="#6E6E6E">
              12 Min Read
            </Typography> */}
            <Typography
              variant="body2"
              fontWeight={600}
              color="#000000"
              sx={{ textDecoration: "underline", cursor: "pointer" }}
              // onClick={() => navigate(route)}
              onClick={() => window.open(route, "_blank")}
            >
              Read More
            </Typography>
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
};

export default TrendingCard;
