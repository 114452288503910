import React from "react";
import { Box, Typography, Container, Stack } from "@mui/material";
import FourBlog from "./BlogComponents/FourBlog";
import NewTrending from "./BlogComponents/NewTrending";
import ShortReads from "./BlogComponents/ShortReads";
import BottomBlogsSection from "./BlogComponents/BottomBlogsSection";
import AnalysisForm from "../home/NewHomePage/AnalysisForm";
import BannerCarousel from "../home/NewHomePage/BannerCarousel/BannerCarousel";

const boxStyle = {
  height: "800px",
  backgroundImage: `
   url("https://solruf-node-static-files.s3.ap-south-1.amazonaws.com/Group+1.png")`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "left",
  gap: 3,
  p: 5,
};
const NewBlogsHome = () => {
  return (
    <Box>
      <Box sx={{ ...boxStyle }}>
        <Box sx={{ width: "40%" }}>
          <Typography variant="h1" fontWeight={600} color={"#ffffff"}>
            Welcome to Solruf
          </Typography>

          <Typography
            variant="subtitle"
            fontWeight={600}
            color={"#ffffff"}
            sx={{ fontSize: "1.2rem" }}
          >
            Solruf is a Solar Marketplace connecting the Consumers with its EPC
            Channel Partners and digitises the existing Installation process.
          </Typography>
        </Box>
      </Box>
      <Container>
        <FourBlog />
        <NewTrending />
        <ShortReads />
        {/* <BottomBlogsSection /> */}
      </Container>
      <Stack direction={"column"} gap={8}>
        {/* <BannerCarousel /> */}
        <AnalysisForm />
      </Stack>
    </Box>
  );
};

export default NewBlogsHome;
