import { Box, Container, Stack, Typography } from '@mui/material';
import React from 'react';
import TableOfContents from './TableOfContents';

const Quote = () => {
   return (
      <Container sx={{ my: 5 }}>
         <Stack rowGap={3}>
            <Typography variant='h4' color='steelblue'>
               Executive Summary
            </Typography>
            <p>
               The "Unlocking Savings Potential: A Personalized Solar Financial
               Analysis" report offers a data-driven exploration of how solar
               energy can transform your financial landscape. Through
               personalized insights into your electricity consumption, cost
               breakdowns, and potential savings, this report empowers you to
               make informed decisions.
            </p>
            <p>
               By harnessing the power of solar energy, you can tap into
               substantial cost savings, secure long-term financial gains, and
               shape a sustainable energy future. For a deeper understanding of
               the financial advantages that solar adoption brings, we invite
               you to delve into the comprehensive report and embark on a path
               to a more financially resilient future.
            </p>
         </Stack>

         <Box sx={{width: 800, maxWidth: '100%'}}>
            <TableOfContents />
         </Box>

         <Stack rowGap={3}>
            <Typography variant='h4' color='steelblue'>
            Client Current Electricity Consumption
            </Typography>
            <p>
               The "Unlocking Savings Potential: A Personalized Solar Financial
               Analysis" report offers a data-driven exploration of how solar
               energy can transform your financial landscape.
               
            </p>
            <p>
               By harnessing the power of solar energy, you can tap into
               substantial cost savings, secure long-term financial gains, and
               shape a sustainable energy future. For a deeper understanding of
               the financial advantages that solar adoption brings, we invite
               you to delve into the comprehensive report and embark on a path
               to a more financially resilient future.
            </p>
         </Stack>
      </Container>
   );
};

export default Quote;
