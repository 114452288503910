import { Box, Stack, styled, Typography, useMediaQuery } from '@mui/material';
import HomeSectionTitle from '../../../../components/HomeSectionTitle/HomeSectionTitle';
import { productCategories } from '../../productsCategories/productCategoriesData';
import { setProductData } from '../../../../redux/slices/viewProductSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import CategoriesCarousel from './CategoriesCarousel';
import { toSlug } from '../../../Procurement/ProcurementCategories';

export const CircularBox = styled(Box)(({ theme }) => ({
   width: 190,
   height: 190,
   borderRadius: '50%',
   overflow: 'hidden',
   '& img': {
      width: '100%',
      height: 'auto',
   },
   margin: '0 auto',
   boxShadow: theme.shadows[25],
   padding: theme.spacing(1),
   [theme.breakpoints.down('sm')]: {
      width: 120,
      height: 120,
   },
}));

const Categories = () => {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const matchSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));

   return (
      <Box sx={{ my: 7 }}>
         <HomeSectionTitle
            fixed='Shop From Top Categories:'
            route='/products'
            sequence={[
               // Same substring at the start will only be typed out once, initially
               'Solar Panel',
               1000, // wait 1s before replacing "Mice" with "Hamsters"
               'Solar Inverter',
               1000,
               'Dc Cable',
               1000,
               'Solar Battery',
               1000,
               'Solar Charge Controller',
               1000,
               'Solar Kit',
               1000,
            ]}
         />

         {matchSm ? (
            <CategoriesCarousel
               productCategories={productCategories}
               navigate={navigate}
               dispatch={dispatch}
            />
         ) : (
            <Stack
               direction='row'
               gap={{ xs: 2, sm: 2, md: 5 }}
               sx={{
                  mt: 8,
                  justifyContent: 'center',
                  alignItems: { xs: 'center', md: 'flex-start' },
               }}
               flexWrap='wrap'
            >
               {productCategories.map((cat) => (
                  <Box
                     onClick={() => {
                        dispatch(
                           setProductData({
                              productCategoryData: cat.CategoryId,
                              productSubCategoryData: cat.SubCategoryId,
                           })
                        );
                        navigate(`/products?category=${toSlug(cat?.name)}`);
                     }}
                     sx={{
                        '&:hover': {
                           transform: 'scale(1.1)',
                           transition: 'ease-in 0.3s',
                           cursor: 'pointer',
                        },
                     }}
                  >
                     <CircularBox>
                        <img src={cat.image} alt='' />
                     </CircularBox>
                     <Typography
                        variant='body'
                        fontWeight={600}
                        mt={4}
                        sx={{ textAlign: 'center', display: 'block' }}
                     >
                        {cat.name}
                     </Typography>
                  </Box>
               ))}
            </Stack>
         )}
      </Box>
   );
};

export default Categories;
