import * as React from 'react';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import MessageIcon from '@mui/icons-material/Message';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import { useNavigate } from 'react-router';
import { IconButton, styled, useMediaQuery } from '@mui/material';
import { goToWhatsapp } from '../../utils/utils';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
   position: 'fixed',
   bottom: '6rem',
   right: '2.1rem',
   zIndex: 1000000,
   background: theme.palette.primary.main,
   color: theme.palette.primary.dark,
   width: 55,
   height: 55,
   '&:hover': {
      background: theme.palette.primary.main,
   },
}));

const actions = [
   { icon: <WhatsAppIcon />, name: 'Whatsapp' },
   { icon: <MarkChatReadIcon />, name: 'Solar Enquiry Form' },
];

export default function WhatsappSpeedDialButton() {
   const matchSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));

   const navigate = useNavigate();
   const [open, setOpen] = React.useState(false);
   const handleOpen = () => setOpen(true);

   const handleClose = (name) => {
      if (name === 'Whatsapp') {
         goToWhatsapp();
         setOpen(false);
      } else if (name === 'Solar Enquiry Form') {
         setOpen(false);
         navigate('/solar-enquiry-form');
      } else {
         setOpen(false);
      }
   };

   return (
      <>
         {matchSm ? (
            <StyledIconButton
               aria-label='delete'
               onClick={() => goToWhatsapp()}
            >
               <WhatsAppIcon />
            </StyledIconButton>
         ) : (
            <Box
               sx={{
                  position: 'fixed',
                  bottom: '5rem',
                  right: '1rem',
                  zIndex: 1000000,
               }}
            >
               <SpeedDial
                  ariaLabel='SpeedDial controlled open example'
                  sx={{
                     position: 'absolute',
                     bottom: 16,
                     right: 12,

                     '& svg': {
                        color: 'primary.dark',
                     },
                     '& .MuiFab-sizeLarge': {
                        boxShadow:
                           '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
                     },
                     '&:hover': {
                        '& .MuiFab-circular': {
                           bgcolor: 'primary.dark',
                        },
                        '& .MuiFab-sizeLarge': {
                           bgcolor: 'primary.main',

                           '& .MuiSvgIcon-fontSizeMedium': {
                              color: 'primary.dark',
                           },
                        },
                        '& svg': {
                           color: 'primary.main',
                        },
                     },
                  }}
                  icon={<MessageIcon />}
                  onClose={handleClose}
                  onOpen={handleOpen}
                  open={open}
               >
                  {actions.map((action) => (
                     <SpeedDialAction
                        sx={{
                           width: 50,
                           height: 50,
                        }}
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={() => handleClose(action.name)}
                     />
                  ))}
               </SpeedDial>
            </Box>
         )}
      </>
   );
}
