import { Swiper } from 'swiper/react/swiper-react';
import { Navigation, Autoplay, Pagination } from 'swiper';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box } from '@mui/material';
import './categorySlider.css';

export default function ReviewSlider({ children }) {
   return (
      <>
         <Swiper
            modules={[Navigation, Autoplay]}
            className='category-slider w-fit'
            pagination={{
               dynamicBullets: true,
               clickable: true,
            }}
            keyboard={{
               enabled: true,
            }}
            autoplay={{
               delay: 3000,
               disableOnInteraction: false,
               pauseOnMouseEnter: true,
            }}
            navigation={{
               nextEl: '.swiper-button-next',
               prevEl: '.swiper-button-prev',
            }}
            breakpoints={{
               1024: {
                  slidesPerView: 'auto',
                  spaceBetween: 5,
               },
               960: {
                  slidesPerView: 'auto',
                  spaceBetween: 5,
               },
               640: {
                  slidesPerView: 'auto',
                  spaceBetween: 5,
               },
               0: {
                  slidesPerView: 'auto',
                  spaceBetween: 5,
               },
            }}
         >
            {children}

            <Box
               sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: 'fit-content',
                  mx: 'auto',
               }}
            >
               <div className='swiper-button-prev'>
                  <ChevronLeftIcon />
               </div>
               <div className='swiper-button-next'>
                  <ChevronRightIcon />
               </div>
            </Box>
         </Swiper>
      </>
   );
}
