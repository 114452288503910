import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';


const TextFieldWithSearch = ({data, value, setValue, label,adminAttribute, sx}) => {
//   const [value, setValue] = useState(null);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Autocomplete
      value={value}
      onChange={handleChange}
      options={data}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          fullWidth
          sx={{
            ...sx
          }}
        />
      )}
    />
  );
};

export default TextFieldWithSearch;