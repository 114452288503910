import { Box, Container, Typography } from "@mui/material";
import React from "react";

import Lottie from "lottie-react";
import sent from "../../assets/lottie/sent.json";
import {
  Background,
  ThanksWrapper,
  Wrapper,
} from "../../components/HomeLeadForm/HomeLeadForm.style";

const Thankyou = ({ ...rest }) => {
  // useEffect(() => {
  //   if (window.dataLayer) {
  //     window.dataLayer.push({
  //       event: "conversion_event_submit_lead_form_2",
  //     });
  //   }
  // }, []);
  return (
    <Container>
      <Box
        sx={{
          mb: 10,
          mt: 5,
        }}
      >
        <Wrapper>
          <Background>
            <ThanksWrapper>
              <Lottie
                animationData={sent}
                loop={true}
                style={{ height: "20rem", marginBottom: "4rem" }}
              />
              <Typography
                variant="h3"
                sx={{
                  "@media (max-width: 600px)": {
                    fontSize: "1.3rem",
                  },
                }}
                fontWeight={600}
              >
                Thanks for submitting
              </Typography>
              <Typography
                variant="h5"
                fontWeight={400}
                sx={{
                  "@media (max-width: 600px)": {
                    fontSize: "1rem",
                  },
                }}
              >
                You will be hearing from us soon!
              </Typography>
            </ThanksWrapper>
          </Background>
        </Wrapper>
      </Box>
    </Container>
  );
};

export default Thankyou;
