import { Outlet, Navigate, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const EnterpriseOutlet = () => {
   const { user, role } = useAuth();
   const location = useLocation();
   
   if ((user && role === "Manufacturer") || (user && role === 'NBFC')) return <Outlet />;
   return (
      <Navigate
         to='/enterprise-login'
         state={{
            from: location.pathname,
         }}
      />
   );
};

export default EnterpriseOutlet;
