import { Typography } from '@mui/material';
import React from 'react';
import { BrandsSlip, Overlay, SliderWrapper, Wrapper } from './Brands.style';
import { motion } from 'framer-motion';

var brands = [
   'https://solruf-node-static-files.s3.ap-south-1.amazonaws .com/brands/Partner+Brands+1.png',
   'https://solruf-node-static-files.s3.ap-south-1.amazonaws.com/brands/Partner+Brands+2.png',
   'https://solruf-node-static-files.s3.ap-south-1.amazonaws.com/brands/Partner+Brands+3.png',
   'https://solruf-node-static-files.s3.ap-south-1.amazonaws.com/brands/Partner+Brands+4.png',
   'https://solruf-node-static-files.s3.ap-south-1.amazonaws.com/brands/Partner+Brands+5.png',
   'https://solruf-node-static-files.s3.ap-south-1.amazonaws.com/brands/Partner+Brands+6.png',
   'https://solruf-node-static-files.s3.ap-south-1.amazonaws.com/brands/Partner+Brands+7.png',
   'https://solruf-node-static-files.s3.ap-south-1.amazonaws.com/brands/Partner+Brands+8.png',
   'https://solruf-node-static-files.s3.ap-south-1.amazonaws.com/brands/Partner+Brands+9.png',
   'https://solruf-node-static-files.s3.ap-south-1.amazonaws.com/brands/Partner+Brands+10.png',
   'https://solruf-node-static-files.s3.ap-south-1.amazonaws.com/brands/Partner+Brands+11.png',
   'https://solruf-node-static-files.s3.ap-south-1.amazonaws.com/brands/Partner+Brands+12.png',
   'https://solruf-node-static-files.s3.ap-south-1.amazonaws.com/brands/Partner+Brands+13.png',
   'https://solruf-node-static-files.s3.ap-south-1.amazonaws.com/brands/Partner+Brands+14.png',
   'https://solruf-node-static-files.s3.ap-south-1.amazonaws.com/brands/Partner+Brands+15.png',
   'https://solruf-node-static-files.s3.ap-south-1.amazonaws.com/brands/Partner+Brands+16.png',
   'https://solruf-node-static-files.s3.ap-south-1.amazonaws.com/brands/Partner+Brands+17.png',
   'https://solruf-node-static-files.s3.ap-south-1.amazonaws.com/brands/Partner+Brands+18.png',
];

const Brands = () => {
   return (
      <Wrapper>
         <Typography
            variant='h4'
            sx={{
               fontWeight: '700',
               px: '1rem',
               '@media (min-width: 600px)': {
                  display: 'none',
               },
            }}
         >
            Our Partners
         </Typography>
         <Overlay>
            <Typography variant='h3' sx={{ fontWeight: '800', color: 'white' }}>
               Our Product Brands
            </Typography>
         </Overlay>
         <SliderWrapper>
            <BrandsSlip
               component={motion.div}
               animate={{ x: -9910 }}
               transition={{
                  repeat: Infinity,
                  duration: 220,
                  type: 'linear',
                  ease: 'linear',
               }}
            >
               {brands.map((brandImg, i) => (
                  <img src={brandImg} alt={`${'brand' + i}`} />
               ))}
               {brands.map((brandImg, i) => (
                  <img src={brandImg} alt={`${'brand' + i}`} />
               ))}
               {brands.map((brandImg, i) => (
                  <img src={brandImg} alt={`${'brand' + i}`} />
               ))}
               {brands.map((brandImg, i) => (
                  <img src={brandImg} alt={`${'brand' + i}`} />
               ))}
               {brands.map((brandImg, i) => (
                  <img src={brandImg} alt={`${'brand' + i}`} />
               ))}
               {brands.map((brandImg, i) => (
                  <img src={brandImg} alt={`${'brand' + i}`} />
               ))}
               {brands.map((brandImg, i) => (
                  <img src={brandImg} alt={`${'brand' + i}`} />
               ))}
               {brands.map((brandImg, i) => (
                  <img src={brandImg} alt={`${'brand' + i}`} />
               ))}
               {brands.map((brandImg, i) => (
                  <img src={brandImg} alt={`${'brand' + i}`} />
               ))}
               {brands.map((brandImg, i) => (
                  <img src={brandImg} alt={`${'brand' + i}`} />
               ))}
               {brands.map((brandImg, i) => (
                  <img src={brandImg} alt={`${'brand' + i}`} />
               ))}
               {brands.map((brandImg, i) => (
                  <img src={brandImg} alt={`${'brand' + i}`} />
               ))}
               {brands.map((brandImg, i) => (
                  <img src={brandImg} alt={`${'brand' + i}`} />
               ))}
               {brands.map((brandImg, i) => (
                  <img src={brandImg} alt={`${'brand' + i}`} />
               ))}
               {brands.map((brandImg, i) => (
                  <img src={brandImg} alt={`${'brand' + i}`} />
               ))}
               {brands.map((brandImg, i) => (
                  <img src={brandImg} alt={`${'brand' + i}`} />
               ))}
               {brands.map((brandImg, i) => (
                  <img src={brandImg} alt={`${'brand' + i}`} />
               ))}
               {brands.map((brandImg, i) => (
                  <img src={brandImg} alt={`${'brand' + i}`} />
               ))}
               {brands.map((brandImg, i) => (
                  <img src={brandImg} alt={`${'brand' + i}`} />
               ))}
               {brands.map((brandImg, i) => (
                  <img src={brandImg} alt={`${'brand' + i}`} />
               ))}
               {brands.map((brandImg, i) => (
                  <img src={brandImg} alt={`${'brand' + i}`} />
               ))}
               {brands.map((brandImg, i) => (
                  <img src={brandImg} alt={`${'brand' + i}`} />
               ))}
               {brands.map((brandImg, i) => (
                  <img src={brandImg} alt={`${'brand' + i}`} />
               ))}
               {brands.map((brandImg, i) => (
                  <img src={brandImg} alt={`${'brand' + i}`} />
               ))}
               {brands.map((brandImg, i) => (
                  <img src={brandImg} alt={`${'brand' + i}`} />
               ))}
               {brands.map((brandImg, i) => (
                  <img src={brandImg} alt={`${'brand' + i}`} />
               ))}
               {brands.map((brandImg, i) => (
                  <img src={brandImg} alt={`${'brand' + i}`} />
               ))}
               {brands.map((brandImg, i) => (
                  <img src={brandImg} alt={`${'brand' + i}`} />
               ))}
            </BrandsSlip>
         </SliderWrapper>
      </Wrapper>
   );
};

export default Brands;
