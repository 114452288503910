import {
   Box,
   Checkbox,
   Container,
   MenuItem,
   Typography,
   styled,
} from '@mui/material';
import React, { useState } from 'react';
import SolrufTextField from '../../components/SolrufTextField/SolrufTextField';
import { useForm } from 'react-hook-form';
import PrimaryButton from '../../components/Custom/PrimaryButton/PrimaryButton';
import { useSelector } from 'react-redux';

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { withStyles } from '@mui/styles';
import { toast } from 'react-toastify';
import SolrufTextArea from '../../components/Custom/SolrufTextArea/SolrufTextArea';
import { sendWhatsappNotification } from '../../utils/sendWhatsappNotification';
import { useNavigate } from 'react-router';
import saveFormDataToGoogleSheet from '../../utils/saveFormDataToSheet';

const FormWrapper = styled(Box)(({ theme }) => ({
   width: '100%',
   maxWidth: '600px',
   padding: '1.5rem',
   margin: '0 auto',
   display: 'flex',
   justifyContent: 'center',
   flexDirection: 'column',
   gap: '1rem',
   background: '#ffffff',
   borderRadius: '1rem',
}));

const StyledCheckbox = withStyles({
   root: {
      '& .MuiSvgIcon-root': {
         borderRadius: '50%',
      },
   },
})((props) => (
   <Checkbox
      disableRipple
      icon={<RadioButtonUncheckedIcon />}
      checkedIcon={<RadioButtonCheckedIcon />}
      {...props}
   />
));

const SolarPumpForm = () => {
   const {
      register,
      handleSubmit,
      // reset,
      formState: { errors, isSubmitting },
   } = useForm();

   const [whatsappChecked, setWhatsappChecked] = useState(false);
   const navigate = useNavigate();

   const handleWhatsappChecked = (event) => {
      setWhatsappChecked(event.target.checked);
   };

   const statesOfIndia = useSelector((state) => state.utils.statesOfIndia);

   const submitHandler = async (formData) => {
      const timestamp = Date.now();
      const formattedDate = new Date(timestamp).toLocaleString();

      const data = {
         name: formData.first_name + formData.last_name,
         phone: formData.phone,
         email: formData.email,
         city: formData.city_district_taluka,
         state: formData.state,
         message: formData.message,
         whatsapp_subscribe: whatsappChecked ? 'yes' : 'no',
         type: 'Lead form',
         timestamp: formattedDate,
      };

      try {
         const { status } = await saveFormDataToGoogleSheet(
            data,
            'https://script.google.com/macros/s/AKfycbyE0IZUtaY4Hc777j9-fn7-8Bitdyg76NiBHVE7v_9BoO8f8i-nXBmqsLEAm_fv5pQ5bg/exec'
         );

         if (status === 200) {
            sendWhatsappNotification(
               data?.name,
               data?.phone,
               'solar_pump_intro_blogs_regard',
               [data.name, '+91-8600694140', 'sumit@solruf.com'],
               {
                  url: 'https://i.ibb.co/C1Pd1gH/solar-pump-intro-regard.jpg',
                  filename: 'test',
               }
            );
            navigate('/products');
         }
      } catch (error) {
         toast.error('Something went wrong');
      }
   };

   const videoUrl = 'https://www.youtube.com/watch?v=6mNE1kQEjjU'; // Replace with your YouTube video URL
   // Extract the video ID from the URL
   const videoId = videoUrl.match(/v=([^&]+)/)[1];

   return (
      <Box sx={{ background: '#f3f3f3', py: 5 }}>
         <Container sx={{ minHeight: 'calc(100vh - 550px)' }}>
            <Box
               sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  flexDirection: ['column', 'column', 'column', 'row'],
                  p: 2,
               }}
            >
               <Box>
                  <iframe
                     title='YouTube Video Player'
                     width='560'
                     maxWidth='100%'
                     height='315'
                     src={`https://www.youtube.com/embed/${videoId}`}
                     frameBorder='0'
                     allowFullScreen
                  ></iframe>
               </Box>
               <Box>
                  <FormWrapper
                     component='form'
                     onSubmit={handleSubmit(submitHandler)}
                  >
                     <Typography
                        sx={{ textAlign: 'center' }}
                        variant='h3'
                        component='h1'
                     >
                        Solar Pump Form
                     </Typography>
                     <Typography
                        sx={{ textAlign: 'center', fontWeight: 400 }}
                        variant='h6'
                        component='h2'
                     >
                        Please fill up this form to apply for Kusum Yojona Solar
                        Pump Subsidy
                     </Typography>
                     <SolrufTextField
                        size='small'
                        sx={{ mr: 2 }}
                        type='text'
                        label='First Name'
                        {...register('first_name', {
                           required: {
                              value: true,
                              message: 'First name is required',
                           },
                        })}
                        error={errors.first_name}
                        helperText={
                           errors.first_name && errors.first_name.message
                        }
                     />
                     <SolrufTextField
                        size='small'
                        type='text'
                        label='Last Name'
                        {...register('last_name', {
                           required: {
                              value: true,
                              message: 'Last name is required',
                           },
                        })}
                        error={errors.last_name}
                        helperText={
                           errors.last_name && errors.last_name.message
                        }
                     />

                     <SolrufTextField
                        size='small'
                        type='email'
                        label='Email (Optional)'
                        {...register('email', {
                           pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: 'Invalid email address',
                           },
                        })}
                        error={errors.email}
                        helperText={errors.email && errors.email.message}
                     />

                     <SolrufTextField
                        size='small'
                        type='number'
                        label='Mobile Number'
                        {...register('phone', {
                           required: {
                              value: true,
                              message: 'Please input a number to continue',
                           },
                        })}
                        error={errors.phone}
                        helperText={errors.phone && errors.phone.message}
                     />
                     <SolrufTextField
                        size='small'
                        label='City/District/Taluka'
                        {...register('city_district_taluka', {
                           required: {
                              value: true,
                              message: 'Please input city/district/taluka',
                           },
                        })}
                        error={errors.city_district_taluka}
                        helperText={
                           errors.city_district_taluka &&
                           errors.city_district_taluka.message
                        }
                     />
                     <SolrufTextField
                        select
                        size='small'
                        type='number'
                        disabled
                        label='State'
                        {...register('state', {
                           required: {
                              value: true,
                              message: 'Please input city/district/taluka',
                           },
                        })}
                        error={errors.state}
                        helperText={errors.state && errors.state.message}
                     >
                        {statesOfIndia.map((state, i) => (
                           <MenuItem value={state} key={state + i}>
                              {state}
                           </MenuItem>
                        ))}
                     </SolrufTextField>

                     <SolrufTextArea
                        label='Message'
                        {...register('message', {
                           required: {
                              value: true,
                              message: 'Please input your message',
                           },
                        })}
                        error={errors.message}
                        helperText={errors.message && errors.message.message}
                     />

                     <Box
                        sx={{
                           display: 'flex',
                           alignItems: 'center',
                           // mb: 2
                        }}
                     >
                        <StyledCheckbox
                           label='Label'
                           defaultChecked
                           checked={whatsappChecked}
                           onChange={handleWhatsappChecked}
                           sx={{}}
                        />
                        <Typography variant='subtitle1'>
                           Opt in for Solar Products Whatsapp Updates
                        </Typography>
                     </Box>

                     <PrimaryButton type='submit' disabled={isSubmitting}>
                        Submit
                     </PrimaryButton>
                  </FormWrapper>
                  {/* form end */}
               </Box>
            </Box>
         </Container>
      </Box>
   );
};

export default SolarPumpForm;
