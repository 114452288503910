import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
 
   Avatar,
   Box,
   Drawer,
   List,
   ListItem,
   Typography,
} from '@mui/material';
import PrimaryButton from '../../../components/Custom/PrimaryButton/PrimaryButton';
import LaunchIcon from '@mui/icons-material/Launch';
import VendorDocuments from './VendorDocuments';
import CustomAccordionForDrawer from '../../../components/Custom/CustomAccordionForDrawer/CustomAccordionForDrawer';

const DrawerWrapper = styled(Drawer)(({ theme }) => ({
   width: 400,
   flexShrink: 0,
   '& .MuiDrawer-paper': {
      width: 400,
   },
}));

function VendorList({ vendors }) {
   const [selectedVendor, setSelectedVendor] = useState(null);

   const handleSubCompanyClick = (vendor) => {
       
      setSelectedVendor(vendor);
   };

   const handleDrawerClose = () => {
      setSelectedVendor(null);
   };

    

   return (
      <>
         <List>
            {vendors.map((vendor, i) => (
               <ListItem
                  key={vendor.id}
                  button
                  sx={{ '&:hover': { background: 'transparent' } }}
               >
                  <Box
                     sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        bgcolor: i % 2 === 0 ? '#F3F3F3' : '#D0D7D9',
                        p: 1,
                        borderRadius: 2,
                        boxShadow: 25,
                        '&:hover': {
                           boxShadow: 2,
                        },
                        alignItems: 'center',
                     }}
                  >
                     <Box
                        sx={{ display: 'flex', gap: 2, alignItems: 'center' }}
                     >
                        <Box
                           sx={{
                              display: 'flex',
                              alignItems: 'center',
                              columnGap: 1,
                           }}
                        >
                           <Avatar
                              alt='Remy Sharp'
                              src={vendor?.portfolio?.logo}
                              sx={{ width: 50, height: 50 }}
                           />
                           <Typography variant='h6'>
                              {vendor?.portfolio?.name}
                           </Typography>
                        </Box>
                        <a
                           href={`https://solruf.com/portfolio/${vendor?.portfolio?.slug}`}
                           alt='go to portfolio'
                           target='_blank'
                           rel='noreferrer'
                        >
                           <PrimaryButton IconEnd={LaunchIcon}>
                              Portfolio
                           </PrimaryButton>
                        </a>
                     </Box>
                     {/* <ChevronRight /> */}

                     <PrimaryButton
                        onClick={() => handleSubCompanyClick(vendor)}
                     >
                        Create Enquiry
                     </PrimaryButton>
                  </Box>
               </ListItem>
            ))}
         </List>


         {/* ========= accordion part ========= */}

         {selectedVendor && (
            <DrawerWrapper
               anchor='right'
               open={Boolean(selectedVendor)}
               onClose={handleDrawerClose}
            >
               <Box sx={{ p: 2, display: 'flex', justifyContent: 'center', flexDirection: 'column', rowGap: 2 }}>
                  <Typography variant='h6' textAlign='center'>
                     {selectedVendor?.portfolio.name}
                  </Typography>

                  <a
                     href={`https://solruf.com/portfolio/${selectedVendor?.portfolio?.slug}`}
                     alt='go to portfolio'
                     target='_blank'
                     rel='noreferrer'
                     style={{
                        alignSelf: 'center'
                     }}
                  >
                     <PrimaryButton IconEnd={LaunchIcon}>
                        Portfolio
                     </PrimaryButton>
                  </a>

                  <CustomAccordionForDrawer title='Vendor Documents'>
                     <VendorDocuments vendorId={selectedVendor?.id} />
                  </CustomAccordionForDrawer>
               </Box>

               <div style={{ padding: 16 }}>
                  <Typography>{selectedVendor?.details}</Typography>
               </div>
            </DrawerWrapper>
         )}
      </>
   );
}

export default VendorList;
