import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import SolrufTextField from '../SolrufTextField/SolrufTextField';
import PrimaryButton from '../Custom/PrimaryButton/PrimaryButton';
import { axiosInstance } from '../../utils/axiosInstance';
import { toast } from 'react-toastify';

const Coupon = ({ couponInfo, setCouponInfo, sx }) => {
   const [couponCode, setCouponCode] = useState('');
   const [applying, setApplying] = useState(false);

   const couponApplyHandler = async () => {
      console.log('request to backend', couponCode);
      try {
         setApplying(true);
         const { status, data } = await axiosInstance.get(
            `/api/coupon/${couponCode}`
         );
         if (status === 200) {
            setApplying(false);
            const { coupon } = data;

            if (coupon.expiresAt < new Date().getTime()) {
               setCouponInfo((info) => ({ ...info, valid: false }));
               throw new Error('Token has expired');
            }

            setCouponInfo({
               title: coupon.title,
               less: coupon.less,
               expiresAt: coupon.expiresAt,
               valid: true,
            });
         }
      } catch (error) {
         setApplying(false);
         setCouponInfo((info) => ({ ...info, valid: false }));
         toast.error('Invalid Token or Token has expired');
      }
   };

   console.log(couponInfo);

   return (
      <Box
         sx={{
            padding: '0rem 3rem 1rem 3rem',
            ...sx
         }}
      >
         <Typography
            sx={{
               fontWeight: 600,
               fontSize: '1rem',
            }}
            gutterBottom
         >
            Apply a coupon code!
         </Typography>
         <SolrufTextField
            label='Code'
            sx={{
               '& .MuiOutlinedInput-root': {
                  background: '#ffffff',
               },
            }}
            size='small'
            onChange={(e) => setCouponCode(e.target.value)}
            // error={errors?.name}
            // helperText={errors.name && errors.name.message}
         />
         <PrimaryButton type='Button' sx={{ mt: 1.5 }} onClick={couponApplyHandler} disabled={applying}>
            Apply
         </PrimaryButton>
      </Box>
   );
};

export default Coupon;
