import { Typography } from "@mui/material";
import React from "react";
import TeamCard from "./TeamCard";
import { CardsWrapper, TWrapper } from "./TeamStyle";

/*

https://i.ibb.co/GW3y8cM/Group-15107.png
https://i.ibb.co/thK2tGb/Group-15106.png
https://i.ibb.co/R0nWP6C/Group-15105.png
https://i.ibb.co/Z2fM2LG/Group-15102.png
https://i.ibb.co/fk48Scs/Group-15104.png
https://i.ibb.co/1dtrCtW/Group-15103.png


https://i.ibb.co/TRV0nJd/Untitled-design-28.png
https://i.ibb.co/k42MzBf/Untitled-design-29.png
https://i.ibb.co/TTP9Rwy/Untitled-design-26.png
https://i.ibb.co/VTdhsM9/Untitled-design-27.png
https://i.ibb.co/fr4cZyn/416152-2.png


*/
const Team = () => {
  return (
    <TWrapper>
      <Typography
        variant="h3"
        sx={{ fontWeight: "bold", lineHeight: "3rem", margin: "0" }}
      >
        <span style={{ color: "#ffd05b" }}>SOLRUF</span> Bright Faces
      </Typography>
      <CardsWrapper>
        <TeamCard
          // image={'https://i.ibb.co/NTVC54g/Untitled-design-25.png'}
          image={
            "https://solrufv1.s3.ap-south-1.amazonaws.com/teamPhotos/SumitPic.png"
          }
          name="Sumit Agarwal"
          position="Founder & CEO"
          linkedinUrl="https://www.linkedin.com/in/sumit-agarwal-322969102/"
          bio="B.Tech. in Electrical Engineering from IIT KGP, 2015-19"
        />
        <TeamCard
          // image={'https://i.ibb.co/tmtT9dS/416152-2.png'}
          image={
            "https://solrufv1.s3.ap-south-1.amazonaws.com/teamPhotos/SanketPic.png"
          }
          name="Sanket Pandit"
          position="Sales & Operation’s Head "
          linkedinUrl="https://www.linkedin.com/in/panditsanket92/"
          // bio='Ex-CTO (ResellMall), B.Tech, Computer science, KIST'
        />
        <TeamCard
          // image={'https://i.ibb.co/VTdhsM9/Untitled-design-27.png'}
          image={
            "https://solrufv1.s3.ap-south-1.amazonaws.com/teamPhotos/SohamPic.png"
          }
          name="Sohem Pise"
          position="Projects Head"
          linkedinUrl="https://www.linkedin.com/in/soham-pise/"
          // bio='5 years of experience in Cosmic Solar'
        />
      </CardsWrapper>
      <CardsWrapper style={{ marginTop: "20px" }}>
        {/* <TeamCard
               // image={'https://i.ibb.co/Y0wjVgq/Untitled-design-26.png'}
               image={'https://i.ibb.co/NTVC54g/Untitled-design-25.png'}
               name='Megha Chandak'
               position='Accounts Manager'
               linkedinUrl='https://www.linkedin.com/in/megha-rathi-7641ba233/'
               // instagramUrl='https://www.instagram.com/vani.25_/'
               bio=''
            /> */}
        <TeamCard
          // image={'https://i.ibb.co/k42MzBf/Untitled-design-29.png'}
          image={
            "https://solrufv1.s3.ap-south-1.amazonaws.com/teamPhotos/UrjaPic.png"
          }
          name="Urja Singla"
          position="Operation’s Manager"
          linkedinUrl="https://www.linkedin.com/in/urja-singla-5720a2274/"
          // bio='BE- Industrial Engineering, BMSCE, Bangalore'
        />

        <TeamCard
          // image={
          //    'https://solruf-node-static-files.s3.ap-south-1.amazonaws.com/Untitled+design+(28).png'
          // }
          image={
            "https://solrufv1.s3.ap-south-1.amazonaws.com/teamPhotos/ShaildendraPic.png"
          }
          name="Shailendra Gosain"
          position="Sales Manager "
          linkedinUrl="https://www.linkedin.com/in/sunil-gosain-3a2475286/"
          // bio='BE- Industrial Engineering, BMSCE, Bangalore'
        />

        <TeamCard
          // image={
          //    'https://solruf-node-static-files.s3.ap-south-1.amazonaws.com/Untitled+design+(28).png'
          // }
          image={
            "https://solrufv1.s3.ap-south-1.amazonaws.com/teamPhotos/RishabhPic.png"
          }
          name="Rishabh Singh"
          position="Relationship Manager"
          linkedinUrl="https://www.linkedin.com/in/rishabh-singh-5b94962b3/"
          // bio='BE- Industrial Engineering, BMSCE, Bangalore'
        />

        <TeamCard
          // image={
          //    'https://solruf-node-static-files.s3.ap-south-1.amazonaws.com/Untitled+design+(24)+1.png'
          // }
          image={
            "https://solrufv1.s3.ap-south-1.amazonaws.com/teamPhotos/AamanPic.png"
          }
          name="Aaman Verma"
          position="Brand Manager"
          linkedinUrl="https://www.linkedin.com/in/aaman-verma21/"
          // bio='BE- Industrial Engineering, BMSCE, Bangalore'
        />
      </CardsWrapper>
    </TWrapper>
  );
};

export default Team;
