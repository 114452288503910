import { Box, Stack } from "@mui/material";

import React, { useEffect, useState } from "react";
import SectionHeader from "./SectionHeader";
import TrendingCard from "./TrendingCard";
import { axiosInstance } from "../../../utils/axiosInstance";
import axios from "axios";

const blogs = [
  {
    title:
      "Harvesting Sunshine: Understanding How Much Power a Solar Panel Can Produce",
    description:
      "Unlock the mysteries of solar power production as we delve into the question: How much power does a solar panel really produce? This comprehensive guide explores the factors influencing solar panel output, efficiency considerations, and how you can harness the maximum potential of sunlight to generate clean, renewable energy.",
    route: "/blogs/harvest",
    img: "https://solruf-node-static-files.s3.ap-south-1.amazonaws.com/dea7fb3955e17aed8abbdf579fa0d753-min.jpeg",
    author: "Rahul Kanojiya",
    timeAgo: "2 months ago",
  },
  {
    title:
      "Illuminating Tomorrow: Solar Inverters, Solar Bulbs, and Solar Panels",
    description:
      "Dive into the future of solar technology with a focus on solar inverters, solar bulbs, and solar panels. This guide unveils the latest innovations shaping the solar tech industry, shedding light on how these advancements are revolutionizing energy efficiency, sustainability, and the way we illuminate our world.",
    route: "/blogs/illuminating",
    img: "https://solruf-node-static-files.s3.ap-south-1.amazonaws.com/cf76cbbad3f58ed393e8a65c19d41caf-min.png",
    author: "Sumit Agarwal",
    timeAgo: "3 weeks ago",
  },
  {
    title:
      "Powering the Future: Latest Advancements in Solar Tech - Inverters & UPS Unveiled",
    description:
      "Explore the cutting-edge innovations in the solar tech industry, with a focus on the latest advancements in inverters and UPS technology. Discover how these crucial components are reshaping solar energy systems, enhancing efficiency, and paving the way for a sustainable and reliable energy future.",
    route: "/blogs/powering",
    img: "https://solruf-node-static-files.s3.ap-south-1.amazonaws.com/c22a392bb11cb7c94ea2c5ac5f095cbe.jpeg",
    author: "Aaman Verma",
    timeAgo: "1 month ago",
  },
  {
    title:
      "Solar Harmony: Tailored Power Backup Solutions with Solar Panels for Home Resilience",
    description:
      "Explore the perfect synergy of solar technology with dedicated power backup solutions for 1BHK, 2BHK, and 3BHK homes. Dive into the world of uninterrupted energy with solar panels designed to enhance the resilience of your living space. Discover how solar innovation ensures a continuous and eco-friendly power supply for your home.",
    route: "/blogs/harmony",
    img: "https://solruf-node-static-files.s3.ap-south-1.amazonaws.com/5adc6c2fef7d8e46b426fa936ff267a2-min.png",
    author: "Rahul Kanojiya",
    timeAgo: "2 weeks ago",
  },
  {
    title: "Choosing Solar Panels Wisely: A Guide to Sustainable Energy",
    description:
      "Solar energy stands as a leading solution for sustainable power in homes and businesses. As you embark on the journey to harness the sun's power, making informed choices about solar panels for your home is essential for maximizing efficiency and minimizing environmental impact.",
    route: "/blogs/choosingSolar",
    img: "https://solruf-node-static-files.s3.ap-south-1.amazonaws.com/04d0ea3891e4522156f821b4920c6822-min.jpeg",
    author: "Aaman Verma",
    timeAgo: "3 months ago",
  },
  {
    title: "Everything You Need to Know about Solar Inverters for Home",
    description:
      "As the never-ending struggle for cost-effective, green and renewable energy grows more desperate, thanks to the increasingly unstable climate and depleting resources, the demand for solar panels is bound to see a bump, especially in the residential context.",
    route: "/blogs/inverters",
    img: "https://solruf-node-static-files.s3.ap-south-1.amazonaws.com/87dab28b3da5e9f30b60db33cc2602db.jpeg",
    author: "Sumit Agarwal",
    timeAgo: "1 week ago",
  },
  {
    title: "The Future Of Solar Energy: Predictions For 2024 and Beyond",
    description:
      "The future of solar energy looks bright, with continued growth and innovation expected in the industry. Here are some of the key trends and developments that are likely to shape the future of solar energy",
    route: "/blogs/futureSolar",
    img: "https://i.ibb.co/Px4MjRX/pexels-pixabay-433308-1.png",
    author: "Aaman Verma",
    timeAgo: "4 days ago",
  },
];

const truncateHTML = (html, length) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  const text = tempDiv.innerText;
  return text.length > length ? text.slice(0, length) + "..." : html;
};

const getTimeAgo = (timestamp) => {
  const now = new Date();
  const givenDate = new Date(timestamp);

  // Helper function to strip time part from a date
  const stripTime = (date) =>
    new Date(date.getFullYear(), date.getMonth(), date.getDate());

  // Strip time parts for accurate comparison
  const strippedNow = stripTime(now);
  const strippedGivenDate = stripTime(givenDate);

  if (strippedNow.getTime() === strippedGivenDate.getTime()) {
    return "Today";
  }

  const diffTime = Math.abs(now - givenDate);
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  const diffMonths = Math.floor(diffDays / 30); // Rough estimate of a month
  const diffYears = Math.floor(diffDays / 365); // Rough estimate of a year

  if (diffYears > 0) {
    return `${diffYears} year${diffYears > 1 ? "s" : ""} ago`;
  } else if (diffMonths > 0) {
    return `${diffMonths} month${diffMonths > 1 ? "s" : ""} ago`;
  } else {
    return `${diffDays} day${diffDays > 1 ? "s" : ""} ago`;
  }
};

const NewTrending = () => {
  const [blogsData, setBlogsData] = useState(null);

  useEffect(() => {
    axios
      .get(
        "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@socialmedia_90251"
      )
      .then((response) => {
        setBlogsData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching blog data:", error);
      });
  }, []);

  if (!blogsData) {
    return <div>Loading...</div>; // You can return a loader or placeholder here
  }

  return (
    <Box sx={{ my: 12 }}>
      <SectionHeader title="Now Trending" />
      <Box>
        <Stack
          direction={{ xs: "column", md: "row" }}
          columnGap={{ xs: 1, sm: 2, md: 5 }}
          sx={{
            mt: 8,
            justifyContent: "space-around",
            alignItems: "center",
          }}
          flexWrap="wrap"
        >
          {/* {blogs.map((el) => (
            <TrendingCard
              title={el.title}
              desc={el.description}
              route={el.route}
              img={el.img}
              timeAgo={el.timeAgo}
              author={el.author}
            />
          ))} */}

          {blogsData.items.map((el) => {
            const imageMatch = el.description
              .toString()
              .match(/<img[^>]+src="([^">]+)"/);
            const image = imageMatch ? imageMatch[1] : null;
            const description = el.description.replace(/<img[^>]*>/g, "");
            const truncatedDescription = truncateHTML(description, 260);
            const timeAgo = getTimeAgo(el.pubDate);
            return (
              <TrendingCard
                title={el.title}
                desc={truncatedDescription}
                route={el.link}
                img={image}
                timeAgo={timeAgo}
                author="SOLRUF"
              />
            );
          })}
        </Stack>
      </Box>
    </Box>
  );
};

export default NewTrending;
