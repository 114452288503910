import { axiAuth } from "../../utils/axiosInstance";

export const updateQuantityInCart = async (cartId, newQuantity) => {
    try {
      const response = await axiAuth.put(`/api/shoppingcarts/${cartId}`, {
        quantity: newQuantity,
      });
  
      return response;
    } catch (error) {
      throw error;
    }
  };