import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Input,
  LinearProgress,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import PrimaryButton from "../Custom/PrimaryButton/PrimaryButton";
import QuoteAccordion from "../CustomerDetailsDrawer/QuoteAccordion";
import TextFieldWithRegister from "../Custom/TextFieldWithRegister/TextFieldWithRegister";
import SelectFieldWithRegister from "../Custom/SelectFieldWithRegister/SelectFieldWithRegister";
import PincodeCityState2 from "../PincodeCityState/PincodeCityState2";
import { Close } from "@mui/icons-material";
import LightTextField from "../Custom/LightTextField/LightTextField";
import { Flex } from "../CustomerDetailsDrawer/customerDetailsDrawer.style";
import {
  InfoBar,
  ProductListWrapper,
  SolrufTextFieldGray,
  Table,
} from "./QuotationService";
import { axiosInstance } from "../../utils/axiosInstance";
import SolrufTextArea from "../Custom/SolrufTextArea/SolrufTextArea";

const leadSourceOptions = [
  { value: "ongrid", label: "Ongrid" },
  { value: "offgrid", label: "Offgrid" },
  { value: "hybrid", label: "Hybrid" },
];

const mountingTypes = [
  { value: "rooftop", label: "Rooftop" },
  { value: "ground_mounted", label: "Ground Mounted" },
];

const company_description =
  "We are an innovative Solar Energy Engineering, Procurement, and Construction (EPC) company focused on delivering high-quality solar rooftop and ground-mounted power plants. Our expertise lies in both off-grid and grid-connected solar power solutions, tailored specifically for residential homes, commercial offices, and industries. Driven by the United Nations Sustainable Development Goals, our mission is to provide affordable and clean energy for everyone, while taking meaningful actions against climate change by 2030. We are dedicated to developing solar power as a sustainable alternative energy source in India, making solar a household utility accessible to all. Our solar plants also help our clients save significantly on electricity bills, with potential savings ranging from 30% to 50% (and up to 100% in some cases), particularly for industries facing higher energy tariffs, thereby contributing positively to their bottom line.";

const WareeProposalService = () => {
  const { register, handleSubmit, formState, setValue, watch, control, reset } =
    useForm();
  const { errors } = formState;
  const formMethods = { register, formState, setValue, watch, control };
  const [checkCumulative, setCheckCumulative] = useState(false);
  const [checkNoPriceBreakdown, setCheckNoPriceBreakdown] = useState(false);
  const [cumulativeTax, setCumulativeTax] = useState(0);
  const [togglecaptolightbills, setTogglecaptolightbills] = useState("cap");
  const [totalPrice, setTotalPrice] = useState(0);
  const [description, setDescription] = useState(company_description);
  const [projects, setProjects] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [size, setSize] = useState("");
  const [location, setLocation] = useState("");
  const [image, setImage] = useState(null);
  const [imageUploaded, setImageUploaded] = useState(false); // For indication
  const fileInputRef = useRef(null); // Reference to the file input
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [projectCustomerName, setProjectCustomerName] = useState('waree');

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
        setImageUploaded(true); // Indicate that the image has been uploaded
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAddProject = () => {
    if (projectName && size && location && image) {
      const newProject = { projectName, size, location, image };
      setProjects([...projects, newProject]);
      // Reset the input fields
      setProjectName("");
      setSize("");
      setLocation("");
      setImage(null);
      setImageUploaded(false);

      // Clear the file input value after project is added
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // This will allow the same image to be re-uploaded
      }
    } else {
      alert("Please fill out all fields");
    }
  };

  // Function to handle project removal
  const handleRemoveProject = (index) => {
    const updatedProjects = projects.filter((_, i) => i !== index);
    setProjects(updatedProjects);
  };
  //   useEffect(() => {
  //     console.log(projects);
  //     console.log("Image: ", image);
  //   }, [projects, image]);
  let items = [
    {
      // id: 1,
      properties: {
        particular_name: "Description: Axitec 545 Wp MONO",
        unit_price: 25000,
        units: 2,
        unit_type: "pcs",
        hsn_sac_code: "85414011",
        igst: 18,
        cgst: 9,
        sgst: 9,
      },
    },
    {
      // id: 1,
      properties: {
        particular_name: "Description: 60kW Growatt",
        unit_price: 120000,
        units: 1,
        unit_type: "unit",
        hsn_sac_code: "85044090",
        igst: 18,
        cgst: 9,
        sgst: 9,
      },
    },
    {
      // id: 1,
      properties: {
        particular_name: "Module Mounting Structure",
        unit_price: 50000,
        units: 1,
        unit_type: "set",
        hsn_sac_code: "73089090",
        igst: 18,
        cgst: 9,
        sgst: 9,
      },
    },
    {
      // id: 1,
      properties: {
        particular_name: "Electrical Ac/Dc Cable (Inc. Earthing)",
        unit_price: 10000,
        units: 100,
        unit_type: "meter",
        hsn_sac_code: "85444999",
        igst: 18,
        cgst: 9,
        sgst: 9,
      },
    },
    {
      // id: 1,
      properties: {
        particular_name: "Distribution Box / Cable Tray",
        unit_price: 15000,
        units: 3,
        unit_type: "box",
        hsn_sac_code: "85381010",
        igst: 18,
        cgst: 9,
        sgst: 9,
      },
    },
    {
      // id: 1,
      properties: {
        particular_name: "Installation / Commissioning",
        unit_price: 20000,
        units: 1,
        unit_type: "job",
        hsn_sac_code: "995461",
        igst: 18,
        cgst: 9,
        sgst: 9,
      },
    },
    {
      // id: 1,
      properties: {
        particular_name: "Net Metering",
        unit_price: 5000,
        units: 1,
        unit_type: "job",
        hsn_sac_code: "995463",
        igst: 18,
        cgst: 9,
        sgst: 9,
      },
    },
  ];
  const [productsData, setProductsData] = useState(items);

  const addProduct = () => {
    setProductsData((prev) => {
      return [
        ...prev,
        // {
        //   id: prev.length + 1,
        //   properties: {
        //     product_name: "",
        //     product_price: 100,
        //     hsn_sac_code: "",
        //     units_per_quantity: 1,
        //     unit_type: "",
        //     igst: 0,
        //     cgst: 0,
        //     sgst: 0,
        //   },
        // },
        {
          //   id: prev.length + 1,
          properties: {
            particular_name: "",
            unit_price: 0,
            units: 1,
            unit_type: "",
            hsn_sac_code: "",
            igst: 0,
            cgst: 0,
            sgst: 0,
          },
        },
      ];
    });
  };
  useEffect(() => {
    if (checkCumulative) {
      setProductsData((prevProductsData) => {
        return prevProductsData.map((item) => ({
          ...item,
          properties: {
            ...item.properties,
            igst: 0,
            cgst: 0,
            sgst: 0,
          },
        }));
      });
    }
  }, [checkCumulative]);

  useEffect(() => {
    if (!checkNoPriceBreakdown) {
      const total = productsData
        .reduce((sum, product) => {
          const unitPrice = parseFloat(product.properties.unit_price);
          const cgst = (parseFloat(product.properties.cgst) / 100) * unitPrice;
          const igst = (parseFloat(product.properties.igst) / 100) * unitPrice;
          const sgst = (parseFloat(product.properties.sgst) / 100) * unitPrice;
          const units = parseInt(product.properties.units);

          const productTotal = (unitPrice + cgst + igst + sgst) * units;
          return sum + productTotal;
        }, 0)
        .toFixed(2);

      setTotalPrice(total);
    }
  }, [productsData, checkNoPriceBreakdown]);

  useEffect(() => {
    if (checkNoPriceBreakdown) {
      setTotalPrice(0);
    }
  }, [productsData, checkNoPriceBreakdown]);

  const handleToggle = (event, newInput) => {
    if (newInput !== null) {
      setTogglecaptolightbills(newInput);
    }
  };

  const onSubmit = async (data) => {
    const builtdata = {
      ...data,
      projects: projects,
      description: description,
      particulars: productsData,
      has_umulative_tax: checkCumulative,
      has_no_price_breakdown: checkNoPriceBreakdown,
      cumulative_tax: cumulativeTax,
      total_price: checkCumulative
        ? parseFloat(
            Number(totalPrice) +
              Number(totalPrice) * (Number(cumulativeTax) / 100)
          ).toFixed(2)
        : totalPrice,
      predicted_system_capacity_kw: data.predicted_system_capacity_kw ?? null,
      light_bill_in_unit: data.light_bill_in_unit ?? null,
    };

    console.log(builtdata);

    console.log("Download Button Clicked");
    try {
      // handlePrint();

      // Make a POST request to the API using Axios
      const response = await axiosInstance.post(
        "https://api-dev-v2.solruf.com/api/v2/proposal_file/waree",
        builtdata,
        {
          responseType: "blob", // Set the response type to Blob
          headers: {
            "Content-Type": "application/json", // Set the Content-Type header to application/json
          },
          onDownloadProgress: (progressEvent) => {
            // Calculate and update the download progress
            // const progress = Math.round(
            //   (progressEvent.loaded / progressEvent.total) * 100
            // );
            // setDownloadProgress(progress);
            // Calculate and update the download progress
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            setDownloadProgress(progress);
          },
        }
      );

      // Create a temporary anchor element to trigger the download
      const a = document.createElement("a");
      a.style.display = "none"; // Hide the anchor
      document.body.appendChild(a); // Append the anchor to the document

      // Function to initiate the download
      const startDownload = () => {
        a.href = window.URL.createObjectURL(response.data);
        a.download = projectCustomerName + "_proposal.pdf";
        a.click(); // Trigger the click event to start the download
      };

      // Start the download immediately
      startDownload();

      // Release the URL object and remove the anchor after download
      window.URL.revokeObjectURL(a.href);
      document.body.removeChild(a);

      // Reset download progress after completion
      setDownloadProgress(0);

      // setActivePage(2);
    } catch (error) {
      // toast.error(error.response.data.message || "Something went wrong");
      console.error("API request error:", error);

      // Reset download progress in case of error
      setDownloadProgress(0);
    }
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box sx={{ padding: isSmallScreen ? 2 : 4 }}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            justifyContent: isSmallScreen ? "center" : "space-between",
            alignItems: "center",
            width: "100%",
            my: 2,
            gap: 4,
          }}
        >
          <PrimaryButton type="submit">Download Proposal</PrimaryButton>
        </Box>
        {/* Progress bar under the button */}
        {downloadProgress > 0 && (
          <Box sx={{ width: "100%", mt: 2, mb: 2 }}>
            <LinearProgress variant="determinate" value={downloadProgress} />
          </Box>
        )}
        <Stack flexDirection={`column`} gap={3} mb={3}>
          <QuoteAccordion
            title="Basic Details"
            titleStyle={{ fontSize: "1rem" }}
            defaultExpanded={true}
          >
            <Stack flexDirection={`column`} gap={3} mb={3}>
              {/*<Controller
                  name="project_name"
                  control={control}
                  defaultValue={projectData?.project_name || ""}
                  render={({ field }) => (
                    <TextFieldWithRegister
                      {...field}
                      label="Name"
                      name="project_name"
                      register={register}
                      errors={errors}
                      InputLabelProps={{
                        shrink: projectData?.project_name ? true : false,
                      }}
                      defaultValue={projectData?.project_name || ""}
                    />
                  )}
                />*/}

              <TextFieldWithRegister
                label="Name"
                name="project_name"
                register={register}
                errors={errors}
                defaultValue={""}
                onChange={(e) => setProjectCustomerName(e.target.value)}
              />
              <TextFieldWithRegister
                label="Mobile"
                name="mobile"
                register={register}
                errors={errors}
                defaultValue={""}
              />
              <TextFieldWithRegister
                label="Email"
                name="email"
                register={register}
                errors={errors}
                defaultValue={""}
              />

              {/* <Controller
                  name="project_type"
                  control={control}
                  defaultValue={projectData?.project_type || ""}
                  render={({ field }) => (
                    <SelectFieldWithRegister
                      {...field}
                      label="Project Type"
                      name="project_type"
                      register={register}
                      errors={errors}
                      options={leadSourceOptions}
                      InputLabelProps={{
                        shrink: projectData?.project_type ? true : false,
                      }}
                      defaultValue={
                        projectData?.project_type !== null
                          ? projectData?.project_type
                          : ""
                      }
                    />
                  )}
                /> */}
              <SelectFieldWithRegister
                label="Project Type"
                name="project_type"
                register={register}
                errors={errors}
                options={leadSourceOptions}
                // InputLabelProps={{
                //   shrink: projectData?.project_type ? true : false,
                // }}
                defaultValue={""}
              />
              {/* <Controller
                  name="mounting_type"
                  control={control}
                  defaultValue={projectData?.mounting_type || ""}
                  render={({ field }) => (
                    <SelectFieldWithRegister
                      {...field}
                      label="Mounting Type"
                      name="mounting_type"
                      register={register}
                      errors={errors}
                      options={mountingTypes}
                      InputLabelProps={{
                        shrink: projectData?.mounting_type ? true : false,
                      }}
                      defaultValue={
                        projectData?.mounting_type !== null
                          ? projectData?.mounting_type
                          : ""
                      }
                    />
                  )}
                /> */}
              <SelectFieldWithRegister
                label="Mounting Type"
                name="mounting_type"
                register={register}
                errors={errors}
                options={mountingTypes}
                // InputLabelProps={{
                //   shrink: projectData?.mounting_type ? true : false,
                // }}
                defaultValue={""}
              />

              <TextFieldWithRegister
                label="Installed PV Systems in KW"
                name="installed_systems"
                register={register}
                errors={errors}
                defaultValue={"0"}
              />
              <SolrufTextArea
                label="Description"
                defaultValue={description}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth // Add any additional props like fullWidth, disabled, etc.
                placeholder="Type description..."
              />

              <TextFieldWithRegister
                label="Warranty"
                name="warranty"
                register={register}
                errors={errors}
                type="number"
                defaultValue={""}
              />

              <PincodeCityState2
                formMethods={{ ...formMethods, control }}
                customerDetails={""}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              />
            </Stack>
          </QuoteAccordion>

          <QuoteAccordion
            title="Completed Solar Installations"
            titleStyle={{ fontSize: "1rem" }}
            defaultExpanded={true}
          >
            <Box sx={{ padding: 2 }}>
              {/* Display added projects as cards in a responsive grid */}
              <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                {projects.length === 0 ? (
                  // Render a message when there are no projects
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      align="center"
                      color="textSecondary"
                    >
                      No projects added yet. Please add a project.
                    </Typography>
                  </Grid>
                ) : (
                  // Render project cards when there are projects
                  projects.map((project, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                      <Card sx={{ position: "relative" }}>
                        {/* Cross button to remove the project */}
                        <IconButton
                          onClick={() => handleRemoveProject(index)}
                          sx={{
                            position: "absolute",
                            top: 8,
                            right: 8,
                            zIndex: 1,
                            backgroundColor: "rgba(255, 255, 255, 0.8)",
                            transition: "background-color 0.3s ease",
                            "&:hover": {
                              backgroundColor: "red", // Danger color on hover
                              color: "white", // Icon color changes to white
                            },
                          }}
                        >
                          <Close />
                        </IconButton>
                        <CardMedia
                          component="img"
                          height="140"
                          image={project.image}
                          alt={project.projectName}
                        />
                        <CardContent>
                          <Typography variant="h6" gutterBottom>
                            {project.projectName}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Size: {project.size}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Location: {project.location}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))
                )}
              </Grid>

              {/* Input form for adding projects */}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    size="small"
                    fullWidth
                    label="Project Name"
                    value={projectName}
                    onChange={(e) => setProjectName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    size="small"
                    fullWidth
                    label="Size"
                    value={size}
                    onChange={(e) => setSize(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Location"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    component="label"
                    fullWidth
                    sx={{ height: "100%" }}
                    color={imageUploaded ? "success" : "primary"}
                  >
                    {imageUploaded ? "Image Uploaded" : "Upload Image"}
                    <input
                      type="file"
                      hidden
                      onChange={handleImageUpload}
                      ref={fileInputRef} // Attach ref to the file input
                    />
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleAddProject}
                  >
                    Add Project
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </QuoteAccordion>

          <QuoteAccordion
            title="Project Data"
            titleStyle={{ fontSize: "1rem" }}
            defaultExpanded={true}
          >
            <Stack
              direction="row"
              alignItems="center"
              columnGap={2}
              justifyContent="center"
            >
              {/* <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={togglecaptolightbills}
                        onChange={() => {
                          setTogglecaptolightbills((prev) => !prev);
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Toggle to Light Bill In Units"
                  />
                </FormGroup> */}
              <ToggleButtonGroup
                color="primary"
                value={togglecaptolightbills}
                exclusive
                onChange={handleToggle}
                aria-label="toggle_buttons"
              >
                <ToggleButton
                  value="cap"
                  aria-label="predicted_system_capacity_kw"
                >
                  System Size
                </ToggleButton>
                <ToggleButton value="bill" aria-label="light_bill_in_unit">
                  Average Monhly Light Bill
                </ToggleButton>
              </ToggleButtonGroup>
              {/* <ToggleButtonGroup
                  color="primary"
                  value={toggleUnits}
                  exclusive
                  onChange={handleUnitsToggle}
                  aria-label="toggle_buttons"
                >
                  <ToggleButton value="unit_cost" aria-label="unit_cost">
                    Unit Cost
                  </ToggleButton>
                  <ToggleButton
                    value="units_after_solar"
                    aria-label="units_after_solar"
                  >
                    Monthly Units After Solar
                  </ToggleButton>
                </ToggleButtonGroup> */}
            </Stack>
            <Stack flexDirection={`column`} gap={3} mb={2} mt={2}>
              {togglecaptolightbills === "bill" && (
                <TextFieldWithRegister
                  label=" Average Monthly Light bill"
                  name="light_bill_in_unit"
                  register={register}
                  errors={errors}
                  type="text"
                  defaultValue={""}
                />
              )}
              {togglecaptolightbills === "cap" && (
                <TextFieldWithRegister
                  label="System Size"
                  name="predicted_system_capacity_kw"
                  register={register}
                  errors={errors}
                  type="text"
                  defaultValue={""}
                />
              )}
              {/* {toggleUnits === "unit_cost" && ( */}
              <TextFieldWithRegister
                label="Unit Cost"
                name="unit_cost"
                register={register}
                errors={errors}
                type="text"
                defaultValue={""}
              />
              {/* )} */}

              {/* {toggleUnits === "units_after_solar" && ( */}
              <TextFieldWithRegister
                label="Monthly Units After Solar"
                name="monthly_units_after_solar"
                // register={register}
                register={(name, options) =>
                  register(name, { ...options, required: false })
                }
                errors={errors}
                type="text"
                defaultValue={""}
              />
            </Stack>
          </QuoteAccordion>
          <QuoteAccordion
            title="Price Breakdown"
            titleStyle={{ fontSize: "1rem" }}
            defaultExpanded={false}
          >
            <Stack
              direction={isSmallScreen ? "column" : "row"}
              alignItems={isSmallScreen ? "flex-start" : "center"}
              columnGap={2}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkCumulative}
                      onChange={() => {
                        if (!checkNoPriceBreakdown) {
                          setCheckCumulative((prev) => !prev);
                        }
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Cumulative Tax"
                />
              </FormGroup>
              {checkCumulative && (
                <>
                  <TextField
                    size="small"
                    type="text"
                    label="Tax value (%)"
                    name="cumulative_tax"
                    defaultValue={cumulativeTax || 0}
                    onChange={(e) => {
                      setCumulativeTax(e.target.value);
                    }}
                    // error={errors.rate}
                    // helperText={errors.rate && errors.rate.message}
                  />
                </>
              )}

              {/* check price breaksown */}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkNoPriceBreakdown}
                      onChange={() => {
                        setCheckNoPriceBreakdown((prev) => !prev);
                        setCheckCumulative(true);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="No Price Breakdown"
                />
              </FormGroup>
              {checkNoPriceBreakdown && (
                <>
                  <TextField
                    size="small"
                    type="text"
                    label="Total Price"
                    name="total_price"
                    // defaultValue={totalPrice || ""}
                    defaultValue="0"
                    onChange={(e) => {
                      setTotalPrice(e.target.value);
                    }}
                    // error={errors.rate}
                    // helperText={errors.rate && errors.rate.message}
                  />
                </>
              )}
            </Stack>
            <Table
              // sx={{
              //   "@media (max-width: 900px)": {
              //     display: "none",
              //   },
              //   border: "none",
              //   width: "100%",
              //   "& tbody tr:nth-of-type(odd)": {
              //     backgroundColor: "inherit", // Remove any odd row background color
              //   },
              //   "& tbody tr:nth-of-type(even)": {
              //     backgroundColor: "inherit", // Remove any even row background color
              //   },
              // }}
              sx={{
                border: "none",
                width: "100%",
                "& tbody tr:nth-of-type(odd)": {
                  backgroundColor: "inherit", // Remove any odd row background color
                },
                "& tbody tr:nth-of-type(even)": {
                  backgroundColor: "inherit", // Remove any even row background color
                },
              }}
            >
              <tbody style={{ display: "block" }}>
                {productsData.map((product, i) => {
                  const entries = Object.entries(product.properties);
                  const firstRowEntries = entries.slice(0, 3);
                  const secondRowEntries = entries.slice(3);

                  const totalValue = (
                    (parseFloat(product.properties.unit_price) +
                      (parseFloat(product.properties.cgst) / 100) *
                        parseFloat(product.properties.unit_price) +
                      (parseFloat(product.properties.igst) / 100) *
                        parseFloat(product.properties.unit_price) +
                      (parseFloat(product.properties.sgst) / 100) *
                        parseFloat(product.properties.unit_price)) *
                    parseInt(product.properties.units)
                  ).toFixed(2);

                  // Function to format label with first letter of each word uppercase
                  const formatLabel = (label) => {
                    return label
                      .replace(/_/g, " ") // Replace underscores with spaces
                      .replace(
                        /\w\S*/g,
                        (txt) =>
                          txt.charAt(0).toUpperCase() +
                          txt.substr(1).toLowerCase()
                      ); // Capitalize first letter of each word
                  };

                  return (
                    <React.Fragment key={"product" + i}>
                      {isSmallScreen && (
                        <>
                          {/* <tr
                              style={{
                                border: "none",
                                borderTop: "2px solid #eee",
                              }}
                            >
                              <td
                                rowSpan={1}
                                style={{
                                  width: "50%",
                                  // border: "2px solid #eee",
                                  borderBottom: "2px solid #eee",
                                  borderLeft: "2px solid #eee",
                                  borderRight: "none",
                                }}
                              >
                                {i + 1}
                              </td>

                              <td
                                rowSpan={1}
                                style={{
                                  width: "50%",
                                  border: "2px solid #eee",
                                  borderBottom: "2px solid #eee",
                                  borderLeft: "none",
                                }}
                              >
                                <Button
                                  onClick={() => {
                                    setProductsData((prev) =>
                                      prev.filter((ele, k) => k !== i)
                                    );
                                  }}
                                >
                                  <Close sx={{ color: "red" }} />
                                </Button>
                              </td>
                            </tr> */}

                          <tr
                            style={{
                              border: "none",
                              borderTop: "2px solid #eee",
                            }}
                          >
                            <td
                              rowSpan={1}
                              style={{
                                borderBottom: "2px solid #eee",
                                borderLeft: "2px solid #eee",
                                borderRight: "none",
                                textAlign: "left", // Align content to the left
                                padding: "10px", // Adjust padding if needed
                              }}
                            >
                              <p>{i + 1}</p>
                            </td>

                            <td
                              rowSpan={1}
                              style={{
                                border: "2px solid #eee",
                                borderBottom: "2px solid #eee",
                                borderLeft: "none",
                                textAlign: "right", // Align content to the right
                                padding: "10px", // Adjust padding if needed
                              }}
                            >
                              <Button
                                onClick={() => {
                                  setProductsData((prev) =>
                                    prev.filter((ele, k) => k !== i)
                                  );
                                }}
                              >
                                <Close sx={{ color: "red" }} />
                              </Button>
                            </td>
                          </tr>

                          {firstRowEntries.length >= 3 && (
                            <tr>
                              <td
                                colSpan={2}
                                width="20%"
                                style={{
                                  border: "none",
                                  borderTop: "2px solid #eee",
                                  borderLeft: "2px solid #eee",
                                  borderRight: "2px solid #eee",
                                }}
                              >
                                <LightTextField
                                  sx={{
                                    minWidth: "100%",
                                    "& .MuiOutlinedInput-root": {
                                      "& fieldset": {
                                        borderColor: "#ffd05b",
                                        borderWidth: "2px",
                                      },
                                      "&:hover fieldset": {
                                        borderColor: "#ffd05b",
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: "#ffd05b",
                                      },
                                    },
                                  }}
                                  placeholder={formatLabel(
                                    firstRowEntries[0][0]
                                  )}
                                  label={formatLabel(firstRowEntries[0][0])}
                                  type="text"
                                  size="small"
                                  name={firstRowEntries[0][0]}
                                  value={firstRowEntries[0][1]}
                                  onChange={(e) => {
                                    setProductsData((prev) => {
                                      prev[i].properties[
                                        firstRowEntries[0][0]
                                      ] = e.target.value;
                                      return [...prev];
                                    });
                                  }}
                                />
                              </td>
                            </tr>
                          )}

                          {firstRowEntries.length >= 2 &&
                            firstRowEntries.slice(1, 3).map((row, idx) => (
                              <tr
                                style={{
                                  borderLeft: "2px solid #eee",
                                  borderRight: "2px solid #eee",
                                }}
                              >
                                <td
                                  colSpan={2}
                                  width="auto"
                                  style={{
                                    border: "none",
                                    borderTop: "2px solid #eee",
                                  }}
                                  key={idx}
                                >
                                  <LightTextField
                                    sx={{
                                      minWidth: "100%",
                                      "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                          borderColor: "#ffd05b",
                                          borderWidth: "2px",
                                        },
                                        "&:hover fieldset": {
                                          borderColor: "#ffd05b",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: "#ffd05b",
                                        },
                                      },
                                    }}
                                    placeholder={formatLabel(row[0])}
                                    label={formatLabel(row[0])}
                                    type="text"
                                    size="small"
                                    name={row[0]}
                                    // value={row[1]}
                                    onChange={(e) => {
                                      setProductsData((prev) => {
                                        // prev[i].properties[row[0]] =
                                        //   e.target.value;
                                        // unit_price: number;
                                        // units: number;
                                        // unit_type: string;
                                        // hsn_sac_code: string;
                                        prev[i].properties[row[0]] =
                                          [
                                            "unit_price",
                                            "units",
                                            "unit_type",
                                            "hsn_sac_code",
                                          ].includes(row[0]) &&
                                          checkNoPriceBreakdown &&
                                          e.target.value === "-"
                                            ? 0
                                            : e.target.value;
                                        return [...prev];
                                      });
                                    }}
                                    value={
                                      [
                                        "unit_price",
                                        "units",
                                        "unit_type",
                                        "hsn_sac_code",
                                      ].includes(row[0]) &&
                                      checkNoPriceBreakdown
                                        ? "-"
                                        : row[1]
                                    }
                                    InputProps={{
                                      readOnly:
                                        [
                                          "unit_price",
                                          "units",
                                          "unit_type",
                                          "hsn_sac_code",
                                        ].includes(row[0]) &&
                                        checkNoPriceBreakdown,
                                    }}

                                    // InputProps={{
                                    //   readOnly:
                                    //     ["igst", "cgst", "sgst"].includes(
                                    //       row[0]
                                    //     ) && checkCumulative,
                                    // }}
                                  />
                                </td>
                              </tr>
                            ))}

                          {secondRowEntries.map(([key, value], j) => (
                            <tr
                              style={{
                                border: "none",
                                borderLeft: "2px solid #eee",
                                borderRight: "2px solid #eee",
                              }}
                            >
                              <td
                                colSpan={2}
                                width={j === 0 ? "20%" : "auto"}
                                key={j}
                                style={{
                                  border: "none",
                                  borderBottom: "2px solid #eee",
                                }}
                              >
                                <LightTextField
                                  // sx={{ minWidth: "4rem" }}
                                  sx={{
                                    minWidth: "100%",
                                    "& .MuiOutlinedInput-root": {
                                      "& fieldset": {
                                        borderColor: "#ffd05b",
                                        borderWidth: "2px",
                                      },
                                      "&:hover fieldset": {
                                        borderColor: "#ffd05b",
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: "#ffd05b",
                                      },
                                    },
                                  }}
                                  placeholder={formatLabel(key)}
                                  label={formatLabel(key)}
                                  type="text"
                                  size="small"
                                  name={key}
                                  //   value={value}
                                  onChange={(e) => {
                                    setProductsData((prev) => {
                                      // prev[i].properties[key] = e.target.value;
                                      prev[i].properties[key] =
                                        [
                                          "igst",
                                          "cgst",
                                          "sgst",
                                          "unit_type",
                                          "hsn_sac_code",
                                        ].includes(key) &&
                                        checkNoPriceBreakdown &&
                                        checkCumulative &&
                                        e.target.value === "-"
                                          ? 0
                                          : e.target.value;
                                      return [...prev];
                                    });
                                  }}
                                  value={
                                    [
                                      "igst",
                                      "cgst",
                                      "sgst",
                                      "unit_type",
                                      "hsn_sac_code",
                                    ].includes(key) &&
                                    checkNoPriceBreakdown &&
                                    checkCumulative
                                      ? "-"
                                      : value
                                  }
                                  // onChange={(e) => {
                                  //   setProductsData((prev) => {
                                  //     const newValue = e.target.value;
                                  //     return prev.map((item, index) => {
                                  //       if (index === i) {
                                  //         const updatedProperties = {
                                  //           ...item.properties,
                                  //         };
                                  //         if (
                                  //           ["igst", "cgst", "sgst"].includes(
                                  //             key
                                  //           ) &&
                                  //           checkCumulative
                                  //         ) {
                                  //           updatedProperties[key] = 0;
                                  //         } else {
                                  //           updatedProperties[key] = newValue;
                                  //         }
                                  //         return {
                                  //           ...item,
                                  //           properties: updatedProperties,
                                  //         };
                                  //       }
                                  //       return item;
                                  //     });
                                  //   });
                                  // }}
                                  InputProps={{
                                    readOnly:
                                      (["igst", "cgst", "sgst"].includes(key) &&
                                        checkCumulative) ||
                                      (["unit_type", "hsn_sac_code"].includes(
                                        key
                                      ) &&
                                        checkNoPriceBreakdown),
                                  }}
                                />
                              </td>
                            </tr>
                          ))}

                          <tr
                            style={{
                              border: "2px solid #eee",
                              borderTop: 0,
                            }}
                          >
                            <td
                              colSpan={entries.length}
                              style={{ fontWeight: "bold" }}
                            >
                              Total Price: {totalValue}
                            </td>
                          </tr>
                          <tr style={{ border: "none" }}></tr>
                        </>
                      )}
                      {!isSmallScreen && (
                        <>
                          <tr style={{ border: "none" }}>
                            <td
                              rowSpan={2}
                              style={{
                                width: "1rem",
                                border: "2px solid #eee",
                                borderBottom: "2px solid #eee",
                              }}
                            >
                              {i + 1}
                            </td>
                            {firstRowEntries.length >= 3 && (
                              <td
                                colSpan={3}
                                width="20%"
                                style={{
                                  border: "none",
                                  borderTop: "2px solid #eee",
                                }}
                              >
                                <LightTextField
                                  sx={{
                                    minWidth: "100%",
                                    "& .MuiOutlinedInput-root": {
                                      "& fieldset": {
                                        borderColor: "#ffd05b",
                                        borderWidth: "2px",
                                      },
                                      "&:hover fieldset": {
                                        borderColor: "#ffd05b",
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: "#ffd05b",
                                      },
                                    },
                                  }}
                                  placeholder={formatLabel(
                                    firstRowEntries[0][0]
                                  )}
                                  label={formatLabel(firstRowEntries[0][0])}
                                  type="text"
                                  size="small"
                                  name={firstRowEntries[0][0]}
                                  value={firstRowEntries[0][1]}
                                  onChange={(e) => {
                                    setProductsData((prev) => {
                                      prev[i].properties[
                                        firstRowEntries[0][0]
                                      ] = e.target.value;
                                      return [...prev];
                                    });
                                  }}
                                />
                              </td>
                            )}
                            {firstRowEntries.length >= 2 &&
                              firstRowEntries.slice(1, 3).map((row, idx) => (
                                <td
                                  colSpan={1}
                                  width="auto"
                                  style={{
                                    border: "none",
                                    borderTop: "2px solid #eee",
                                  }}
                                  key={idx}
                                >
                                  <LightTextField
                                    sx={{
                                      minWidth: "100%",
                                      "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                          borderColor: "#ffd05b",
                                          borderWidth: "2px",
                                        },
                                        "&:hover fieldset": {
                                          borderColor: "#ffd05b",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: "#ffd05b",
                                        },
                                      },
                                    }}
                                    placeholder={formatLabel(row[0])}
                                    label={formatLabel(row[0])}
                                    type="text"
                                    size="small"
                                    name={row[0]}
                                    // value={row[1]}
                                    onChange={(e) => {
                                      setProductsData((prev) => {
                                        // prev[i].properties[row[0]] =
                                        //   e.target.value;
                                        // unit_price: number;
                                        // units: number;
                                        // unit_type: string;
                                        // hsn_sac_code: string;
                                        prev[i].properties[row[0]] =
                                          [
                                            "unit_price",
                                            "units",
                                            "unit_type",
                                            "hsn_sac_code",
                                          ].includes(row[0]) &&
                                          checkNoPriceBreakdown &&
                                          e.target.value === "-"
                                            ? 0
                                            : e.target.value;
                                        return [...prev];
                                      });
                                    }}
                                    value={
                                      [
                                        "unit_price",
                                        "units",
                                        "unit_type",
                                        "hsn_sac_code",
                                      ].includes(row[0]) &&
                                      checkNoPriceBreakdown
                                        ? "-"
                                        : row[1]
                                    }
                                    InputProps={{
                                      readOnly:
                                        [
                                          "unit_price",
                                          "units",
                                          "unit_type",
                                          "hsn_sac_code",
                                        ].includes(row[0]) &&
                                        checkNoPriceBreakdown,
                                    }}

                                    // InputProps={{
                                    //   readOnly:
                                    //     ["igst", "cgst", "sgst"].includes(
                                    //       row[0]
                                    //     ) && checkCumulative,
                                    // }}
                                  />
                                </td>
                              ))}
                            <td
                              rowSpan={2}
                              style={{
                                width: "1rem",
                                border: "2px solid #eee",
                                borderBottom: "2px solid #eee",
                              }}
                            >
                              <Button
                                onClick={() => {
                                  setProductsData((prev) =>
                                    prev.filter((ele, k) => k !== i)
                                  );
                                }}
                              >
                                <Close sx={{ color: "red" }} />
                              </Button>
                            </td>
                          </tr>
                          <tr style={{ border: "none" }}>
                            {secondRowEntries.map(([key, value], j) => (
                              <td
                                width={j === 0 ? "20%" : "auto"}
                                key={j}
                                style={{
                                  border: "none",
                                  borderBottom: "2px solid #eee",
                                }}
                              >
                                <LightTextField
                                  // sx={{ minWidth: "4rem" }}
                                  sx={{
                                    minWidth: "100%",
                                    "& .MuiOutlinedInput-root": {
                                      "& fieldset": {
                                        borderColor: "#ffd05b",
                                        borderWidth: "2px",
                                      },
                                      "&:hover fieldset": {
                                        borderColor: "#ffd05b",
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: "#ffd05b",
                                      },
                                    },
                                  }}
                                  placeholder={formatLabel(key)}
                                  label={formatLabel(key)}
                                  type="text"
                                  size="small"
                                  name={key}
                                  //   value={value}
                                  onChange={(e) => {
                                    setProductsData((prev) => {
                                      // prev[i].properties[key] = e.target.value;
                                      prev[i].properties[key] =
                                        [
                                          "igst",
                                          "cgst",
                                          "sgst",
                                          "unit_type",
                                          "hsn_sac_code",
                                        ].includes(key) &&
                                        checkNoPriceBreakdown &&
                                        checkCumulative &&
                                        e.target.value === "-"
                                          ? 0
                                          : e.target.value;
                                      return [...prev];
                                    });
                                  }}
                                  value={
                                    [
                                      "igst",
                                      "cgst",
                                      "sgst",
                                      "unit_type",
                                      "hsn_sac_code",
                                    ].includes(key) &&
                                    checkNoPriceBreakdown &&
                                    checkCumulative
                                      ? "-"
                                      : value
                                  }
                                  // onChange={(e) => {
                                  //   setProductsData((prev) => {
                                  //     const newValue = e.target.value;
                                  //     return prev.map((item, index) => {
                                  //       if (index === i) {
                                  //         const updatedProperties = {
                                  //           ...item.properties,
                                  //         };
                                  //         if (
                                  //           ["igst", "cgst", "sgst"].includes(
                                  //             key
                                  //           ) &&
                                  //           checkCumulative
                                  //         ) {
                                  //           updatedProperties[key] = 0;
                                  //         } else {
                                  //           updatedProperties[key] = newValue;
                                  //         }
                                  //         return {
                                  //           ...item,
                                  //           properties: updatedProperties,
                                  //         };
                                  //       }
                                  //       return item;
                                  //     });
                                  //   });
                                  // }}
                                  InputProps={{
                                    readOnly:
                                      (["igst", "cgst", "sgst"].includes(key) &&
                                        checkCumulative) ||
                                      (["unit_type", "hsn_sac_code"].includes(
                                        key
                                      ) &&
                                        checkNoPriceBreakdown),
                                  }}
                                />
                              </td>
                            ))}
                          </tr>
                          <tr
                            style={{
                              border: "2px solid #eee",
                              borderTop: 0,
                            }}
                          >
                            <td
                              colSpan={entries.length}
                              style={{ fontWeight: "bold" }}
                            >
                              Total Price: {totalValue}
                            </td>
                          </tr>
                          <tr style={{ border: "none" }}></tr>
                        </>
                      )}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </Table>
            <Button
              variant="outlined"
              sx={{
                px: 3,
                display: "flex",
                alignItems: "center",
                // color: "#ffd05b",
                fontWeight: "bold",
                border: "1px solid #4d4d4d",
                color: "primary.dark",
              }}
              onClick={addProduct}
            >
              Add a new row
            </Button>
          </QuoteAccordion>
        </Stack>
      </Box>
    </Box>
  );
};

export default WareeProposalService;
