import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import { Box, Chip, Container } from '@mui/material';
import { useEffect } from 'react';
import { axiAuth } from '../../../utils/axiosInstance';
import Pagination from '@mui/material/Pagination';
import EnquiryDetailsDrawer from './EnquiryDetailsDrawer';

const StyledListItem = styled(ListItem)(({ theme, even }) => ({
   backgroundColor: !even ? '#f3f3f3' : '#ffffff',
   '&:hover': {
      backgroundColor: theme.palette.action.hover,
   },
}));

const StyledDrawer = styled(Drawer)({
   width: 400,
   flexShrink: 0,
});

const StyledDrawerPaper = styled('div')(({ theme }) => ({
   width: '720px',
   backgroundColor: theme.palette.primary.light,
   padding: theme.spacing(2),
   minHeight: '100vh',
}));

const ListItemWithDetails = ({ enquiry, onSelect, selectedItem }) => {
   const [open, setOpen] = useState(false);

   const [enquiryDetails, setEnquiryDetails] = useState(null);
   // const [loading, setLoading] = useState(false);
   // const [error, setError] = useState(null);

   const handleClick = async () => {
      setOpen(!open);
      onSelect(enquiry);

      try {
         // setLoading(true);
         const { data } = await axiAuth.get(
            `api/admin/enquiries/${enquiry.enquiry_id}`
         );
         console.log(data.enquiries);
         setEnquiryDetails(data);
         // setLoading(false);
         // setError(null);
      } catch (error) {
         // setLoading(false);
         // setError('Error Fetching Details for this enquiry');
      }
   };

   return (
      <>
         <StyledListItem
            button
            onClick={handleClick}
            even={enquiry.enquiry_id % 2 === 0}
         >
            <Box
               sx={{
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: 2,
               }}
            >
               <Chip
                  label={`Enquiry Id ${enquiry.enquiry_id}`}
                  sx={{ width: '150px', fontWeight: 600 }}
               />
               <Typography variant='h6' sx={{
                  fontSize: '1rem'
               }}>
                  {enquiry?.product?.other?.productName}
               </Typography>

               <Chip
                  label={`Created at ${enquiry?.created_at}`}
                  sx={{ width: '200px', fontWeight: 600 }}
               />
               <Chip
                  label={`Delivery Date ${enquiry?.delivery_date}`}
                  sx={{ width: '200px', fontWeight: 600 }}
               />
               <Chip
                  label={`Address ${enquiry?.user_address?.city}, ${enquiry?.user_address?.state}`}
                  sx={{ width: '250px', fontWeight: 600 }}
               />
            </Box>
         </StyledListItem>
         <StyledDrawer
            anchor='right'
            open={open}
            onClose={() => setOpen(false)}
         >
            <StyledDrawerPaper>
               <Typography variant='h6'>
                  More details of product {enquiry.product_name}
               </Typography>
               <EnquiryDetailsDrawer enquiry={enquiryDetails} />
            </StyledDrawerPaper>
         </StyledDrawer>
      </>
   );
};

const EnquiryListForAdmin = () => {
   const [selectedItem, setSelectedItem] = useState(null);

   const handleSelect = (item) => {
      setSelectedItem(item);
   };

   const [enquiries, setEnquiries] = useState([]);
   const [loading, setLoading] = useState(false);
   const [error, setError] = useState(null);

   const [page, setPage] = useState(1);
   // const [hasMorePages, setHasMorePages] = useState(false);
   const [lastPage, setLastPage] = useState(0);

   useEffect(() => {
      async function fetchEnquiries() {
         try {
            setLoading(true);
            const { data, status } = await axiAuth(
               `api/admin/enquiries?page=${page}&limit=5`
            );
            if (status === 200) {
               setEnquiries(data.enquiries);
               setLoading(false);

               // setHasMorePages(data.has_more_pages);
               setLastPage(data?.pagination?.last_page);
            }
         } catch (error) {
            setError(error);
            setLoading(false);
         }
      }
      fetchEnquiries();
   }, [page]);

   const handlePaginationChange = (event, value) => {
      setPage(value);
   };

   if (error) {
      return <div>Something went wrong!</div>;
   }

   if (loading) {
      return <div>Loading...</div>;
   }

   return (
      <Container>
         <List>
            <Typography variant='h4' textAlign='center'>
               Enquiry List
            </Typography>

            {enquiries?.map((enquiry) => (
               <ListItemWithDetails
                  key={enquiry.enquiry_id}
                  enquiry={enquiry}
                  onSelect={handleSelect}
                  selectedItem={selectedItem}
               />
            ))}
         </List>
         <Box sx={{ width: '100%' }}>
            <Pagination
               sx={{ marginTop: '16px', mx: 'auto', width: 'fit-content' }}
               count={lastPage}
               page={page}
               onChange={handlePaginationChange}
               color='primary'
               size='large'
               // disabled={!hasMorePages}
            />
         </Box>
      </Container>
   );
};

export default EnquiryListForAdmin;
