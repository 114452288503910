import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const truncateHTML = (html, length) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  const text = tempDiv.innerText;
  return text.length > length ? text.slice(0, length) + "..." : html;
};

const extractDate = (timestamp) => {
  const date = new Date(timestamp);
  return date.toLocaleDateString(); // This will return the date in the local date format
};

const getTimeAgo = (timestamp) => {
  const now = new Date();
  const givenDate = new Date(timestamp);

  // Helper function to strip time part from a date
  const stripTime = (date) =>
    new Date(date.getFullYear(), date.getMonth(), date.getDate());

  // Strip time parts for accurate comparison
  const strippedNow = stripTime(now);
  const strippedGivenDate = stripTime(givenDate);

  if (strippedNow.getTime() === strippedGivenDate.getTime()) {
    return "Today";
  }

  const diffTime = Math.abs(now - givenDate);
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  const diffMonths = Math.floor(diffDays / 30); // Rough estimate of a month
  const diffYears = Math.floor(diffDays / 365); // Rough estimate of a year

  if (diffYears > 0) {
    return `${diffYears} year${diffYears > 1 ? "s" : ""} ago`;
  } else if (diffMonths > 0) {
    return `${diffMonths} month${diffMonths > 1 ? "s" : ""} ago`;
  } else {
    return `${diffDays} day${diffDays > 1 ? "s" : ""} ago`;
  }
};

const ShortReadsCard = ({ blog = {} }) => {
  const navigate = useNavigate();
  const imageMatch = blog.description
    .toString()
    .match(/<img[^>]+src="([^">]+)"/);
  const image = imageMatch ? imageMatch[1] : null;
  const description = blog.description.replace(/<img[^>]*>/g, "");
  const truncatedDescription = truncateHTML(description, 260);
  const pubDate = extractDate(blog.pubDate);
  const timeAgo = getTimeAgo(blog.pubDate);
  return (
    <Stack
      direction="row"
      sx={{
        width: "100%",
        maxWidth: "400px",
        height: 120,
        "&:hover": {
          cursor: "pointer",
          bgcolor: "#fbf9f9",
          borderRadius: 1,
        },
      }}
      alignItems="center"
      columnGap={2}
      // onClick={() => navigate(blog.route)}
      onClick={() => window.open(blog.link, "_blank")}
    >
      <Box
        sx={{
          "& img": {
            objectFit: "cover",
            width: "100%",
            height: "100%",
          },
          flex: 1,
          height: "100%",
          overflow: "hidden",
        }}
      >
        <img src={image} alt="test" />
      </Box>
      <Box sx={{ flex: 2 }}>
        <Typography variant="h6" fontWeight={600}>
          {blog.title.length > 34
            ? blog.title.slice(0, 34) + "..."
            : blog.title}
        </Typography>
        {/* <Typography variant="h6" fontWeight={600}>
          {blog.description}
        </Typography> */}

        <Typography variant="subtitle" color="#6e6e6e">
          {pubDate}
        </Typography>
      </Box>
    </Stack>
  );
};

export default ShortReadsCard;
