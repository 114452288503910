import { FormControlLabel, Switch } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';

const IOSSwitch = styled((props) => (
   <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
   width: 42,
   height: 26,
   padding: 0,
   '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
         transform: 'translateX(16px)',
         color: '#fff',
         '& + .MuiSwitch-track': {
            backgroundColor:
               theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
            opacity: 1,
            border: 0,
         },
         '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
         },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
         color: '#33cf4d',
         border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
         color:
            theme.palette.mode === 'light'
               ? theme.palette.grey[100]
               : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
         opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
   },
   '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
      borderRadius: '4px !important',
   },
   '& .MuiSwitch-track': {
      borderRadius: 10 / 2,
      backgroundColor: theme.palette.mode === 'light' ? 'red' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
         duration: 500,
      }),
   },
}));

const SolrufSwitch = ({ checked, onChange, sx }) => {
   return (
      <FormControlLabel
         sx={{ ...sx }}
         control={<IOSSwitch sx={{}} defaultChecked />}
         label=''
         checked={checked}
         onChange={onChange}
         inputprops={{ 'aria-label': 'controlled' }}
      />
   );
};

export default SolrufSwitch;
