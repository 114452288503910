import React from 'react';
import { Box } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Pagination, Autoplay } from 'swiper';

// import banner2 from '../../../../assets/banners/Banner 2.svg';
// import banner4 from '../../../../assets/banners/Banner 4.svg';
// import banner6 from '../../../../assets/banners/Banner 6.svg';
// import banner3 from '../../../../assets/banners/Banner 3.svg';



import './bannerCarousel.style.css'

const BannerCarousel = () => {
   return (
      <Box>
         <Swiper
            pagination={{
               dynamicBullets: true,
               clickable: true,
            }}
            modules={[Pagination, Autoplay]}
            className='homeBannerSlider'
            autoplay={{
               delay: 3000,
               disableOnInteraction: false,
               pauseOnMouseEnter: true,
            }}
            loop={true}
           
         >
            <SwiperSlide>
               <img src='https://solruf-node-static-files.s3.ap-south-1.amazonaws.com/Banner+10.png' alt='banner10' />
            </SwiperSlide>
            <SwiperSlide>
               <img src='https://solruf-node-static-files.s3.ap-south-1.amazonaws.com/Bnanner+5.png' alt='banner7' />
            </SwiperSlide>
            <SwiperSlide>
               <img src='https://solruf-node-static-files.s3.ap-south-1.amazonaws.com/Banner+9.jpg' alt='banner9' />
            </SwiperSlide>
            {/* <SwiperSlide>
               <img src={banner6} alt='banner6' />
            </SwiperSlide>
            <SwiperSlide>
               <img src={banner2} alt='banner2' />
            </SwiperSlide>
            <SwiperSlide>
               <img src={banner4} alt='banner4' />
            </SwiperSlide>
            <SwiperSlide>
               <img src={banner3} alt='banner3' />
            </SwiperSlide> */}
          
         </Swiper>
      </Box>
   );
};

export default BannerCarousel;
