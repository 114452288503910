import MenuItem from "@mui/material/MenuItem";
import SolrufTextField from "../../SolrufTextField/SolrufTextField";

const SelectFieldWithRegister = ({
  label,
  name,
  register,
  errors,
  options,
  ...rest
}) => (
  <SolrufTextField
    select
    label={label}
    error={errors[name]}
    {...register(name, {
      required: {
        value: true,
        message: `Please select ${label.toLowerCase()}`,
      },
    })}
    helperText={errors[name] ? errors[name].message : ""}
    size="small"
    {...rest}
  >
    {options.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))}
  </SolrufTextField>
);

export default SelectFieldWithRegister;
