import { Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { LibraryIcon } from '@heroicons/react/solid';
import NavigationIcon from '@mui/icons-material/Navigation';

const ProductDetailList = ({
   list,
   description,
   hand,
   home,
   sx,
   fontSize1,
   fontSize2,
}) => {
   const match500 = useMediaQuery((theme) => theme.breakpoints.down(500));

   if (home) {
      return (
         <>
            <Box
               sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  ...sx,
               }}
            >
               <LibraryIcon
                  style={{ width: '25px', marginRight: '.5rem', flexShrink: 0 }}
               />

               <Typography
                  variant='body1'
                  sx={{ fontSize: `${fontSize1 ? fontSize1 : '0.6rem'}` }}
               >
                  <strong>{list}</strong> {description}
               </Typography>
            </Box>
         </>
      );
   }

   return (
      <>
         <Box sx={{ display: 'flex', alignItems: 'flex-start', mt: 1, ...sx }}>
            {!hand ? (
               <NavigationIcon
                  sx={{
                     width: '15px',
                     color: '#3FB500',
                     fontWeight: 'bolder',
                     marginRight: match500 ? '0.5rem': '0.7rem',
                     transform: 'rotate(90deg)'

                  }}
               />
            ) : (
               <Box sx={{ mr: '0.9rem' }}>&#128073;</Box>
            )}
            <Typography sx={{ fontSize: `${fontSize2 ? fontSize2 : match500 ? '11px' : '0.8rem'}` }}>
               <strong>{list}</strong> {description}
            </Typography>
         </Box>
      </>
   );
};

export default ProductDetailList;
