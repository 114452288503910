const quotationDateFormatter = (inputObject) => {
   const dateParts = inputObject.date.split('-');
   const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
   ];
   const formattedDate = `${parseInt(dateParts[2], 10)}-${
      monthNames[parseInt(dateParts[1], 10) - 1]
   }-${dateParts[0]}`;

   // Calculate the difference between date and dueDate in days
   const dateObj = new Date(inputObject.date);
   const dueDateObj = new Date(inputObject.dueDate);
   const timeDiff = dueDateObj - dateObj;
   const dayDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

   // Convert the due date to the desired format
   const dueDateParts = inputObject.dueDate.split('-');
   const formattedDueDate = `${parseInt(dueDateParts[2], 10)}-${
      monthNames[parseInt(dueDateParts[1], 10) - 1]
   }-${dueDateParts[0]}`;

   // Output object
   const outputObject = {
      formatted_date: formattedDate,
      formatted_dueDate: formattedDueDate,
      dueDateDifference: dayDiff,
   };

   return outputObject;
};

export default quotationDateFormatter;
