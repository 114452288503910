import { useState, useEffect } from 'react';

const useDiscountCalculator = (...params) => {
   const [price, quantity, couponInfo] = params;
   const [discount, setDiscount] = useState(0);
   const [grandTotal, setGrandTotal] = useState(0);

   useEffect(() => {
      let gTotal = price * quantity;
      if (couponInfo.valid) {
         const percentage = couponInfo.less;
         const discount = (percentage / 100) * gTotal;
         setDiscount(discount);
         const discountedGrandTotal = gTotal - discount;
         setGrandTotal(discountedGrandTotal);
      } else {
         setGrandTotal(gTotal);
         setDiscount(0);
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, params);

   return { discount, grandTotal };
};

export default useDiscountCalculator;
