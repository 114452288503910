// BasicDetailsForm.js
import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import { Controller } from "react-hook-form";
import useSolrufPinCode from "../../hooks/useSolrufPinCode";
import SolrufTextField from "../SolrufTextField/SolrufTextField";
import { useSelector } from "react-redux";
import { useDebounce } from "use-debounce";

const PincodeCityState2 = ({ formMethods, customerDetails, sx }) => {
  const { register, formState, setValue, watch, control } = formMethods;
  const statesOfIndia = useSelector((state) => state.utils.statesOfIndia);

  const [pincode] = watch(["pincode"]);
  const [debouncedPinCode] = useDebounce(pincode, 1000);
  const { indiaState: stateByPin, district } =
    useSolrufPinCode(debouncedPinCode);

  const currentCity = watch("city");
  const currentState = watch("state");
  console.log("Current City:", currentCity);
  console.log("Current Customer:", customerDetails);

  useEffect(() => {
    if (stateByPin && district) {
      setValue("city", district);
      setValue("state", stateByPin);
    }
  }, [stateByPin, district, setValue]);

  return (
    <Box sx={{ ...sx }}>
      <SolrufTextField
        size="small"
        label="Pin-code/Zip-code"
        sx={{ mr: 2 }}
        defaultValue={customerDetails?.pincode || ""}
        //   value={customerDetails?.location?.pincode || ""}
        {...register("pincode", {
          required: {
            value: true,
            message: "Pincode is required",
          },
        })}
        error={formState.errors.pincode}
        helperText={
          formState.errors.pincode ? formState.errors.pincode.message : ""
        }
      />
      <SolrufTextField
        size="small"
        label="City/District"
        sx={{ mr: 2 }}
        defaultValue={customerDetails?.city || ""}
        {...register("city", {
          required: {
            value: true,
            message: "City is required",
          },
        })}
        InputLabelProps={{ shrink: currentCity && true }}
        error={formState.errors.city}
        helperText={formState.errors.city ? formState.errors.city.message : ""}
      />

      <SolrufTextField
        size="small"
        label="State"
        sx={{ mr: 2 }}
        defaultValue={customerDetails?.state || ""}
        {...register("state", {
          required: {
            value: true,
            message: "State is required",
          },
        })}
        InputLabelProps={{ shrink: currentState && true }}
        error={formState.errors.state}
        helperText={
          formState.errors.state ? formState.errors.state.message : ""
        }
      />

      {/* <Controller
        control={control}
        name="state"
        defaultValue={customerDetails?.state || ""}
        rules={{ required: true, message: "State is required" }}
        render={({ field }) => (
          <>
            <SolrufTextField select label="State" {...field} size="small">
              {statesOfIndia.map((state) => (
                <MenuItem key={state} value={state}>
                  {state}
                </MenuItem>
              ))}
            </SolrufTextField>
          </>
        )}
      /> */}
    </Box>
  );
};

export default PincodeCityState2;
