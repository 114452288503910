import React, { forwardRef } from 'react';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material';

const StyledTextField = styled(TextField)(({ theme }) => ({
   '& label.Mui-focused': {
      color: theme.palette.primary.main,
   },
   '& .MuiOutlinedInput-root': {
      '& fieldset': {
         borderColor: theme.palette.primary.main,
         borderWidth: '2px',
      },
      '&:hover fieldset': {
         borderColor: theme.palette.primary.main,
      },
      '&.Mui-focused fieldset': {
         borderColor: theme.palette.primary.main,
      },
      '&.Mui-error fieldset': {
         borderColor: theme.palette.primary.main,
      },
   },
}));

const SolrufTextArea = forwardRef((props, ref) => {
   const { label, value, onChange, ...rest } = props;

   return (
      <StyledTextField
         //  className={classes.textField}
         label={label}
         multiline
         rows={4}
         variant='outlined'
         value={value}
         onChange={onChange}
         inputRef={ref}
         {...rest}
      />
   );
});

export default SolrufTextArea;
