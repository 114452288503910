import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import PrimaryButton from '../components/Custom/PrimaryButton/PrimaryButton';
import axios from 'axios';
import { useNavigate } from 'react-router';
import Loader from '../components/Loader/Loader';

const TestPaymentProduct = () => {
   const navigate = useNavigate();
   const [loading, setLoading] = useState(false);

   const handlePayment = async () => {
      setLoading(true);
      const {
         data: { order },
      } = await axios.post(`http://localhost:5000/api/v2/payment/create`, {
         amount: 600,
      });

      const options = {
         key: 'rzp_test_iDJGLVCA4jfLy6',
         amount: order.amount,
         currency: 'INR',
         name: 'Solruf',
         description: 'Solar Product Payment',
         image: 'https://i.ibb.co/rws9hV3/Whats-App-Image-2023-05-27-at-12-56-54.jpg',
         order_id: order.id,
         handler: async function (response) {
            const {
               razorpay_payment_id,
               // razorpay_order_id,
               razorpay_signature,
            } = response;

            const { status } = await axios.post(
               'http://localhost:5000/api/v2/payment/verification',
               {
                  razorpay_payment_id,
                  razorpay_order_id: order.id,
                  razorpay_signature,
                  orderInfo: {},
               }
            );

            if (status === 200) {
               navigate(`/paymentsuccess/${razorpay_payment_id}`);
            }
         },
         prefill: {
            name: 'Mizanur Rahman',
            email: 'mizanmahi24@gmail.com',
            contact: '9932383997',
         },
         notes: {
            address: 'Mumbai',
         },
         theme: {
            color: '#ffd05b',
         },
      };
      const razor = new window.Razorpay(options);
      razor.open();
      setLoading(false);
   };

   return (
      <Box
         sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '90vh',
         }}
      >
         <Box
            sx={{
               border: '1px solid #ccc',
               borderRadius: '8px',
               padding: '16px',
               maxWidth: '400px',
               textAlign: 'center',
               boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
            }}
         >
            <Typography variant='h4' gutterBottom>
               Solar Product
            </Typography>
            <Typography variant='body1' gutterBottom>
               Description of the fancy product.
            </Typography>
            <Typography variant='h5' gutterBottom>
               INR 600
            </Typography>
         </Box>

         {loading ? (
            <Loader />
         ) : (
            <PrimaryButton
               variant='contained'
               color='primary'
               onClick={handlePayment}
               sx={{ mt: 4 }}
            >
               Pay Now
            </PrimaryButton>
         )}
      </Box>
   );
};

export default TestPaymentProduct;
