import { axiAuth } from './axiosInstance';

const API_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzOGVkMmYwNGIzNmZlMGFmNTJkOWFkYSIsIm5hbWUiOiJTT0xSVUYiLCJhcHBOYW1lIjoiQWlTZW5zeSIsImNsaWVudElkIjoiNjM4ZWQyZjA0YjM2ZmUwYWY1MmQ5YWQ1IiwiYWN0aXZlUGxhbiI6IkJBU0lDX1RSSUFMIiwiaWF0IjoxNjcwMzA0NDk2fQ.lWMZXRChelN0fltvB-CNkSD0L8ubAXxgWWWK76wb3dk'

export const sendWhatsappNotification = async (
   userName,
   destination,
   campaignName,
   templateParams,
   media
) => {
   const data = {
      apiKey: API_KEY,
      campaignName,
      destination: `+91${destination}`,
      userName,
      media,
      templateParams,
   };

   try {
      const { status } = await axiAuth.post(
         `https://backend.aisensy.com/campaign/t1/api`,
         data
      );
      if (status === 200) {
         console.log('Whatsapp notification sent successfully');
      }
   } catch (error) {
      console.log(error.message);
   }
};
