import React from 'react';
import { Box, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom';
import orderConfirmedSvg from '../assets/order_confirmation.svg';
import PrimaryButton from '../components/Custom/PrimaryButton/PrimaryButton';

const OrderConfirmationPage = ({paymentId}) => {
   const navigate = useNavigate();

   const goToProductPage = () => {
      navigate('/products');
   };

   return (
      <Box
         display='flex'
         justifyContent='center'
         alignItems='center'
         height='100%'
         flexDirection='column'
         gap={3}
         sx={{
            position: 'relative',
            '&::before': {
               content: "''",
               position: 'absolute',
               top: 0,
               left: 0,
               right: 0,
               bottom: 0,
               backgroundImage: `url(${orderConfirmedSvg})`,
               backgroundPosition: 'center',
               opacity: 0.1, // Adjust the opacity value as per your preference
               backgroundSize: '50%',
               backgroundRepeat: 'no-repeat',
            },
         }}
      >
         <Box textAlign='center'>
            <CheckCircleIcon sx={{ fontSize: 80, color: 'green' }} />
            <Typography variant='h4' component='h1' gutterBottom>
               Order Confirmed!
            </Typography>
            <Typography variant='body1'>
               Your order has been successfully confirmed.
            </Typography>
            <Typography variant='body1'>Transaction ID: {paymentId}</Typography>
         </Box>

         <PrimaryButton onClick={goToProductPage}>
            Explore More Products
         </PrimaryButton>
      </Box>
   );
};

export default OrderConfirmationPage;
