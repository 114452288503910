


import { Container, Grid, Typography, styled } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';

import QuantityController from '../../components/QuantityController/QuantityController';
import { useForm } from 'react-hook-form';
import SolrufTextField from '../../components/SolrufTextField/SolrufTextField';
// import CompanyDetails from './CompanyDetails';
// import Success from './Success';
import SliderWIthThumbnail from '../../components/SliderWIthThumbnail/SliderWIthThumbnail';
import { useParams } from 'react-router';
import { axiAuth } from '../../utils/axiosInstance';

import { Flex } from '../EnquiryPage/enquiryPage.style';
import { toast } from 'react-toastify';
import { useScrollPosition } from '../../hooks/useScrollPosition';
import PrimaryButton from '../../components/Custom/PrimaryButton/PrimaryButton';

import noImage from '../../assets/no-image.jpeg';
import PageTitle from '../../components/PageTitle/PageTitle';

import EmbedYTVideo from '../../components/EmbedYTVideo/EmbedYTVideo';
import EnquiryProductDetails from '../EnquiryPage/EnquiryProductDetails';
import RoundedDarkButton from '../../components/RoundedDarkButton/RoundedDarkButton';
import axios from 'axios';
import SolrufModal from '../../components/Custom/SolrufModal/SolrufModal';
import GoToButton from '../Blogs/KusumSolarPumps/GoToButton';
import saveFormDataToGoogleSheet from '../../utils/saveFormDataToSheet';
import Coupon from '../../components/Coupon/Coupon';
import CouponAppliedCard from '../../components/Coupon/CouponAppliedCard';
import useDiscountCalculator from '../../hooks/useDiscountCalculator';

const FormWrapper = styled(Box)(({ theme }) => ({
   width: '100%',
   maxWidth: '600px',
   padding: '1.5rem',
   margin: '0 auto',
   display: 'flex',
   justifyContent: 'center',
   flexDirection: 'column',
   gap: '1rem',
   background: '#ffffff',
   borderRadius: '1rem',
}));

const noImages = [noImage, noImage, noImage, noImage, noImage, noImage];

const CheckoutProductPage = () => {
   const [isOpen, setIsOpen] = useState(false);

   const toggleDrawer = () => {
      setIsOpen(!isOpen);
   };

   const [quantity, setQuantity] = useState(1);
   const [quantityError, setQuantityError] = useState('');

   const {
      register,
      handleSubmit,
      reset,
      watch,
      formState: { errors, isSubmitting },
   } = useForm({
      defaultValues: {
         pincode: '',
      },
   });

   const { productSlug } = useParams();

   const [productDetails, setProductDetails] = useState({});
   const [productLoading, setProductLoading] = useState(true);

   // const [grandTotal, setGrandTotal] = useState(0);
   // const [discount, setDiscount] = useState(null);

   const [couponInfo, setCouponInfo] = useState({
      less: 0,
      title: '',
      expiresAt: '',
      valid: false,
   });

   console.log(couponInfo);
   console.log(productDetails);

   const price = 4500;

   useEffect(() => {
      setProductLoading(true);
      axiAuth(`api/products/get/${productSlug}`)
         .then((res) => {
            setProductDetails(res.data.product);
            setProductLoading(false);
         })
         .catch((err) => {
            toast.error(err.response.data.message);
            setProductLoading(false);
         });
   }, [productSlug]);

   const scrollPosition = useScrollPosition();

   const submitHandler = async (data) => {
      setQuantityError('');
      if (quantity < 1) {
         setQuantityError('Quantity cannot be less than 1');
         return;
      }
      const orderInfo = {
         quantity: quantity,
         product_id: productDetails?.product_id,
         product_name: productDetails?.product_name,
         ...data,
      };

      try {
         const { status, data } = await axios.post(
            'https://links.solruf.com/product-checkout/place-order-rocket',
            { orderInfo }
         );
         if (status === 200) {
            toast.success('Order Created Successfully');
            console.log(data);
            reset();
         }
      } catch (error) {
         console.log(error);
         toast.warn('Something went wrong while creating order');
      }

      try {
         orderInfo.pickup_pincode = '444606';
         orderInfo.price = price;
         const { status } = await saveFormDataToGoogleSheet(
            orderInfo,
            'https://script.google.com/macros/s/AKfycbz-235F-rzVfRwaRzjzOzvPuHHRilWyP5GISspDAEEyK8CY3yNmH8elK2SLGihoKGLhEQ/exec'
         );
         if (status === 200) {
            toast.success('Order Placed Successfully');
            console.log(data);
            reset();
         }
      } catch (error) {
         console.log(error);
         toast.warn('Something went wrong');
      }
   };

   const pincode = watch('pincode');

   const [minFreightCharge, setMinFreightCharge] = useState(0);

   const loginToShipRocket = async () => {
      const {
         data: { token },
      } = await axios.post(
         'https://apiv2.shiprocket.in/v1/external/auth/login',
         {
            email: 'mizanmahi24@gmail.com',
            password: '541990Mizan@',
         }
      );

      return token;
   };

   useEffect(() => {
      if (pincode?.length === 6) {
         loginToShipRocket().then((token) => {
            const config = {
               headers: {
                  // Authorization: `Bearer ${token}`,
                  Authorization: `Bearer ${token}`,
               },
            };

            axios
               .get(
                  `https://apiv2.shiprocket.in/v1/external/courier/serviceability/?cod=1&pickup_postcode=444606&delivery_postcode=${pincode}&weight=${productDetails.weight}&length=${productDetails.length}&breadth=${productDetails.breadth}&height=${productDetails.height}`,
                  config
               )
               .then((data) => {
                  console.log(data.data);
                  setMinFreightCharge(
                     Math.min(
                        ...data.data?.data.available_courier_companies?.map(
                           (c) => c.rate
                        )
                     )
                  );
               })
               .catch((err) => {
                  toast.error(
                     err?.response?.data?.message || 'Something Went Wrong'
                  );
               });
         });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [pincode]);

   const { discount, grandTotal } = useDiscountCalculator(
      price,
      quantity,
      couponInfo
   );

   const couponRemoveHandler = () => {
      setCouponInfo({ less: 0, title: '', expiresAt: '', valid: false });
   };

   if (productLoading) {
      return <div>Loading...</div>;
   }

  

   return (
      <Box sx={{ mb: 4, background: '#f3f3f3', pb: 3, minHeight: '90vh' }}>
         <PageTitle title={productSlug} />
         <Container maxWidth='xl'>
            <Box>
               <>
                  <Grid
                     container
                     spacing={5}
                     sx={{
                        mt: {
                           xs: '0',
                           sm: '0',
                        },
                     }}
                  >
                     <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Box sx={{ mb: 3, maxWidth: 500, mx: 'auto' }}>
                           <SliderWIthThumbnail
                              images={
                                 productDetails?.images?.length > 0
                                    ? productDetails?.images.map(
                                         (img) => img.image_url
                                      )
                                    : noImages
                              }
                              autoplay={true}
                           />
                        </Box>
                     </Grid>

                     <Grid item xs={12} sm={12} md={6} lg={6}>
                        {productDetails && (
                           <EnquiryProductDetails
                              productDetails={productDetails}
                              checkout={true}
                           />
                        )}
                     </Grid>
                  </Grid>
                  <Box
                     sx={{
                        display: 'flex',
                        justifyContent: 'center',
                     }}
                  >
                     <RoundedDarkButton
                        title='Buy This Product'
                        onClick={() => setIsOpen(true)}
                     />
                  </Box>
                  {/* ====== enquiry ====== box ====== */}

                  {/* video section */}
                  {productDetails?.video_url && (
                     <EmbedYTVideo
                        videoUrl={productDetails?.video_url}
                        sx={{ mt: 6 }}
                     />
                  )}
               </>
            </Box>

            <Flex sx={{ maxWidth: '900px', mx: 'auto', mt: 7 }}>
               <Typography variant='h6'>
                  To check more about this product visit
               </Typography>
               <GoToButton
                  url={`https://links.solruf.com/products/${productDetails?.product_id}/${productDetails?.product_slug}`}
               >
                  Check Details
               </GoToButton>
            </Flex>
         </Container>
         {/* bottom fixed button */}
         {scrollPosition > 900 && (
            <PrimaryButton
               onClick={() => {
                  // scroll to top y position
                  window.scrollTo(0, 500);
               }}
               sx={{
                  width: '100%',
                  position: 'fixed',
                  bottom: 0,
                  left: 0,
                  zIndex: 1,
                  display: {
                     xs: 'block',
                     sm: 'none',
                  },
               }}
            >
               Buy This Product
            </PrimaryButton>
         )}

         <SolrufModal modal open={isOpen} onClose={toggleDrawer}>
            <Box>
               <Container>
                  <Box>
                     <FormWrapper
                        component='form'
                        onSubmit={handleSubmit(submitHandler)}
                     >
                        <Typography
                           sx={{ textAlign: 'center' }}
                           variant='h4'
                           component='h1'
                        >
                           Enter Order Details
                        </Typography>

                        <SolrufTextField
                           size='small'
                           sx={{ mr: 2 }}
                           type='text'
                           label='Name'
                           {...register('name', {
                              required: {
                                 value: true,
                                 message: 'Name is required',
                              },
                           })}
                           error={errors.name}
                           helperText={errors.name && errors.name.message}
                        />

                        <SolrufTextField
                           size='small'
                           type='number'
                           label='Mobile Number'
                           {...register('phone', {
                              required: {
                                 value: true,
                                 message: 'Please input a number to continue',
                              },
                           })}
                           error={errors.phone}
                           helperText={errors.phone && errors.phone.message}
                        />
                        <SolrufTextField
                           size='small'
                           type='number'
                           label='Pincode'
                           {...register('pincode', {
                              required: {
                                 value: true,
                                 message: 'Pin code is required',
                              },
                           })}
                           error={errors.pincode}
                           helperText={errors.pincode && errors.pincode.message}
                        />

                        <SolrufTextField
                           size='small'
                           type='text'
                           label='Street'
                           {...register('street', {
                              required: {
                                 value: true,
                                 message: 'Street is required',
                              },
                              minLength: {
                                 value: 10,
                                 message:
                                    'Street should be at least 10 characters long',
                              },
                           })}
                           error={errors.street}
                           helperText={errors.street && errors.street.message}
                        />

                        <SolrufTextField
                           size='small'
                           label='Area'
                           {...register('area', {
                              required: {
                                 value: true,
                                 message: 'Ares is required',
                              },
                           })}
                           error={errors.area}
                           helperText={errors.area && errors.area.message}
                        />
                        <SolrufTextField
                           size='small'
                           label='Address'
                           {...register('address', {
                              required: {
                                 value: true,
                                 message: 'Address is required',
                              },
                           })}
                           error={errors.address}
                           helperText={errors.address && errors.address.message}
                        />

                        <Flex>
                           <QuantityController
                              quantity={quantity}
                              setQuantity={setQuantity}
                              quantityError={quantityError}
                              setQuantityError={setQuantityError}
                           />
                        </Flex>

                        {!couponInfo?.valid && (
                           <Coupon
                              sx={{ padding: 0 }}
                              couponInfo={couponInfo}
                              setCouponInfo={setCouponInfo}
                           />
                        )}

                        {couponInfo.valid && (
                           <CouponAppliedCard
                              sx={{ padding: 0 }}
                              couponInfo={couponInfo}
                              couponRemoveHandler={couponRemoveHandler}
                           />
                        )}

                        <Box
                           sx={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'flex-end',
                           }}
                        >
                           <Box
                              component='table'
                              sx={{
                                 width: '100%',
                                 fontSize: '1rem',
                                 '& tr': {
                                    width: '100%',
                                 },
                                 '& th': {
                                    width: '50%',
                                    minWidth: '10rem',
                                    textAlign: 'left',
                                 },
                                 '& td': {
                                    width: '50%',
                                    textAlign: 'right',
                                 },
                              }}
                           >
                              <tr>
                                 <th>Freight Rate:</th>
                                 <td>₹ {minFreightCharge}</td>
                              </tr>
                              <tr>
                                 <th>Discount:</th>
                                 <td>₹ -{discount}</td>
                              </tr>
                              <tr>
                                 <th>Total:</th>
                                 <td>₹ {grandTotal + minFreightCharge}</td>
                              </tr>
                           </Box>
                        </Box>

                        <PrimaryButton type='submit' disabled={isSubmitting}>
                           Submit
                        </PrimaryButton>
                     </FormWrapper>
                     {/* form end */}
                  </Box>
               </Container>
            </Box>
         </SolrufModal>
      </Box>
   );
};

export default CheckoutProductPage;