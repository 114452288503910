import { Box, Container, Typography } from '@mui/material';
import PrimaryButton from '../../components/Custom/PrimaryButton/PrimaryButton';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { EnquiryMSItem } from '../../components/HomeLeadForm/HomeLeadForm.style';
import siSvg from '../../assets/solar-installation.svg';
import smSvg from '../../assets/solar-maintenance.svg';
import spSvg from '../../assets/solar-products.svg';
import otherSvg from '../../assets/other.svg';


const WhatsappEnquiryPage = () => {
   return (
      <Container>
         <Box
            sx={{
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               minHeight: '400px',
               py: 2
            }}
         >
            <Box
               sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 3,
               }}
            >
               <Typography variant='h4'>
                  Connect with us on Whatsapp
               </Typography>

               <PrimaryButton
                  sx={{
                     py: 1.5,
                     borderRadius: '10px',
                     maxWidth: '20rem',
                     fontSize: '1.2rem',
                     textTransform: 'capitalize',
                     width: '200px',
                     '&:hover': {
                        color: '#000000',
                     },
                  }}
                  IconEnd={WhatsAppIcon}
                  component='a'
                  href='https://api.whatsapp.com/send?phone=+918169737450&text=Hello%20there%21%20I%20would%20like%20to%20know%20more%20about%20SOLRUF%20or%20would%20like%20to%20purchase%20a%20solar%20product.%20Can%20you%20help%20us%20with%20this%3F'
               >
                  WhatsApp
               </PrimaryButton>
             
               <Box
                  sx={{
                     display: 'flex',
                     alignItems: 'center',
                     width: '100%',
                     justifyContent: 'space-evenly',
                     rowGap: '1.5rem',
                     columnGap: '1.5rem',
                     '@media (max-width: 600px)': {
                        rowGap: '0.5rem',
                     },

                     flexWrap: 'wrap',
                  }}
               >
                  <EnquiryMSItem
                     // component={motion.div}
                     whileHover={{
                        scale: 0.9,
                     }}
                     sx={{
                        border: '1px solid #D8D8D8',
                        '-webkit-backdrop-filter': 'blur(10px)',
                        'backdrop-filter': 'blur(10px)',
                     }}
                  >
                     <Box
                        sx={{
                           position: 'absolute',
                           width: '100%',
                           height: '100%',
                           top: '0',
                           left: '0',
                           zIndex: '10',
                           cursor: 'pointer',
                        }}
                        // onClick={() => {
                        //    setService('solar installation');
                        //    setPageIdx(2);
                        // }}
                     />
                     <img
                        src={siSvg}
                        alt=''
                        style={{
                           height: '5rem',
                        }}
                     />
                     <Typography
                        sx={{
                           textAlign: 'center',
                           fontWeight: '600',
                           fontSize: '0.9rem',
                        }}
                     >
                        SOLAR INSTALLATION
                     </Typography>
                  </EnquiryMSItem>
                  <EnquiryMSItem
                     // component={motion.div}
                     whileHover={{
                        scale: 0.9,
                     }}
                     sx={{
                        border: '1px solid #D8D8D8',
                        background: 'transparent',
                        '-webkit-backdrop-filter': 'blur(10px)',
                        'backdrop-filter': 'blur(10px)',
                     }}
                  >
                     <Box
                        sx={{
                           position: 'absolute',
                           width: '100%',
                           height: '100%',
                           top: '0',
                           left: '0',
                           zIndex: '10',
                           cursor: 'pointer',
                        }}
                        // onClick={() => {
                        //    setService('solar maintenance');
                        //    setPageIdx(2);
                        // }}
                     />
                     <img
                        src={smSvg}
                        alt=''
                        style={{
                           height: '5rem',
                        }}
                     />
                     <Typography
                        sx={{
                           textAlign: 'center',
                           fontWeight: '600',
                           fontSize: '0.9rem',
                        }}
                     >
                        SOLAR MAINTENANCE
                     </Typography>
                  </EnquiryMSItem>
                  <EnquiryMSItem
                     // component={motion.div}
                     whileHover={{
                        scale: 0.9,
                     }}
                     sx={{
                        border: '1px solid #D8D8D8',
                        background: 'transparent',
                        '-webkit-backdrop-filter': 'blur(10px)',
                        'backdrop-filter': 'blur(10px)',
                     }}
                  >
                     <Box
                        sx={{
                           position: 'absolute',
                           width: '100%',
                           height: '100%',
                           top: '0',
                           left: '0',
                           zIndex: '10',
                           cursor: 'pointer',
                        }}
                        // onClick={() => {
                        //    setService('solar products');
                        //    setPageIdx(2);
                        // }}
                     />
                     <img
                        src={spSvg}
                        alt=''
                        style={{
                           height: '5rem',
                        }}
                     />
                     <Typography
                        sx={{
                           textAlign: 'center',
                           fontWeight: '600',
                           fontSize: '0.9rem',
                        }}
                     >
                        SOLAR PRODUCTS
                     </Typography>
                  </EnquiryMSItem>
                  <EnquiryMSItem
                     // component={motion.div}
                     whileHover={{
                        scale: 0.9,
                     }}
                     sx={{
                        border: '1px solid #D8D8D8',
                        background: 'transparent',
                        '-webkit-backdrop-filter': 'blur(10px)',
                        'backdrop-filter': 'blur(10px)',
                     }}
                  >
                     <Box
                        sx={{
                           position: 'absolute',
                           width: '100%',
                           height: '100%',
                           top: '0',
                           left: '0',
                           zIndex: '10',
                           cursor: 'pointer',
                        }}
                        // onClick={() => {
                        //    setService('solar products');
                        //    setPageIdx(2);
                        // }}
                     />
                     <img
                        src={spSvg}
                        alt=''
                        style={{
                           height: '5rem',
                        }}
                     />
                     <Typography
                        sx={{
                           textAlign: 'center',
                           fontWeight: '600',
                           fontSize: '0.9rem',
                        }}
                     >
                        SOLAR PUMPS
                     </Typography>
                  </EnquiryMSItem>
                  <EnquiryMSItem
                     // component={motion.div}
                     whileHover={{
                        scale: 0.9,
                     }}
                     sx={{
                        border: '1px solid #D8D8D8',
                        background: 'transparent',
                        '-webkit-backdrop-filter': 'blur(10px)',
                        'backdrop-filter': 'blur(10px)',
                     }}
                  >
                     <Box
                        sx={{
                           position: 'absolute',
                           width: '100%',
                           height: '100%',
                           top: '0',
                           left: '0',
                           cursor: 'pointer',
                           zIndex: '10',
                        }}
                        // component={HashLink}
                        to='#offer-form'
                        // onClick={() => {
                        //    rest.onClose();
                        // }}
                     />
                     <img
                        src={otherSvg}
                        alt=''
                        style={{
                           height: '5rem',
                        }}
                     />
                     <Typography
                        sx={{
                           textAlign: 'center',
                           fontWeight: '600',
                           fontSize: '0.9rem',
                        }}
                        component='h2'
                     >
                        Book a Free Solar Site Visit
                     </Typography>
                  </EnquiryMSItem>
               </Box>
           
            </Box>
         </Box>
      </Container>
   );
};

export default WhatsappEnquiryPage;
