import { Box, Divider, Typography, styled } from '@mui/material'
import React from 'react'
import { Flex } from '../../../components/CustomerDetailsDrawer/customerDetailsDrawer.style'
import FeatureDetail from '../../../components/FeatureDetail/FeatureDetail'
import RoundedDarkButton from '../../../components/RoundedDarkButton/RoundedDarkButton'
import BackToButton from '../../../components/BackToButton/BackToButton'
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PaymentsIcon from '@mui/icons-material/Payments';
import DescriptionIcon from '@mui/icons-material/Description';


const Wrapper = styled(Box)(({ theme }) => ({
    padding: '1rem',
 
    background: '#FFFFFF',
    borderRadius: '10px',
 }));

 const Nav = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '2rem',
 }));
 

const BidDetails = ({vendorData, quantity, enquiryId, onClose }) => {
  return (
    <Wrapper>
    <Nav>
       <BackToButton onClick={onClose} >Back To Bids</BackToButton>
       
    </Nav>
    <Flex sx={{ mb: 1, alignItems: 'center', justifyContent: 'space-between' }}>
       <Typography variant='h5'>{vendorData?.portfolio?.name}</Typography>
       <a
          href={`https://solruf.com/portfolio/${vendorData?.portfolio?.slug}`}
          alt='go to portfolio'
          target='_blank'
          rel='noreferrer'
       >
          <RoundedDarkButton title='Check Detail Portfolio' />
       </a>
    </Flex>

    <Typography variant='h6'>Bidding Details</Typography>
    <Divider />

    <Flex
       sx={{
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          mt: 3,
       }}
    >
       <Box sx={{ mr: 2, minWidth: '50%' }}>
          <FeatureDetail
             title='Bidding Price'
             value={vendorData?.bid?.price + ' ₹'}
             icon={<LocalAtmIcon />}
          />
          <FeatureDetail
             valueStyle={{
                maxWidth: '500px',
             }}
             title='Description'
             value={vendorData?.bid?.description}
             icon={<DescriptionIcon />}
          />
          <FeatureDetail
             valueStyle={{
                maxWidth: '500px',
             }}
             title='Total Amount'
             value={vendorData?.bid?.price * quantity + ' ₹'}
             icon={<DescriptionIcon />}
          />
       </Box>
       <Box>
          <FeatureDetail
             title='Advanced Payment'
             value={vendorData?.bid?.advance_payment + ' %'}
             icon={<PaymentsIcon />}
          />

          <FeatureDetail
             title='Warranty Years'
             value={vendorData?.bid?.warranty_years + ' Years'}
             icon={<CalendarTodayIcon />}
          />
          <FeatureDetail
             title='Booking Price'
             value={
                (vendorData?.bid?.price *
                   quantity *
                   vendorData?.bid?.advance_payment) /
                   100 +
                ' ₹'
             }
             icon={<CalendarTodayIcon />}
          />
       </Box>
    </Flex>

    {/* <Flex
       sx={{ my: 3, flexWrap: 'wrap', justifyContent: 'start', rowGap: 1 }}
    >
       {bidDocuments?.map(({ name, url }, index) => (
          <DownloadChip
             key={name}
             label={name}
             sx={{ mr: 1 }}
             onClick={() => {}}
             component='a'
             href={url}
             target='_blank'
          />
       ))}
    </Flex> */}

    {/* ================== query section ================== */}
    <Typography variant='h6'>Queries</Typography>
    <Divider sx={{ mb: 3 }} />

    {/* <PrimaryButton
       sx={{ mb: '1rem', px: 2 }}
       onClick={handleQueryToggle}
       IconStart={AddIcon}
    >
       Query
    </PrimaryButton> */}

  

    {/* {queries?.map((query, index) => (
       <CustomAccordionForDrawer
          title={`${index + 1}. ${query.question}`}
          // iconButton='delete'
          titleStyle={{ fontSize: '1rem' }}
          sx={{
             margin: '16px 0 16px 16px',
             px: 0,
             boxShadow: 0,
             '& .MuiAccordionSummary-root': {
                background: '#F3F3F3',
                borderBottom: '1px solid #D0D7D9',
                color: 'rgba(0,0,0,0.67) !important',
             },
             '& .MuiAccordionDetails-root': {
                padding: '0',
             },
          }}
       >
          <>
             <AccordionDetails
                sx={{
                   color: '#4D4D4D',
                   background: '#F3F3F3',
                   padding: '0',
                   borderRadius: '0 0 10px 10px',
                }}
             >
                <Box
                   sx={{
                      pt: 1,
                      px: 2,
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                   }}
                >
                   <Box component='span' sx={{
                      display: 'flex',
                      alignItems: 'center',
                      mr: 1,
                   }}>
                   
                      {query.created_at.substr(
                         query.created_at.length - 8
                      )}
                   </Box>
                   <span>{query.created_at.split(' ')[0]}</span>
                   <Button
                      variant='text'
                      sx={{
                         color: '#F20519',
                         textTransform: 'none',
                         fontSize: '1.1rem',
                      }}
                      onClick={() =>
                         deleteQueryHandler(
                            query.query_id,
                            query.conversation_id
                         )
                      }
                   >
                      Delete
                   </Button>
                </Box>
                {query.answer ? (
                   <AnswerBox>
                      <Answer>
                         <Typography variant='h6'>Ans:</Typography>
                         <Typography
                            variant='h6'
                            textAlign='left'
                            sx={{ ml: 1, fontWeight: 300 }}
                         >
                            {query.answer}
                         </Typography>
                      </Answer>
                   </AnswerBox>
                ) : (
                   <Box sx={{ py: 3 }}>
                      <NoAnswerBox>
                         <Typography variant='body1'>
                            No Answer Yet
                         </Typography>
                      </NoAnswerBox>
                   </Box>
                )}
             </AccordionDetails>
          </>
       </CustomAccordionForDrawer>
    ))} */}
 </Wrapper>
  )
}

export default BidDetails