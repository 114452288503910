import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  MenuItem,
  styled,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import YellowButton from "../../../components/YellowButton/YellowButton";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import AutoCompleteSelect from "../../../components/AutoCompleteSelect/AutoCompleteSelect";
import TabPanel from "../../../components/SolrufTabPanel/SolrufTabPanel";
import { AppTextInputButton } from "../../../components/AppTextInputButton";
import "./AddProduct.css";
import FileUploadWithProgress from "../../../components/FileUploadWithProgress/FileUploadWithProgress";
import SelectCheckBox from "../../../components/SelectCheckBox/SelectCheckBox";
import ListViewTable from "../../../components/ListViewTable/ListViewTable";
import {
  createProduct,
  getAttributes,
  getBrands,
  getCategories,
  getSubCategories,
  updateProduct,
} from "../helper";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import AddAttribute from "../AddAttribute/AddAttribute";
import { nanoid } from "nanoid";
import { useDropzone } from "react-dropzone";
import { PlusIcon } from "@heroicons/react/solid";
import UploadError from "../../MyPortfolio/UploadError";
import SingleFIleUploadWithProgress from "../../MyPortfolio/SingleFIleUploadWithProgress";
import {
  generateUrl,
  generatePrevUrl,
} from "../../../utils/urlGeneratorForProductAndWarrantyDoc";
import SolrufTextField from "../../../components/SolrufTextField/SolrufTextField";
import { axiAuth } from "../../../utils/axiosInstance";
import CloseIcon from "@mui/icons-material/Close";
import FilterModal from "./FilterModal";
import SolrufSwitch from "../../../components/Custom/SolrufSwitch/SolrufSwitch";
import AddBrand from "../AddBrand/AddBrand";
import TextFieldWithSearch from "../../../components/Custom/TextFieldWithSearch/TextFieldWithSearch";
import ProductDimension from "./ProductDimension";
import RichEditor from "../../../components/richEditor/RichEditor";

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
// >>>>>>>>>>>>>>>>>>>>>>>>>> CHANGES APPLIED >>>>>>>>>>>>>>>>>>>>>>>>
// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

// 'List View' >> Portfolio card
// 'Grid View' >> Procurement card
// 'Product Main Page' >> Product feature list
// 'Booking price view' >> Vendor editable purchase
// 'Price View' >> Vendor editable booking

export const ViewPointOptions = [
  "Portfolio card",
  "Procurement card",
  "Vendor editable purchase",
  "Vendor editable booking",
  "Product feature list",
  "Catalogue",
];

const DocumentUploadBox = styled("div")(({ theme }) => {
  return {
    width: "100%",
    padding: "1rem",
    background: theme.palette.secondary.light,
    borderRadius: "5px",
  };
});

const CertificateNameBox = styled("div")(({ theme }) => {
  return {
    width: "100%",
    margin: "0rem auto",
    border: "3px solid #FFD05B",
    borderRadius: "5px",
    outline: "none",
    fontFamily: theme.typography.fontFamily,
    height: "39px",
    overflow: "hidden",
    display: "flex",
    "& input": {
      border: "none",
      width: "80%",
      height: "100%",
      padding: "1rem",
      "&:focus": {
        outline: "none",
      },
    },
    "& input[type=file]": {
      display: "none",
    },
  };
});

export const SolrufTextFieldGray = styled(SolrufTextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {},
}));

//Dropdown and Input Box
const FieldUnitBox = styled("div")(({ theme }) => {
  return {
    width: "100%",
    margin: "0 auto",
    border: "2px solid #FFD05B",
    borderRadius: "5px",
    outline: "none",
    fontFamily: theme.typography.fontFamily,
    height: "39px",
    overflow: "hidden",
    "& input": {
      border: "none",
      width: "80%",
      height: "100%",
      padding: "1rem",
      background: "#FFFFFF",
      "&:focus": {
        outline: "none",
      },
    },
    "& select": {
      border: "none",
      outline: "outline",
      width: "20%",
      borderRight: "5px solid #FFD05B",
      height: "100%",
      textAlign: "center",
      background: "#ffd05b",
      "&:focus": {
        outline: "none",
      },
      "& option": {
        background: "#ffd05b",
      },
    },
  };
});

const AddProduct = () => {
  const dispatch = useDispatch();
  const createProductSelector = useSelector((state) => state.createProduct);

  const [units, setUnits] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState("");
  const [selectedUnitType, setSelectedUnitType] = useState("");

  const handleProductDescriptionChange = (content, editor) => {
    setProductDescription(content);
  };

  const handleWarrantyDescriptionChange = (content, editor) => {
    setWarrantyDescription(content);
  };

  useEffect(() => {
    axiAuth
      .get("api/admin/units")
      .then((res) => {
        setUnits(res.data.units);
        setSelectedUnit(res.data.units[0]);
      })
      .catch((err) => {});
  }, []);

  //selected values
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedAttribute, setSelectedAttribute] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [productImages, setProductImages] = useState([]);
  const [selectedViews, setSelectedViews] = useState([]);
  const [fieldUnit, setFieldUnit] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [sales_type, setSales_type] = useState("enquiry");

  const handleRadioButtonChange = (event) => {
    setSales_type(event.target.value);
  };

  // edit products states
  // const [editProduct, setEditProduct] = useState(null);

  //entered values
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [warrantyDescription, setWarrantyDescription] = useState("");
  const [productFields, setProductFields] = useState([]);
  const [editId, setEditId] = useState(null);

  const onClickEdit = (id) => {
    setOpenModal(false);
    let editField = productFields.find((field) => field.nanoid === id);
    setEditId(id);
    setSelectedAttribute({
      id: editField.attribute_id,
      name: editField.attribute_name,
      category_id: selectedCategory,
    });
    setFieldUnit(editField.value_unit);
    setSelectedViews(editField.fieldViewPoints);
    setSelectedUnit(editField.value_unit_type);
  };

  //Store get data from api
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [attributeList, setAttributeList] = useState([]);
  const [brands, setBrands] = useState([]);

  //Temporary store data
  const [productCreateClicked, setProductCreateClicked] = useState(false);

  const emptyAll = () => {
    setProductName("");
    setProductDescription("");
    setWarrantyDescription("");
    setProductImages([]);
    setProductFields([]);
    setSelectedBrand(null);
    setProductCreateClicked(false);
    setSelectedAttribute("");
    setFieldUnit("");
    setSelectedCategory(null);
    setSelectedSubCategory(null);
    setProductDocuments([]);
    setWarrantyDocuments([]);
  };

  const emptyAttributes = () => {
    setSelectedAttribute("");
    setFieldUnit("");
    setSelectedViews([]);
  };

  //State for tab
  const [mainTab, setMainTab] = useState(0);

  const handleMainTabChange = (event, newValue) => {
    setMainTab(newValue);
  };

  //Get categories from api
  useEffect(() => {
    getCategories().then((response) => {
      setCategories(response);
    });
  }, []);

  //Get subcategories from api
  useEffect(() => {
    if (selectedCategory) {
      getSubCategories(selectedCategory).then((response) => {
        setSubCategories(response);
      });
      getBrands(selectedCategory).then((response) => {
        setBrands(response);
      });
    }
  }, [selectedCategory]);

  //Get attributes from api
  useEffect(() => {
    if (selectedSubCategory) {
      getAttributes(selectedSubCategory).then((response) => {
        setAttributeList([
          {
            attribute_id: response.id,
            value: response.name,
            value_unit: selectedUnit,
            editable: true,
            views: {
              list: {
                level: 1,
                visibility: false,
              },
              grid: {
                level: 1,
                visibility: false,
              },
              main: {
                level: 1,
                visibility: false,
              },
            },
          },
        ]);
        setAttributeList(response);
      });
    }
  }, [selectedSubCategory, selectedUnit]);

  const [taxFields, setTaxFields] = useState({
    hsn_sac_code: "",
    tax_igst: "",
    tax_cgst: "",
    tax_sgst: "",
  });

  const [isActive, setIsActive] = useState(true);

  const handleIsActiveChange = () => {
    setIsActive(!isActive);
  };

  const onSubmitCreate = async (e) => {
    e.preventDefault();
    const warrantyDocs = generateUrl(warrantyDocuments);
    const productDocs = generateUrl(productDocuments);
    //
    if (
      selectedCategory === null ||
      selectedSubCategory === null ||
      productName === "" ||
      productDescription === "" ||
      productDocs.length === 0 ||
      warrantyDocs.length === 0 ||
      warrantyDescription === "" ||
      productImages.length === 0 ||
      productFields.length === 0 ||
      videoUrl.length === 0
    ) {
      toast.error("Please fill all the fields");
      if (productDocs.length === 0) {
        toast.error("Please upload product documents");
      }
      if (warrantyDocs.length === 0) {
        toast.error("Please upload warranty documents");
      }
    } else {
      if (productImages.length < 3 || productImages.length > 5) {
        toast.error("Please upload 3 to 5 images");
      } else {
        let images = [];
        //get url from array and store as string in the array
        images = productImages.map((image) => {
          return image.url;
        });

        const filterProductAttributes = [];

        productFields.forEach((item) => {
          let newData = {
            ...item,
            views: {
              portfolio_card: item.views.portfolioCard,
              procurement_card: item.views.procurementCard,
              vendor_editable_purchase: item.views.vendorEditablePurchase,
              vendor_editable_booking: item.views.vendorEditableBooking,
              product_feature_list: item.views.productFeatureList,
              catalogue: item.views.catalogue,
              field_view_points: item.fieldViewPoints,
            },
          };
          delete newData["fieldViewPoints"];
          delete newData["value"];
          delete newData["nanoid"];
          filterProductAttributes.push(newData);
        });

        const brandId = brands.find(
          (brand) => brand?.name === selectedBrand?.name
        )?.id;

        let isDimensionsFieldNotEmpty = true;

        for (const field in dimensions) {
          if (!dimensions[field].trim()) {
            isDimensionsFieldNotEmpty = false;
          }
        }

        if (!isDimensionsFieldNotEmpty) {
          toast.warn("Add all the dimension field data");
        }

        let data = {
          name: productName,
          category_id: selectedSubCategory,
          description: productDescription,
          attributes: filterProductAttributes,
          product_description: productDescription,
          warranty_description: warrantyDescription,
          documents: [...warrantyDocs, ...productDocs],
          images: images.filter(Boolean),
          brand_id: brandId,
          ...taxFields,
          active: isActive,
          video_url: videoUrl,
          sales_type,
          unit_type: selectedUnitType,
          ...dimensions,
        };

        createProduct(data, dispatch).then((response) => {
          if (response && (response.message = "Product created")) {
            toast.success("Product created successfully");
            emptyAll();
          } else {
            toast.error("Product creation failed");
          }
        });
      }
    }
  };

  // Initialize state with the default values
  const [dimensions, setDimensions] = useState({
    height: "10", // Default height value
    weight: "2", // Default width value
    length: "20", // Default length value
    breadth: "10", // Default breadth value
  });

  // Handle changes in the input fields
  const handleDimensionChange = (event) => {
    const { name, value } = event.target;

    // Update the state with the new value for the corresponding field
    setDimensions((prevDimensions) => ({
      ...prevDimensions,
      [name]: value,
    }));
  };

  const onSubmitUpdate = async (e) => {
    e.preventDefault();

    if (
      selectedCategory === null ||
      selectedSubCategory === null ||
      productName === "" ||
      productDescription === "" ||
      warrantyDescription === "" ||
      productFields.length === 0
    ) {
      toast.error("Please fill all the fields");
    } else {
      const warrantyDocs = generateUrl(warrantyDocuments).concat(
        generatePrevUrl(prevWarrantyDocuments)
      );
      const productDocs = generateUrl(productDocuments).concat(
        generatePrevUrl(prevProductDocuments)
      );
      let images = productImages.map((image) => {
        return image.url;
      });
      prevProductImages.forEach((ele) => {
        images.push(ele.image_url);
      });

      if (productDocs.length === 0) {
        toast.error("Please upload product documents");
      }
      if (warrantyDocs.length === 0) {
        toast.error("Please upload warranty documents");
      }
      if (images.length < 3 || images.length > 5) {
        toast.error("Please upload 3 to 5 images");
        return;
      }

      let isDimensionsFieldNotEmpty = true;

      for (const field in dimensions) {
        if (!dimensions[field].trim()) {
          isDimensionsFieldNotEmpty = false;
        }
      }

      if (!isDimensionsFieldNotEmpty) {
        toast.warn("Add all the dimension field data");
      }

      const filterProductAttributes = [];
      productFields.forEach((item) => {
        let newData = {
          ...item,
          views: {
            portfolio_card: item.views.portfolioCard,
            procurement_card: item.views.procurementCard,
            vendor_editable_purchase: item.views.vendorEditablePurchase,
            vendor_editable_booking: item.views.vendorEditableBooking,
            product_feature_list: item.views.productFeatureList,
            field_view_points: item.fieldViewPoints,
            catalogue: item.views.catalogue,
          },
        };
        delete newData["fieldViewPoints"];
        delete newData["attribute_name"];
        delete newData["nanoid"];
        filterProductAttributes.push(newData);
      });

      const brandId = brands.find(
        (brand) => brand?.name === selectedBrand?.name
      )?.id;

      let data = {
        name: productName,
        category_id: selectedSubCategory,
        description: productDescription,
        attributes: filterProductAttributes,
        product_description: productDescription,
        warranty_description: warrantyDescription,
        documents: [...warrantyDocs, ...productDocs],
        images: images,
        brand_id: brandId,
        active: isActive,
        ...taxFields,
        dimensions,
      };

      updateProduct(data, editProductId, dispatch).then((response) => {
        if (response && (response.message = "Product created")) {
          toast.success("Product updated successfully");
        } else {
          toast.error("Product update failed");
        }
      });
    }
  };

  const [productDocuments, setProductDocuments] = useState([]);
  const [documentNameError, setDocumentNameError] = useState("");
  const productNameRef = useRef(null);

  const onDropProductDocument = useCallback((acceptedFiles, rejectedFiles) => {
    if (productNameRef.current.value.trim().length === 0) {
      productNameRef.current.focus();
      setDocumentNameError("Please enter Certificate name");
      return;
    }
    const mappedAcceptedFiles = acceptedFiles.map((file) => {
      setDocumentNameError("");
      file.givenName = productNameRef.current.value;
      return {
        file,
        error: [],
      };
    });
    setProductDocuments((cur) => [
      ...cur,
      ...mappedAcceptedFiles,
      ...rejectedFiles,
    ]);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropProductDocument,
    maxSize: 5000000,
  });

  const onProductDocumentUpload = (url, file) => {
    setProductDocuments((cur) =>
      cur.map((fw) => {
        if (fw.file === file) {
          return { ...fw, url, type: "product" };
        }
        return fw;
      })
    );
  };

  const productDocumentDeleteHandler = (file) => {
    setProductDocuments((cur) => cur.filter((fw) => fw.file !== file));
  };

  const [warrantyDocuments, setWarrantyDocuments] = useState([]);
  const [warrantyDocumentNameError, setWarrantyDocumentNameError] =
    useState("");
  const warrantyDocumentNameRef = useRef(null);

  const onDropWarrantyDocument = useCallback((acceptedFiles, rejectedFiles) => {
    if (warrantyDocumentNameRef.current.value.trim().length === 0) {
      warrantyDocumentNameRef.current.focus();
      setWarrantyDocumentNameError("Please enter Document name");
      return;
    }
    const mappedAcceptedFiles = acceptedFiles.map((file) => {
      setWarrantyDocumentNameError("");
      file.givenName = warrantyDocumentNameRef.current.value;
      return {
        file,
        // type: 'warranty',
        error: [],
      };
    });
    setWarrantyDocuments((cur) => [
      ...cur,
      ...mappedAcceptedFiles,
      ...rejectedFiles,
    ]);
  }, []);

  const {
    getRootProps: getRootPropsForWarrantee,
    getInputProps: getInputPropsForWarrantee,
  } = useDropzone({
    onDrop: onDropWarrantyDocument,
    maxSize: 5000000,
  });

  const onWarrantyDocumentUpload = (url, file) => {
    setWarrantyDocuments((cur) =>
      cur.map((fw) => {
        if (fw.file === file) {
          return { ...fw, url, type: "warranty" };
        }
        return fw;
      })
    );
  };

  const warrantyDocumentDeleteHandler = (file) => {
    setWarrantyDocuments((cur) => cur.filter((fw) => fw.file !== file));
  };

  const editProductId = useSelector(
    (state) => state.editProductAdmin?.productToBeEdited?.product_id
  );
  //

  const [prevProductImages, setPrevProductImages] = useState([]);
  const [prevProductDocuments, setPrevProductDocuments] = useState([]);
  const [prevWarrantyDocuments, setPrevWarrantyDocuments] = useState([]);

  //

  const prevDocDeleteHandler = (fileId, reqType) => {
    if (fileId) {
      axiAuth
        .delete(`/api/document/${fileId}`)
        .then((res) => {
          if (reqType === "prevProductDocuments") {
            let upId = prevProductDocuments.filter((ele) => {
              return fileId !== ele.doc_id;
            });
            setPrevProductDocuments(upId);
          } else if (reqType === "prevWarrantyDocuments") {
            let upId = prevWarrantyDocuments.filter((ele) => {
              return fileId !== ele.doc_id;
            });
            setPrevWarrantyDocuments(upId);
          }
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    if (editProductId) {
      setTimeout(() => {
        axiAuth
          .get(`api/admin/products/${editProductId}`)
          .then((res) => {
            // setEditProduct(res.data?.product);

            setSelectedCategory(res.data?.product?.category?.sub_category_id);
            setProductName(res.data?.product.product_name);
            setSelectedBrand(res.data?.product.product_brand);
            setProductDescription(res.data?.product.product_description);
            setWarrantyDescription(
              res.data.product.product_warranty_description
            );
            setPrevProductImages(res.data.product.images);
            setPrevWarrantyDocuments(
              res.data.product.documents.filter(
                (doc) => doc.doc_type === "warranty"
              )
            );
            setPrevProductDocuments(
              res.data.product.documents.filter(
                (doc) => doc.doc_type === "product"
              )
            );

            setSelectedCategory(res.data.product.main_category.id);
            setSelectedSubCategory(
              res.data.product.sub_category.sub_category_id
            );

            setIsActive(res.data?.product?.active);
            setTaxFields({
              tax_cgst: res.data?.product?.tax_cgst,
              tax_sgst: res.data?.product?.tax_sgst,
              tax_igst: res.data?.product?.tax_igst,
              hsn_sac_code: res.data?.product?.hsn_sac_code,
            });

            const finalAttribute = [];
            let duplicate = [];
            res.data?.product?.attributes?.forEach((item) => {
              if (!duplicate.includes(item.name)) {
                item.attribute_values.forEach((ele) => {
                  let tempVar = {
                    attribute_id: item.id,
                    attribute_name: item.name,
                    nanoid: ele.id.toString(),
                    fieldViewPoints: ele.views.field_view_points,
                    value_unit: ele.value,
                    value_unit_type: ele.value_unit,
                    attribute_filterable: item.filterable,
                    attribute_filter_type: item.filter_type,
                    editable: ele.editable === 1 ? true : false,
                    views: {
                      portfolioCard: ele.views.portfolio_card,
                      procurementCard: ele.views.procurement_card,
                      vendorEditablePurchase:
                        ele.views.vendor_editable_purchase,
                      vendorEditableBooking: ele.views.vendor_editable_booking,
                      productFeatureList: ele.views.product_feature_list,
                      catalogue: ele.views.catalogue,
                    },
                  };
                  finalAttribute.push(tempVar);
                });
                duplicate.push(item.attribute_name);
              }
            });

            setProductFields(finalAttribute);
          })
          .catch((err) => {});
      }, 1000);
    }
  }, [editProductId]);

  return (
    <Container maxWidth="xl">
      <TabPanel
        handleTabChange={handleMainTabChange}
        activeTab={mainTab}
        tabs={["Add Product", "Add Attribute", "Add Brand"]}
        sx={{
          "& .MuiButtonBase-root": {
            fontSize: ["1rem", "1.5rem", "1.6rem"],
          },
        }}
      />
      {mainTab === 0 && (
        <div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              my: 4,
            }}
          >
            <Typography
              variant="h5"
              gutterBottom
              style={{
                fontWeight: "bold",
                fontSize: "1.4rem",
              }}
              className="mt-2"
            >
              Add Product
            </Typography>
            {editProductId ? (
              <YellowButton
                variant="contained"
                color="primary"
                style={{
                  padding: "0.6rem 2.8rem",
                }}
                onClick={onSubmitUpdate}
              >
                Update Product
              </YellowButton>
            ) : (
              <YellowButton
                variant="contained"
                color="primary"
                style={{
                  padding: "0.6rem 2.8rem",
                }}
                onClick={onSubmitCreate}
              >
                Save Product
              </YellowButton>
            )}
          </Box>
          {!productCreateClicked && (
            <Box
              sx={{
                display: "flex",
                my: 3,
              }}
            >
              <SolrufTextFieldGray
                select
                size="small"
                label="Select Category"
                value={selectedCategory || ""}
                onChange={(e) => setSelectedCategory(e.target.value)}
                sx={{ mr: 2 }}
              >
                {categories?.map(({ category_id, name }, id) => (
                  <MenuItem key={id} value={category_id}>
                    {name}
                  </MenuItem>
                ))}
              </SolrufTextFieldGray>

              <SolrufTextFieldGray
                select
                size="small"
                label="Select Sub Category"
                value={selectedSubCategory || ""}
                onChange={(e) => setSelectedSubCategory(e.target.value)}
              >
                {subCategories?.map(({ category_id, name }, id) => (
                  <MenuItem key={id} value={category_id}>
                    {name}
                  </MenuItem>
                ))}
              </SolrufTextFieldGray>
            </Box>
          )}
          <div>
            {!productCreateClicked && (
              <>
                <Typography variant="h6" gutterBottom className="mt-4">
                  Product Name
                </Typography>
                <AppTextInputButton
                  isButtonPresent={true}
                  placeholder="Search Products Example:- 550W Solar Panel, Solar Cooker..."
                  value={productName}
                  onChange={(e) => {
                    setProductName(e.target.value);
                  }}
                  onClick={() => {
                    if (productName) {
                      setProductCreateClicked(true);
                    }
                  }}
                  buttonDisabled={!productName}
                />
              </>
            )}
            {productCreateClicked && (
              <Box
                className="upload-background"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 3,
                  width: "100%",
                }}
              >
                <Typography sx={{ mt: 3 }} component="p">
                  {productName}
                </Typography>
                <Button
                  style={{
                    color: "#000",
                  }}
                  onClick={() => {
                    setProductCreateClicked(!productCreateClicked);
                  }}
                >
                  <EditIcon />
                </Button>
              </Box>
            )}

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mt: 3,
                ml: 2,
                columnGap: 2,
              }}
            >
              <Typography>Is Active ?</Typography>
              <SolrufSwitch
                sx={{ py: 0.5 }}
                checked={isActive}
                onChange={handleIsActiveChange}
              />
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">Type</Typography>
              <Box>
                <RadioGroup
                  value={sales_type}
                  onChange={handleRadioButtonChange}
                >
                  <FormControlLabel
                    value="purchase"
                    control={<Radio />}
                    label="Purchase"
                  />
                  <FormControlLabel
                    value="enquiry"
                    control={<Radio />}
                    label="Enquiry"
                  />
                </RadioGroup>
              </Box>
            </Box>
            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">Unit Type</Typography>
              <Box
                sx={{
                  my: 3,
                }}
              >
                <SolrufTextFieldGray
                  select
                  size="small"
                  label="Select Unit Type"
                  value={selectedUnitType}
                  onChange={(e) => setSelectedUnitType(e.target.value)}
                >
                  {units.map((unit) => (
                    <MenuItem value={unit}>{unit}</MenuItem>
                  ))}
                </SolrufTextFieldGray>
              </Box>
            </Box>
            <Box
              sx={{
                my: 3,
              }}
            >
              <Typography variant="h6">Brand</Typography>

              <TextFieldWithSearch
                data={brands.map((brand) => ({ name: brand.name }))}
                value={selectedBrand}
                setValue={setSelectedBrand}
                label={
                  brands.length === 0
                    ? "Select category and sub category"
                    : "Select a Brand"
                }
                sx={{
                  maxWidth: "300px",
                }}
              />
            </Box>

            {/*  height, width, length and breadth section start */}
            <div>
              <ProductDimension
                dimensions={dimensions}
                onInputChange={handleDimensionChange}
              />
            </div>

            {/*  height, width, length and breadth section end */}

            <Box>
              {/* <AppTextInputButton
                textArea={true}
                placeholder="Product Description"
                value={productDescription}
                onChange={(e) => {
                  setProductDescription(e.target.value);
                }}
              /> */}
              <RichEditor
                placeholder="Product Description"
                value={productDescription}
                handleChange={handleProductDescriptionChange}
              />
            </Box>
          </div>

          {/* //? document upload box for product // */}

          <DocumentUploadBox>
            <Typography variant="h6" gutterBottom sx={{ color: "#000000" }}>
              Add Product Documents
            </Typography>
            <CertificateNameBox>
              <input
                type="text"
                placeholder="Document Name"
                ref={productNameRef}
              />

              <label
                htmlFor="serviceFile"
                style={{
                  width: "20%",
                  height: "100%",
                  background: "#ffd05b",
                }}
                {...getRootProps()}
              >
                <input {...getInputProps()} multiple />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <PlusIcon style={{ width: 25 }} />{" "}
                  <Typography variant="body1" sx={{ ml: 2, color: "#000000" }}>
                    Add
                  </Typography>
                </Box>
              </label>
            </CertificateNameBox>
            {documentNameError && (
              <Typography style={{ color: "red" }}>
                {documentNameError}
              </Typography>
            )}

            {/* ================================================ */}

            {productDocuments.map((fileWrapper, i) => {
              return fileWrapper?.errors?.length ? (
                <UploadError
                  file={fileWrapper.file}
                  errors={fileWrapper.errors}
                  onDelete={productDocumentDeleteHandler}
                />
              ) : (
                <SingleFIleUploadWithProgress
                  key={i}
                  file={fileWrapper.file}
                  onDelete={productDocumentDeleteHandler}
                  onFileUpload={onProductDocumentUpload}
                />
              );
            })}

            {prevProductDocuments &&
              prevProductDocuments.map((doc) => (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 1,
                    borderBottom: "1px solid gray",
                  }}
                >
                  <Typography>{doc?.doc_name}</Typography>
                  <Button
                    endIcon={<CloseIcon />}
                    color="secondary"
                    onClick={() =>
                      prevDocDeleteHandler(doc?.doc_id, "prevProductDocuments")
                    }
                  ></Button>
                </Box>
              ))}
          </DocumentUploadBox>

          {/* //? document upload box for product end// */}
          <div>
            {/* <AppTextInputButton
              textArea={true}
              placeholder="Warranty Description"
              className="mt-4"
              value={warrantyDescription}
              onChange={(e) => {
                setWarrantyDescription(e.target.value);
              }}
            /> */}
            <RichEditor
              placeholder="Warranty Description"
              value={warrantyDescription}
              handleChange={handleWarrantyDescriptionChange}
            />
          </div>
          {/* //? document upload box for warrantee // */}
          <DocumentUploadBox>
            <Typography variant="h6" gutterBottom sx={{ color: "#000000" }}>
              Add Warrantee Documents
            </Typography>
            <CertificateNameBox>
              <input
                type="text"
                placeholder="Document Name"
                ref={warrantyDocumentNameRef}
              />

              <label
                htmlFor="serviceFile"
                style={{
                  width: "20%",
                  height: "100%",
                  background: "#ffd05b",
                }}
                {...getRootPropsForWarrantee()}
              >
                <input {...getInputPropsForWarrantee()} multiple />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <PlusIcon style={{ width: 25 }} />{" "}
                  <Typography variant="body1" sx={{ ml: 2, color: "#000000" }}>
                    Add
                  </Typography>
                </Box>
              </label>
            </CertificateNameBox>
            {warrantyDocumentNameError && (
              <Typography style={{ color: "red" }}>
                {warrantyDocumentNameError}
              </Typography>
            )}

            {/* ================================================ */}

            {warrantyDocuments.map((fileWrapper, i) => {
              return fileWrapper?.errors?.length ? (
                <UploadError
                  file={fileWrapper.file}
                  errors={fileWrapper.errors}
                  onDelete={warrantyDocumentDeleteHandler}
                />
              ) : (
                <SingleFIleUploadWithProgress
                  key={i}
                  file={fileWrapper.file}
                  onDelete={warrantyDocumentDeleteHandler}
                  onFileUpload={onWarrantyDocumentUpload}
                />
              );
            })}
            {prevWarrantyDocuments &&
              prevWarrantyDocuments.map((doc) => (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 1,
                    borderBottom: "1px solid gray",
                  }}
                >
                  <Typography>{doc?.doc_name}</Typography>
                  <Button
                    endIcon={<CloseIcon />}
                    color="secondary"
                    onClick={() =>
                      prevDocDeleteHandler(doc?.doc_id, "prevWarrantyDocuments")
                    }
                  ></Button>
                </Box>
              ))}
          </DocumentUploadBox>
          {/* //? document upload box for warrantee end// */}

          <SolrufTextFieldGray
            sx={{ mt: 3 }}
            InputLabelProps={{ shrink: true }}
            size="small"
            label="Video Url"
            onChange={(e) => setVideoUrl(e.target.value)}
            value={videoUrl}
          />

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              my: 2,
              columnGap: 2,
            }}
          >
            <SolrufTextField
              label="Hsn"
              size="small"
              value={taxFields.hsn_sac_code}
              onChange={(e) => {
                setTaxFields({
                  ...taxFields,
                  hsn_sac_code: e.target.value,
                });
              }}
            />
            <SolrufTextField
              label="Igst"
              size="small"
              value={taxFields.tax_igst}
              onChange={(e) => {
                setTaxFields({
                  ...taxFields,
                  tax_igst: e.target.value,
                });
              }}
            />
            <SolrufTextField
              label="Cgst"
              size="small"
              value={taxFields.tax_cgst}
              onChange={(e) => {
                setTaxFields({
                  ...taxFields,
                  tax_cgst: e.target.value,
                });
              }}
            />
            <SolrufTextField
              label="Sgst"
              size="small"
              value={taxFields.tax_sgst}
              onChange={(e) => {
                setTaxFields({
                  ...taxFields,
                  tax_sgst: e.target.value,
                });
              }}
            />
          </Box>

          <div>
            <Typography variant="h6" gutterBottom className="mt-4 pl-3">
              Add Product Images
            </Typography>
            <FileUploadWithProgress
              fileType={["image/png", "image/jpeg"]}
              document={productImages}
              setDocument={setProductImages}
              name="Add Product Images (3-5MB)"
              prevImages={prevProductImages}
              setPrevProductImages={setPrevProductImages}
            />
          </div>
          <div>
            <Typography variant="h6" gutterBottom className="my-4 text-center">
              Product Fields
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  pt: 1,
                }}
              >
                <AutoCompleteSelect
                  style={{
                    marginRight: "1rem",
                    marginTop: "3px",
                    backgroundColor: "#ffffff",
                  }}
                  options={attributeList.filter((attr) => attr.active)}
                  value={selectedAttribute}
                  setValue={setSelectedAttribute}
                  label="Select Attribute"
                  disabled={selectedSubCategory !== null ? false : true}
                  notAdd={true}
                />
              </Box>
              <Box
                sx={{
                  flex: 1,
                  pt: 1,
                  mx: 1,
                }}
              >
                <FieldUnitBox>
                  <input
                    type="text"
                    placeholder="Field Unit"
                    name="field_unit"
                    value={fieldUnit}
                    onChange={(e) => {
                      setFieldUnit(e.target.value);
                    }}
                  />
                  <select
                    name="field_unit_type"
                    onChange={(event) => setSelectedUnit(event.target.value)}
                    value={selectedUnit}
                  >
                    {units.map((unit) => (
                      <option key={unit} value={unit}>
                        {unit}
                      </option>
                    ))}
                  </select>
                </FieldUnitBox>
              </Box>
              <Box
                sx={{
                  flex: 1,
                  pt: 1,
                }}
              >
                <SelectCheckBox
                  selected={selectedViews}
                  setSelected={setSelectedViews}
                  options={ViewPointOptions}
                />
              </Box>
              <div className="col-6 col-md-2">
                <YellowButton
                  variant="contained"
                  color="primary"
                  style={{
                    padding: "0.6rem 2.8rem",
                  }}
                  onClick={() => {
                    if (selectedAttribute === "" || fieldUnit === "") {
                      toast.error("Please fill all the attribute fields!");
                      return;
                    }
                    if (editId) {
                      setProductFields(
                        productFields.map((productField) => {
                          if (productField.nanoid === editId) {
                            //
                            return {
                              ...productField,
                              attribute_id: selectedAttribute.id,
                              attribute_name: selectedAttribute.name,
                              editable: true,
                              value_unit: fieldUnit,
                              value_unit_type: selectedUnit,
                              attribute_filterable: false,
                              attribute_filter_type: "select",
                              views: {
                                portfolioCard: {
                                  level: productField.views.portfolioCard.level,
                                  visibility:
                                    selectedViews.indexOf("Portfolio card") > -1
                                      ? true
                                      : false,
                                },
                                procurementCard: {
                                  level:
                                    productField.views.procurementCard.level,
                                  visibility:
                                    selectedViews.indexOf("Procurement card") >
                                    -1
                                      ? true
                                      : false,
                                },
                                vendorEditablePurchase: {
                                  level:
                                    productField.views.vendorEditablePurchase
                                      .level,
                                  visibility:
                                    selectedViews.indexOf(
                                      "Vendor editable purchase"
                                    ) > -1
                                      ? true
                                      : false,
                                },
                                vendorEditableBooking: {
                                  level:
                                    productField.views.vendorEditableBooking
                                      .level,
                                  visibility:
                                    selectedViews.indexOf(
                                      "Vendor editable booking"
                                    ) > -1
                                      ? true
                                      : false,
                                },
                                productFeatureList: {
                                  level:
                                    productField.views.productFeatureList.level,
                                  visibility:
                                    selectedViews.indexOf(
                                      "Product feature list"
                                    ) > -1,
                                },
                                catalogue: {
                                  level: productField.views.catalogue.level,
                                  visibility:
                                    selectedViews.indexOf("Catalogue") > -1,
                                },
                              },
                              fieldViewPoints: selectedViews,
                            };
                          }
                          return productField;
                        })
                      );
                    } else {
                      setProductFields([
                        ...productFields,
                        {
                          attribute_id: selectedAttribute?.id,
                          attribute_name: selectedAttribute.name,
                          editable: true,
                          value_unit: fieldUnit,
                          value_unit_type: selectedUnit,
                          attribute_filterable: false,
                          attribute_filter_type: null,
                          views: {
                            portfolioCard: {
                              level: productFields.filter(
                                (list) =>
                                  list.views.portfolioCard.visibility === true
                              ).length,
                              visibility:
                                selectedViews.indexOf("Portfolio card") > -1
                                  ? true
                                  : false,
                            },
                            procurementCard: {
                              level: productFields.filter(
                                (list) =>
                                  list.views.procurementCard.visibility === true
                              ).length,
                              visibility:
                                selectedViews.indexOf("Procurement card") > -1
                                  ? true
                                  : false,
                            },
                            vendorEditablePurchase: {
                              level: productFields.filter(
                                (list) =>
                                  list.views.vendorEditablePurchase
                                    .visibility === true
                              ).length,
                              visibility:
                                selectedViews.indexOf(
                                  "Vendor editable purchase"
                                ) > -1
                                  ? true
                                  : false,
                            },
                            vendorEditableBooking: {
                              level: productFields.filter(
                                (list) =>
                                  list.views.vendorEditableBooking
                                    .visibility === true
                              ).length,
                              visibility:
                                selectedViews.indexOf(
                                  "Vendor editable booking"
                                ) > -1
                                  ? true
                                  : false,
                            },
                            productFeatureList: {
                              level: productFields.filter(
                                (list) =>
                                  list.views.productFeatureList.visibility ===
                                  true
                              ).length,
                              visibility:
                                selectedViews.indexOf("Product feature list") >
                                -1,
                            },
                            catalogue: {
                              level: productFields.filter(
                                (list) =>
                                  list.views.catalogue.visibility === true
                              ).length,
                              visibility:
                                selectedViews.indexOf("Catalogue") > -1,
                            },
                          },
                          fieldViewPoints: selectedViews,
                          nanoid: nanoid(),
                        },
                      ]);
                    }
                    emptyAttributes();
                    setEditId(null);
                  }}
                >
                  Save
                </YellowButton>
              </div>
            </Box>
          </div>

          <div>
            <Typography variant="h6" gutterBottom className="mt-4 pl-3">
              All Features
            </Typography>
            {productFields.map((filteredList) => (
              <ListViewTable
                list={filteredList}
                onClickEdit={() => {
                  onClickEdit(filteredList.nanoid);
                }}
                setProductFields={setProductFields}
                type="add"
              />
            ))}
          </div>
          <Box sx={{ my: 4 }}>
            <YellowButton
              className="my-3 ml-3"
              variant="contained"
              color="primary"
              style={{
                padding: "0.6rem 2.8rem",
              }}
              onClick={() => setOpenModal(true)}
            >
              Preview
            </YellowButton>
          </Box>
          {openModal && (
            <FilterModal
              modalProps={{
                openModal,
                setopenModal: setOpenModal,
                productFields,
                onClickEdit,
                setProductFields,
              }}
            />
          )}

          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={createProductSelector.loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
      ``
      {mainTab === 1 && <AddAttribute />}
      {mainTab === 2 && <AddBrand />}
    </Container>
  );
};

export default AddProduct;
