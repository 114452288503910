import {
  Container,
  Box,
  Typography,
  Card,
  CardContent,
  Stack,
  Button,
  List,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Wrapper } from "./PartnerStyle";
import {
  AppRegistrationOutlined,
  AppRegistrationRounded,
  ArchitectureRounded,
  AssignmentIndRounded,
  CheckCircle,
  DesignServicesRounded,
  DeveloperBoardRounded,
  DomainRounded,
  ElectricalServicesRounded,
  ErrorOutlineOutlined,
  LocalAtmRounded,
  ModelTrainingRounded,
  PriceCheckRounded,
  QueryStatsRounded,
  SkateboardingRounded,
  SupportAgentRounded,
  SupportRounded,
  VerticalSplitRounded,
} from "@mui/icons-material";
import axios from "axios";
import { axiosInstance } from "../../utils/axiosInstance";
import SolrufTextField from "../../components/SolrufTextField/SolrufTextField";
import SolrufTextArea from "../../components/Custom/SolrufTextArea/SolrufTextArea";
import { toast } from "react-toastify";
import Laptop from "../../assets/laptop.png";

import WebLayout1 from "../../assets/weblayout1.png";
import WebLayout2 from "../../assets/weblayout2.png";
import "./PartnerStyle.css";
import { getTimeStamp } from "../../utils/utils";
import PrimaryButton from "../../components/Custom/PrimaryButton/PrimaryButton";

const PointStyle = {
  color: "#545454",
  border: "1px solid #545454",
  borderRadius: "50%",
  padding: "5px",
  fontSize: "36px",
};

const Points = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "10px",
        alignItems: "center",
        width: "100%",
        flexWrap: "no-wrap",
        "@media (max-width: 600px)": {
          alignItems: "start",
        },
      }}
    >
      {children}
    </Box>
  );
};

const EPC = () => {
  // scroll to top
  const [formData, setFormData] = useState({
    fullName: "",
    phoneNumber: "",
    email: "",
    pincode: "",
    city: "",
    state: "",
    address: "",
    remarks: "",
  });
  const [errorMessages, setErrorMessages] = useState({
    fullName: "",
    phoneNumber: "",
    email: "",
    pincode: "",
    city: "",
    state: "",
    address: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Clear error message when user starts typing
    setErrorMessages((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handlePincodeChange = async (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      pincode: value,
    }));
    // Clear error message when user starts typing
    setErrorMessages((prevState) => ({
      ...prevState,
      pincode: "",
    }));

    try {
      const response = await axiosInstance.get(
        `https://api.postalpincode.in/pincode/${value}`
      );
      if (
        response.data &&
        response.data.length > 0 &&
        response.data[0].PostOffice
      ) {
        const postOffice = response.data[0].PostOffice[0];
        setFormData((prevState) => ({
          ...prevState,
          city: postOffice.District,
          state: postOffice.State,
        }));
      }
    } catch (error) {
      console.error("Error fetching pincode data:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let hasError = false;

    // Validate required fields
    const requiredFields = [
      "fullName",
      "phoneNumber",
      "email",
      "pincode",
      "city",
      "state",
      "address",
    ];
    const newErrorMessages = {};

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrorMessages[field] = `${capitalizeEachWord(field)} is required`;
        hasError = true;
      }

      // Validate email pattern
      if (
        formData.email &&
        !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(formData.email)
      ) {
        newErrorMessages.email = "Invalid email address";
        hasError = true;
      }

      // Validate phone number length
      if (formData.phoneNumber && formData.phoneNumber.length !== 10) {
        newErrorMessages.phoneNumber =
          "Phone number must be 10 characters long";
        hasError = true;
      }
    });

    if (hasError) {
      setErrorMessages(newErrorMessages);
      return;
    }
    // timestamp: getTimeStamp(),
    // You can do something with the form data here, like send it to a server
    try {
      // Prepare form data
      const formDataToSend = new FormData();
      Object.entries(formData).forEach(([key, value]) => {
        formDataToSend.append(key, value);
      });

      formDataToSend.append("timestamp", getTimeStamp());

      console.log(formDataToSend.getAll("timestamp"));

      // Send form data
      await axios.post(
        "https://script.google.com/macros/s/AKfycbyjuHDlba0KjylJNw72DJSRQlPOry3smp9RAX3QuykonlvSefkvG-VBn6Ar5DTFrW185A/exec",
        formDataToSend
      );

      // Reset the form after successful submission
      setFormData({
        fullName: "",
        phoneNumber: "",
        email: "",
        pincode: "",
        city: "",
        state: "",
        address: "",
        remarks: "",
      });

      toast.success("Successfully registered");
    } catch (error) {
      toast.error("Something went wrong");
    }
    // Reset the form after submission
    setFormData({
      fullName: "",
      phoneNumber: "",
      email: "",
      pincode: "",
      city: "",
      state: "",
      address: "",
      remarks: "",
    });
  };

  // Function to capitalize each word
  const capitalizeEachWord = (str) => {
    return str
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/\b\w/g, (c) => c.toUpperCase())
      .replace(/_/g, " ");
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //   <form><script src="https://checkout.razorpay.com/v1/payment-button.js" data-payment_button_id="pl_O2PSPWhfvYn3wP" async> </script> </form>
  //   <form><script src="https://checkout.razorpay.com/v1/payment-button.js" data-payment_button_id="pl_O2PTY3OdzCKEDD" async> </script> </form>

  useEffect(() => {
    const containerStandard = document.getElementById(
      "razorpay-container-standard"
    );
    const containerPremium = document.getElementById(
      "razorpay-container-premium"
    );
    const existingScriptStandard = containerStandard.querySelector(
      'script[data-payment_button_id="pl_O2PSPWhfvYn3wP"]'
    );

    const existingScriptPremium = containerPremium.querySelector(
      'script[data-payment_button_id="pl_O2PTY3OdzCKEDD"]'
    );

    if (!existingScriptStandard && !existingScriptPremium) {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/payment-button.js";
      script.async = true;
      script.setAttribute("data-payment_button_id", "pl_O2PSPWhfvYn3wP");
      containerStandard.appendChild(script);

      const script1 = document.createElement("script");
      script1.src = "https://checkout.razorpay.com/v1/payment-button.js";
      script1.async = true;
      script1.setAttribute("data-payment_button_id", "pl_O2PTY3OdzCKEDD");
      containerPremium.appendChild(script1);

      //   return () => {
      //     containerStandard.removeChild(script);
      //     containerPremium.removeChild(script1);
      //   };
    }
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Wrapper>
      <Box
        sx={{
          background:
            'url("https://media-cldnry.s-nbcnews.com/image/upload/t_fit-1500w,f_auto,q_auto:best/newscms/2018_01/2122431/170816-solar-panels-mn-1520.jpg") no-repeat center/cover',
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              background: "rgba(42, 42, 42, 0.49)",
              borderRadius: "2px",
              boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
              backdropFilter: "blur(5px)",
              width: "75%",
              marginTop: "20px",
              padding: "10px",
            }}
          >
            <Typography
              variant="h4"
              sx={{ color: "#FFFFFF", textAlign: "center" }}
            >
              Channel Partner Program
            </Typography>
            <Typography
              sx={{ color: "#FFFFFF", fontWeight: "700", textAlign: "center" }}
            >
              Power Your Network, Power India's Future
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            justifyContent: "center",
            marginTop: "25px",
            marginBottom: "25px",
            flexWrap: "wrap",
          }}
        >
          <Card
            sx={{
              width: "350px",
              background: "rgba(42, 42, 42, 0.49)",
              borderRadius: "2px",
              boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
              backdropFilter: "blur(5px)",
            }}
          >
            <CardContent sx={{ height: "100%" }}>
              <Stack
                sx={{
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <Box>
                  <Typography sx={{ marginBottom: "25px", color: "#FFFFFF" }}>
                    The solar revolution is here, and Solruf is at the
                    forefront. Millions of homes and businesses are poised to go
                    solar, and Solruf is leading the charge. Join our{" "}
                    <span style={{ fontWeight: "700" }}>
                      Solruf EPC Partner Program
                    </span>{" "}
                    and become a key player in this exciting transformation.
                  </Typography>
                </Box>
                {/* <Box style={{ paddingBottom: "55px" }}>
                  <form
                    id="razorpay-container-standard"
                    style={{ width: "100%", paddingBottom: "15px" }}
                  ></form>
                  <form
                    id="razorpay-container-premium"
                    style={{ width: "100%" }}
                  ></form>
                </Box> */}
                <Box sx={{ marginTop: "100px" }}>
                  <PrimaryButton
                    sx={{ width: "100%" }}
                    onClick={() =>
                      (window.location.href =
                        "https://links.solruf.com/portfolio/majestic-solar")
                    }
                  >
                    Sample Vendor Portfolio
                  </PrimaryButton>
                </Box>
              </Stack>
            </CardContent>
          </Card>

          {/* Calendly */}
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                background: "#ffffff",
                width: "340px",
                px: 2,
              }}
            >
              <Typography variant="h6" sx={{ textAlign: "center", p: 1 }}>
                BOOK A MEETING WITH US
              </Typography>
              <div
                className="calendly-inline-widget"
                data-url="https://calendly.com/solruf/solruf-installer-platform-onboarding?hide_event_type_details=1&hide_gdpr_banner=1&text_color=4d4d4d&primary_color=ffd05b"
                style={{ minWidth: "320px", height: "480px" }}
              ></div>
            </Box>
          </Box>

          <Card sx={{ width: "350px", borderRadius: "2px" }}>
            <CardContent>
              <Box>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#FFD05B",
                    marginBottom: "35px",
                    textAlign: "center",
                    textDecoration: "underline",
                  }}
                >
                  Sign Up
                </Typography>
                <Stack
                  component="form"
                  onSubmit={handleSubmit}
                  sx={{
                    width: "100%",
                  }}
                  spacing={2}
                  noValidate
                  autoComplete="off"
                >
                  <SolrufTextField
                    label="Full Name"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleChange}
                    error={!!errorMessages.fullName}
                    helperText={errorMessages.fullName}
                    required
                  />
                  <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
                    <SolrufTextField
                      label="Phone Number"
                      variant="outlined"
                      sx={{ width: "100%" }}
                      size="small"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                      error={!!errorMessages.phoneNumber}
                      helperText={errorMessages.phoneNumber}
                      required
                    />
                    <SolrufTextField
                      label="Email"
                      variant="outlined"
                      sx={{ width: "100%" }}
                      size="small"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      error={!!errorMessages.email}
                      helperText={errorMessages.email}
                      required
                    />
                  </Stack>

                  <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
                    <SolrufTextField
                      label="Pincode"
                      variant="outlined"
                      sx={{}}
                      size="small"
                      name="pincode"
                      value={formData.pincode}
                      onChange={handlePincodeChange}
                      error={!!errorMessages.pincode}
                      helperText={errorMessages.pincode}
                      required
                    />
                    <SolrufTextField
                      label="City"
                      variant="outlined"
                      sx={{}}
                      size="small"
                      name="city"
                      value={formData.city}
                      onChange={handleChange}
                      error={!!errorMessages.city}
                      helperText={errorMessages.city}
                      required
                    />
                    <SolrufTextField
                      label="State"
                      variant="outlined"
                      sx={{}}
                      size="small"
                      name="state"
                      value={formData.state}
                      onChange={handleChange}
                      error={!!errorMessages.state}
                      helperText={errorMessages.state}
                      required
                    />
                  </Stack>

                  <SolrufTextField
                    label="Address"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    error={!!errorMessages.address}
                    helperText={errorMessages.address}
                    required
                  />
                  <SolrufTextArea
                    minRows={3}
                    maxRows={6}
                    label="Remarks (Optional)"
                    placeholder="Remarks (Optional)"
                    sx={{ width: "100%" }}
                    name="remarks"
                    value={formData.remarks}
                    onChange={handleChange}
                  />
                  <Button type="submit" variant="contained" color="primary">
                    Submit
                  </Button>
                </Stack>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
      <Box
        style={{
          backgroundColor: "#2a2a2a",
          paddingBottom: "20px",
          paddingTop: "20px",
        }}
      >
        {/* <Container>
          <Box sx={{ marginBottom: "25px" }}>
            <Box
              sx={{
                backgroundColor: "#FFD05B",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              <Typography
                variant="h5"
                sx={{ color: "#2a2a2a", textAlign: "center" }}
              >
                Why Partner with Solruf?
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                paddingLeft: "10%",
                paddingRight: "10%",
                paddingTop: "40px",
                paddingBottom: "40px",
              }}
            >
              <Container>
                <Box style={{ marginBottom: "25px" }}>
                  <Points>
                    <VerticalSplitRounded sx={PointStyle} />
                    <Typography
                      sx={{
                        fontWeight: "700",
                      }}
                    >
                      Seamless, Digital Platform:{" "}
                      <span style={{ fontWeight: "400" }}>
                        Our state-of-the-art platform makes it simple to manage
                        projects and onboard clients by streamlining the entire
                        process.
                      </span>
                    </Typography>
                  </Points>
                  <Typography></Typography>
                </Box>

                <Box style={{ marginBottom: "25px" }}>
                  <Points>
                    <SupportRounded sx={PointStyle} />
                    <Typography
                      sx={{
                        fontWeight: "700",
                      }}
                    >
                      Expert Support:{" "}
                      <span style={{ fontWeight: "400" }}>
                        Take advantage of our committed engineering and support
                        teams' knowledge. We'll provide you with the information
                        and tools required for success.
                      </span>
                    </Typography>
                  </Points>
                </Box>
                <Box style={{ marginBottom: "25px" }}>
                  <Points>
                    <LocalAtmRounded sx={PointStyle} />
                    <Typography
                      sx={{
                        fontWeight: "700",
                      }}
                    >
                      Unmatched Profit Potential:{" "}
                      <span style={{ fontWeight: "400" }}>
                        Earn attractive commissions on every successful project
                        you bring to Solruf.
                      </span>
                    </Typography>
                  </Points>
                </Box>
                <Box style={{ marginBottom: "25px" }}>
                  <Points>
                    <ElectricalServicesRounded sx={PointStyle} />
                    <Typography
                      sx={{
                        fontWeight: "700",
                      }}
                    >
                      Wide Range of Products & Services:{" "}
                      <span style={{ fontWeight: "400" }}>
                        Offer your clients the best with Solruf's comprehensive
                        suite of solar solutions, including our CYRA, ARBOR, and
                        CANOPAS ranges.
                      </span>
                    </Typography>
                  </Points>
                </Box>
              </Container>
            </Box>
          </Box>
        </Container>
        <Container>
          <Box sx={{ marginBottom: "25px" }}>
            <Box
              sx={{
                backgroundColor: "#FFD05B",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              <Typography
                variant="h5"
                sx={{ color: "#2a2a2a", textAlign: "center" }}
              >
                Who Should Apply?
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                paddingLeft: "10%",
                paddingRight: "10%",
                paddingTop: "40px",
                paddingBottom: "40px",
              }}
            >
              <Container>
                <Box style={{ marginBottom: "35px" }}>
                  <Typography>
                    Solruf is seeking partnerships with a variety of
                    professionals who share our vision for a sustainable future.
                    This includes:
                  </Typography>
                </Box>
                <Box style={{ marginBottom: "25px" }}>
                  <Points>
                    <ArchitectureRounded sx={PointStyle} />
                    <Typography
                      sx={{
                        fontWeight: "700",
                      }}
                    >
                      Premium Interior Designers & Architects:{" "}
                      <span style={{ fontWeight: "400" }}>
                        Integrating high-performance solar solutions can elevate
                        your projects and provide value for your clients.
                      </span>
                    </Typography>
                  </Points>
                  <Typography></Typography>
                </Box>

                <Box style={{ marginBottom: "25px" }}>
                  <Points>
                    <AssignmentIndRounded sx={PointStyle} />
                    <Typography
                      sx={{
                        fontWeight: "700",
                      }}
                    >
                      Wealth managers and certified public accountants:{" "}
                      <span style={{ fontWeight: "400" }}>
                        Give your clients a comprehensive financial plan that
                        takes solar energy's long-term advantages into account.
                      </span>
                    </Typography>
                  </Points>
                </Box>
                <Box style={{ marginBottom: "25px" }}>
                  <Points>
                    <DeveloperBoardRounded sx={PointStyle} />
                    <Typography
                      sx={{
                        fontWeight: "700",
                      }}
                    >
                      Developers and builders of luxury projects:{" "}
                      <span style={{ fontWeight: "400" }}>
                        Set your upscale projects apart with innovative solar
                        technology and environmentally friendly architecture.
                      </span>
                    </Typography>
                  </Points>
                </Box>
                <Box style={{ marginBottom: "25px" }}>
                  <Points>
                    <DomainRounded sx={PointStyle} />
                    <Typography
                      sx={{
                        fontWeight: "700",
                      }}
                    >
                      Real estate agents and developers:{" "}
                      <span style={{ fontWeight: "400" }}>
                        Installing solar electricity on your homes can draw in
                        eco-aware tenants and increase their market value.
                      </span>
                    </Typography>
                  </Points>
                </Box>
                <Box style={{ marginBottom: "25px" }}>
                  <Points>
                    <DesignServicesRounded sx={PointStyle} />
                    <Typography
                      sx={{
                        fontWeight: "700",
                      }}
                    >
                      Designers and landscaping agencies:{" "}
                      <span style={{ fontWeight: "400" }}>
                        Use solar elements to create outdoor areas that are
                        genuinely sustainable.
                      </span>
                    </Typography>
                  </Points>
                </Box>
              </Container>
            </Box>
          </Box>
        </Container>
        <Container>
          <Box sx={{ marginBottom: "25px" }}>
            <Box
              sx={{
                backgroundColor: "#FFD05B",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              <Typography
                variant="h5"
                sx={{ color: "#2a2a2a", textAlign: "center" }}
              >
                Becoming a Solruf Partner is Easy!
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                paddingLeft: "10%",
                paddingRight: "10%",
                paddingTop: "40px",
                paddingBottom: "40px",
              }}
            >
              <Container>
                <Box style={{ marginBottom: "25px" }}>
                  <Points>
                    <AppRegistrationRounded sx={PointStyle} />
                    <Typography
                      sx={{
                        fontWeight: "700",
                      }}
                    >
                      Online Registration:{" "}
                      <span style={{ fontWeight: "400" }}>
                        Complete our short online application.
                      </span>
                    </Typography>
                  </Points>
                  <Typography></Typography>
                </Box>

                <Box style={{ marginBottom: "25px" }}>
                  <Points>
                    <SkateboardingRounded sx={PointStyle} />
                    <Typography
                      sx={{
                        fontWeight: "700",
                      }}
                    >
                      Full Onboarding:{" "}
                      <span style={{ fontWeight: "400" }}>
                        We'll walk you through a brief onboarding procedure to
                        make sure you have access to all the resources and
                        information you require.
                      </span>
                    </Typography>
                  </Points>
                </Box>
                <Box style={{ marginBottom: "25px" }}>
                  <Points>
                    <PriceCheckRounded sx={PointStyle} />
                    <Typography
                      sx={{
                        fontWeight: "700",
                      }}
                    >
                      Begin Making Money:{" "}
                      <span style={{ fontWeight: "400" }}>
                        Introduce your network to Solruf's cutting-edge solar
                        systems to begin receiving lucrative commissions.
                      </span>
                    </Typography>
                  </Points>
                </Box>
                <Box style={{ marginTop: "35px" }}>
                  <Typography>
                    Join the Solruf Partner Program today and be a part of
                    building a brighter, more sustainable future for India!
                  </Typography>
                </Box>
              </Container>
            </Box>
          </Box>
        </Container>
        <Container>
          <Box sx={{ marginBottom: "25px" }}>
            <Box
              sx={{
                backgroundColor: "#FFD05B",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              <Typography
                variant="h5"
                sx={{ color: "#2a2a2a", textAlign: "center" }}
              >
                We also offer
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                paddingLeft: "10%",
                paddingRight: "10%",
                paddingTop: "40px",
                paddingBottom: "40px",
              }}
            >
              <Container>
                <Box style={{ marginBottom: "25px" }}>
                  <Points>
                    <ModelTrainingRounded sx={PointStyle} />
                    <Typography
                      sx={{
                        fontWeight: "700",
                      }}
                    >
                      Training & Sales Support:{" "}
                      <span style={{ fontWeight: "400" }}>
                        Receive ongoing training and support to help you excel
                        as a Solruf partner.
                      </span>
                    </Typography>
                  </Points>
                  <Typography></Typography>
                </Box>

                <Box style={{ marginBottom: "25px" }}>
                  <Points>
                    <SupportAgentRounded sx={PointStyle} />
                    <Typography
                      sx={{
                        fontWeight: "700",
                      }}
                    >
                      Expert Support:{" "}
                      <span style={{ fontWeight: "400" }}>
                        Track leads, manage projects, and access valuable
                        resources through our dedicated partner portal.
                      </span>
                    </Typography>
                  </Points>
                </Box>

                <Box style={{ marginTop: "35px" }}>
                  <Typography>
                    Don't miss out on this exciting opportunity! Partner with
                    Solruf and unlock the power of the solar revolution!
                  </Typography>
                </Box>
              </Container>
            </Box>
          </Box>
        </Container> */}
        <Container>
          <Box sx={{ marginBottom: "25px" }}>
            <Box
              sx={{
                backgroundColor: "#FFD05B",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              <Typography
                variant="h5"
                sx={{ color: "#2a2a2a", textAlign: "center" }}
              >
                Three Main Aspects
              </Typography>
              <Typography
                variant="h5"
                sx={{ color: "#2a2a2a", textAlign: "center" }}
              >
                of solar Business
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                paddingLeft: "10%",
                paddingRight: "10%",
                "@media (max-width: 600px)": {
                  paddingLeft: "2%",
                  paddingRight: "2%",
                },
                paddingTop: "40px",
                paddingBottom: "40px",
              }}
            >
              <div className="timeline">
                <ol>
                  <li>
                    <h2 style={{ marginTop: "20px", marginBottom: "20px" }}>
                      Pre Sales
                    </h2>
                    <p>Solar Designing </p>
                    <p>Automated Proposals</p>
                    <p>Solar Leads CRM</p>
                    <p>Site Visit Support</p>
                  </li>
                  <li>
                    <h2 style={{ marginTop: "20px", marginBottom: "20px" }}>
                      Solar Installation
                    </h2>
                    <p>Project Financing </p>
                    <p>Project Management Tools</p>
                    <p>Manufacture Source Procurement</p>
                    <p>Nation wide Solar Installation Team</p>
                    <p>Discom Licensing & Subsidy Support</p>
                  </li>
                  <li>
                    <h2 style={{ marginTop: "20px", marginBottom: "20px" }}>
                      Post Installation
                    </h2>
                    <p>Lead Referral Program </p>
                    <p>Support Warranty Support</p>
                    <p>Operation & Maintenance Support</p>
                  </li>
                </ol>
              </div>
            </Box>
          </Box>
        </Container>

        {/* 2 */}
        <Container>
          <Box sx={{ marginBottom: "25px" }}>
            <Box
              sx={{
                backgroundColor: "#FFD05B",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              <Typography
                variant="h5"
                sx={{ color: "#2a2a2a", textAlign: "center" }}
              >
                Problems faced in Solar project
              </Typography>
              <Typography
                variant="h5"
                sx={{ color: "#2a2a2a", textAlign: "center" }}
              >
                & lead management
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                paddingLeft: "10%",
                paddingRight: "10%",
                paddingTop: "40px",
                paddingBottom: "40px",
              }}
            >
              <Container>
                <Stack
                  sx={{
                    justifyContent: "center",
                    width: "100%",
                    flexDirection: "row",
                  }}
                >
                  <ul>
                    <li style={{ lineHeight: 2.5 }}>
                      <Typography>Lack of Solar business profile</Typography>
                    </li>
                    <li style={{ lineHeight: 2.5 }}>
                      <Typography>Delayed proposal th the customer</Typography>
                    </li>
                    <li style={{ lineHeight: 2.5 }}>
                      <Typography>Non-Timely followup on bulk leads</Typography>
                    </li>
                    <li style={{ lineHeight: 2.5 }}>
                      <Typography>
                        Support required on project financing
                      </Typography>
                    </li>
                    <li style={{ lineHeight: 2.5 }}>
                      <Typography>
                        Unsecured procurement and lack of vendors
                      </Typography>
                    </li>
                    <li style={{ lineHeight: 2.5 }}>
                      <Typography>
                        Non-availability of Installation team in project
                        locality
                      </Typography>
                    </li>
                  </ul>
                </Stack>
              </Container>
            </Box>
          </Box>
        </Container>
        {/* 3 */}
        <Container>
          <Box sx={{ marginBottom: "25px" }}>
            <Box
              sx={{
                backgroundColor: "#FFD05B",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              <Typography
                variant="h5"
                sx={{ color: "#2a2a2a", textAlign: "center" }}
              >
                Our Solutions
              </Typography>
              {/* <Typography
                variant="h5"
                sx={{ color: "#2a2a2a", textAlign: "center" }}
              >
                & lead management
              </Typography> */}
            </Box>
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                paddingTop: "40px",
                paddingBottom: "40px",
              }}
            >
              <Stack
                sx={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                className="stack"
              >
                <Box sx={{ width: "70%" }} className="content-stack">
                  <Box sx={{ mb: 5 }}>
                    <Box
                      sx={{
                        backgroundImage:
                          "linear-gradient(90deg, #FFE04F50 0%, rgba(255, 255, 255, 0.5) 100%)",
                        padding: "10px",
                      }}
                    >
                      <Typography variant="h5">Lead management</Typography>
                    </Box>
                    <Box sx={{ mt: 3, px: 3 }}>
                      <Typography>1. Set Reminders for Followups</Typography>
                      <Typography>
                        2. Get High Quality 10 Solar Leads / week
                      </Typography>
                      <Typography>
                        3. ⁠Generate automated Solar proposals & Quotations
                      </Typography>
                      <Typography>
                        4. ⁠Store all customer data in a single location
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ mb: 5 }}>
                    <Box
                      sx={{
                        backgroundImage:
                          "linear-gradient(90deg, #FFE04F50 0%, rgba(255, 255, 255, 0.5) 100%)",
                        padding: "10px",
                      }}
                    >
                      <Typography variant="h5">
                        Solar Vendor Portfolios
                      </Typography>
                    </Box>
                    <Box sx={{ mt: 3, px: 3 }}>
                      <Typography>
                        1. Automated Product Catalog for sharing
                      </Typography>
                      <Typography>
                        2. Digital Solar Profile with Business details & past
                        projects
                      </Typography>
                      <Typography>
                        3. Get leads & promote referral marketing with
                        portfolios
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  className="content-stack"
                  sx={{
                    px: 5,
                  }}
                >
                  <img src={Laptop} alt="Laptop" style={{ width: "100%" }} />
                </Box>
              </Stack>

              <Stack
                sx={{
                  flexDirection: "row-reverse",
                  mt: 10,
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                className="stack"
              >
                <Box className="content-stack" sx={{ width: "70%" }}>
                  <Box sx={{ mb: 5 }}>
                    <Box
                      sx={{
                        backgroundImage:
                          "linear-gradient(90deg, rgba(255, 255, 255, 0.5) 0%, #FFE04F50 100%)",
                        padding: "10px",
                      }}
                    >
                      <Typography variant="h5">
                        Solar Designing & Installation support
                      </Typography>
                    </Box>
                    <Box sx={{ mt: 3, px: 3 }}>
                      <Typography>
                        1. ⁠Online Licensing & application support
                      </Typography>
                      <Typography>
                        2. ⁠Provide ONM to your customers with us
                      </Typography>
                      <Typography>
                        3. ⁠Dedicated INC team in your project locality
                      </Typography>
                      <Typography>
                        4. Get 3D Solar design & Technical report for every site
                        visit
                      </Typography>
                      <Typography>
                        5. ⁠Track BOM Procurement, Payments & Deliveries
                        simultaneously
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ mb: 5 }}>
                    <Box
                      sx={{
                        backgroundImage:
                          "linear-gradient(90deg, rgba(255, 255, 255, 0.5) 0%, #FFE04F50 100%)",
                        padding: "10px",
                      }}
                    >
                      <Typography variant="h5">Project Financing</Typography>
                    </Box>
                    <Box sx={{ mt: 3, px: 3 }}>
                      <Typography>1. ⁠Procure materials on credit</Typography>
                      <Typography>
                        2. Get EMI for your Customers @ Solruf projects
                      </Typography>
                      <Typography>
                        3. ⁠Access to PPA, Opean access, RESCO & Group captive
                        project financing
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    px: 5,
                  }}
                  className="content-stack"
                >
                  <img
                    src={WebLayout1}
                    alt="laptop3"
                    style={{
                      boxShadow:
                        "19.617px 19.617px 36.781px 0px rgba(0, 0, 0, 0.25), 4.904px 4.904px 12.26px 0px rgba(0, 0, 0, 0.35)",
                    }}
                  />
                  <img
                    src={WebLayout2}
                    alt="laptop2"
                    style={{
                      marginLeft: "100px",
                      marginTop: "50px",
                    }}
                  />
                </Box>
              </Stack>
            </Box>
          </Box>
        </Container>
        {/* 4 */}
        <Container>
          <Box sx={{ marginBottom: "25px" }}>
            <Box
              sx={{
                backgroundColor: "#FFD05B",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              <Typography
                variant="h5"
                sx={{ color: "#2a2a2a", textAlign: "center" }}
              >
                Plans & Pricing
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                paddingLeft: "10%",
                paddingRight: "10%",
                paddingTop: "40px",
                paddingBottom: "40px",
              }}
            >
              <Container>
                <Stack
                  sx={{
                    flexDirection: "row",
                    justifyContent: "center",
                    gap: 4,
                  }}
                  className="cards-price"
                >
                  <Card
                    sx={{
                      width: "330px",
                      boxShadow:
                        "2.985px 14.926px 5.97px 0px rgba(0, 0, 0, 0.10)",
                    }}
                  >
                    <CardContent>
                      <Box sx={{ padding: "1px" }}>
                        <Box
                          sx={{
                            padding: "10px 43px",
                            borderRadius: "7px",
                            background: "#FFD05B",
                          }}
                        >
                          <Typography variant="h6" sx={{ textAlign: "center" }}>
                            Standard
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ mt: 3, height: "250px" }}>
                        <Box
                          sx={{ display: "flex", gap: 1, alignItems: "center" }}
                        >
                          <CheckCircle sx={{ fontSize: "16px" }} />
                          <Typography sx={{ fontSize: "16px" }}>
                            Solar Digital Business Portfolio{" "}
                          </Typography>
                        </Box>
                        <Box
                          sx={{ display: "flex", gap: 1, alignItems: "center" }}
                        >
                          <CheckCircle sx={{ fontSize: "16px" }} />
                          <Typography sx={{ fontSize: "16px" }}>
                            Procurement{" "}
                          </Typography>
                        </Box>
                        <Box
                          sx={{ display: "flex", gap: 1, alignItems: "center" }}
                        >
                          <CheckCircle sx={{ fontSize: "16px" }} />
                          <Typography sx={{ fontSize: "16px" }}>
                            Automated Quotations{" "}
                          </Typography>
                        </Box>
                        <Box
                          sx={{ display: "flex", gap: 1, alignItems: "center" }}
                        >
                          <CheckCircle sx={{ fontSize: "16px" }} />
                          <Typography sx={{ fontSize: "16px" }}>
                            Solar Project Pproposal{" "}
                          </Typography>
                        </Box>
                        <Box
                          sx={{ display: "flex", gap: 1, alignItems: "center" }}
                        >
                          <CheckCircle sx={{ fontSize: "16px" }} />
                          <Typography sx={{ fontSize: "16px" }}>
                            CRM{" "}
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ mt: 5, mb: 2 }}>
                        <Typography variant="h4">₹ 3,500 / Month</Typography>
                      </Box>

                      <Box sx={{ mt: 3 }}>
                        <Box
                          sx={{ display: "flex", gap: 1, alignItems: "center" }}
                        >
                          <ErrorOutlineOutlined
                            sx={{ fontSize: "14px", color: "#FFD05B" }}
                          />
                          <Typography sx={{ fontSize: "14px" }}>
                            Unlimited Quotation and profile sharing{" "}
                          </Typography>
                        </Box>

                        <Box
                          sx={{ display: "flex", gap: 1, alignItems: "center" }}
                        >
                          <ErrorOutlineOutlined
                            sx={{ fontSize: "14px", color: "#FFD05B" }}
                          />
                          <Typography sx={{ fontSize: "14px" }}>
                            Unlimited access CRM (Customer Relation Management){" "}
                          </Typography>
                        </Box>
                      </Box>

                      <Box style={{ marginTop: "50px" }}>
                        <form
                          id="razorpay-container-standard"
                          style={{ width: "100%", paddingBottom: "15px" }}
                        ></form>
                      </Box>
                    </CardContent>
                  </Card>

                  {/* second card */}
                  <Card
                    sx={{
                      width: "330px",
                      boxShadow:
                        "2.985px 14.926px 5.97px 0px rgba(0, 0, 0, 0.10)",
                    }}
                  >
                    <CardContent>
                      <Box sx={{ padding: "1px" }}>
                        <Box
                          sx={{
                            padding: "10px 43px",
                            borderRadius: "7px",
                            background: "#FFD05B",
                          }}
                        >
                          <Typography variant="h6" sx={{ textAlign: "center" }}>
                            Premium
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{ mt: 3, height: "250px" }}
                        className="card-points"
                      >
                        <Box
                          sx={{ display: "flex", gap: 1, alignItems: "center" }}
                        >
                          <CheckCircle sx={{ fontSize: "16px" }} />
                          <Typography sx={{ fontSize: "16px" }}>
                            Solar Digital Business Portfolio{" "}
                          </Typography>
                        </Box>
                        <Box
                          sx={{ display: "flex", gap: 1, alignItems: "center" }}
                        >
                          <CheckCircle sx={{ fontSize: "16px" }} />
                          <Typography sx={{ fontSize: "16px" }}>
                            Procurement{" "}
                          </Typography>
                        </Box>
                        <Box
                          sx={{ display: "flex", gap: 1, alignItems: "center" }}
                        >
                          <CheckCircle sx={{ fontSize: "16px" }} />
                          <Typography sx={{ fontSize: "16px" }}>
                            Automated Quotations{" "}
                          </Typography>
                        </Box>
                        <Box
                          sx={{ display: "flex", gap: 1, alignItems: "center" }}
                        >
                          <CheckCircle sx={{ fontSize: "16px" }} />
                          <Typography sx={{ fontSize: "16px" }}>
                            Solar Project Pproposal{" "}
                          </Typography>
                        </Box>
                        <Box
                          sx={{ display: "flex", gap: 1, alignItems: "center" }}
                        >
                          <CheckCircle sx={{ fontSize: "16px" }} />
                          <Typography sx={{ fontSize: "16px" }}>
                            CRM{" "}
                          </Typography>
                        </Box>
                        <Box
                          sx={{ display: "flex", gap: 1, alignItems: "center" }}
                        >
                          <CheckCircle sx={{ fontSize: "16px" }} />
                          <Typography sx={{ fontSize: "16px" }}>
                            Solar Leads{" "}
                          </Typography>
                        </Box>
                        <Box
                          sx={{ display: "flex", gap: 1, alignItems: "center" }}
                        >
                          <CheckCircle sx={{ fontSize: "16px" }} />
                          <Typography sx={{ fontSize: "16px" }}>
                            3D Solar Designs{" "}
                          </Typography>
                        </Box>
                        <Box
                          sx={{ display: "flex", gap: 1, alignItems: "center" }}
                        >
                          <CheckCircle sx={{ fontSize: "16px" }} />
                          <Typography sx={{ fontSize: "16px" }}>
                            Technical Expert Consulation{" "}
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ mt: 3, mb: 2 }}>
                        <Typography variant="h4">₹ 5,000 / Month</Typography>
                      </Box>

                      <Box sx={{ mt: 3 }}>
                        <Box
                          sx={{ display: "flex", gap: 1, alignItems: "center" }}
                        >
                          <ErrorOutlineOutlined
                            sx={{ fontSize: "14px", color: "#FFD05B" }}
                          />
                          <Typography sx={{ fontSize: "14px" }}>
                            Unlimited Quotation and profile sharing{" "}
                          </Typography>
                        </Box>
                        <Box
                          sx={{ display: "flex", gap: 1, alignItems: "center" }}
                        >
                          <ErrorOutlineOutlined
                            sx={{ fontSize: "14px", color: "#FFD05B" }}
                          />
                          <Typography sx={{ fontSize: "14px" }}>
                            Unlimited access CRM (Customer Relation Management){" "}
                          </Typography>
                        </Box>
                        <Box
                          sx={{ display: "flex", gap: 1, alignItems: "center" }}
                        >
                          <ErrorOutlineOutlined
                            sx={{ fontSize: "14px", color: "#FFD05B" }}
                          />
                          <Typography sx={{ fontSize: "14px" }}>
                            8 to 10 Solar Installation leads per week{" "}
                          </Typography>
                        </Box>

                        <Box
                          sx={{ display: "flex", gap: 1, alignItems: "center" }}
                        >
                          <ErrorOutlineOutlined
                            sx={{ fontSize: "14px", color: "#FFD05B" }}
                          />
                          <Typography sx={{ fontSize: "14px" }}>
                            Only 20 3D on-site designs{" "}
                          </Typography>
                        </Box>
                      </Box>
                      <Box style={{ marginTop: "25px" }}>
                        <form
                          id="razorpay-container-premium"
                          style={{ width: "100%", paddingBottom: "15px" }}
                        ></form>
                      </Box>
                    </CardContent>
                  </Card>
                </Stack>
                <Box style={{ marginTop: "45px" }}>
                  <ul>
                    <li>
                      The Standard Plan offers essential features like CRM,
                      profile sharing, procurement, and expert consultation at a
                      lower price point.{" "}
                    </li>
                    <li>
                      Meanwhile, the Premium Plan includes additional features
                      such as premium quality leads, 3D on-site designs (with a
                      limitation of 20 designs), and technical support, making
                      it suitable for businesses looking for more advanced tools
                      and services to enhance their solar projects.{" "}
                    </li>
                  </ul>
                </Box>
              </Container>
            </Box>
          </Box>
        </Container>
      </Box>
    </Wrapper>
  );
};

export default EPC;
