import {
    Box,
    Chip,
 
    MenuItem,
    Select,
    Typography,
 } from '@mui/material';
 import { Container } from '@mui/material';
 import React, { useEffect, useState } from 'react';
 import { axiAuth } from '../../../utils/axiosInstance';
 import PrimaryButton from '../../../components/Custom/PrimaryButton/PrimaryButton';
 import { toast } from 'react-toastify';
 
 const ManufacturerManagement = () => {
    const [manufacturerUserList, setManufacturerList] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [editUserId, setEditUserId] = useState(null);
 
    const [manufacturers, setManufacturers] = useState([]);
 
    useEffect(() => {
       if (editMode) return;
 
       axiAuth('api/admin/manufacturer-user')
          .then(({ data, status }) => {
              
             if (status === 200) {
                setManufacturerList(data?.data);
             }
          })
          .catch((err) => {});
    }, [editMode]);
 
    useEffect(() => {
       axiAuth('api/manufacturers')
          .then(({ data, status }) => {
              
             if (status === 200) {
                setManufacturers(data?.data);
             }
          })
          .catch((err) =>{});
    }, []);
 
    const editHandler = (userId) => {
        
       setEditMode(true);
       setEditUserId(userId);
    };
 
    const saveHandler = () => {
       axiAuth
          .post('api/admin/manufacturer-user', {
             user_id: editUserId,
             manufacturer_id: selectedManufacturer,
          })
          .then(({ status }) => {
             if (status === 200) {
                setEditMode(false);
                setEditUserId(null);
             }
          })
          .catch((err) => {
             toast.warn('Something went wrong when adding Manufacturer');
              
          });
    };
 
    const [selectedManufacturer, setSelectedManufacturer] = React.useState('');
 
    const handleChange = (event) => {
       setSelectedManufacturer(event.target.value);
    };
     
 
    return (
       <Container>
          {manufacturerUserList.map(({user, manufacturer}) => (
             <Box
                key={user.id}
                sx={{ mb: 4, p: 2, borderRadius: 2, boxShadow: 25 }}
             >
                <Box
                   sx={{
                      bgcolor: '#F3F3F3',
                      display: 'flex',
                      alignItems: 'center',
                      borderRadius: 2,
                      gap: 2,
                      p: 2,
                      '& img': {
                         width: '60px',
                         height: 'auto',
                      },
                   }}
                >
                   <img
                      src={
                         user.profile_image
                            ? user.profile_image
                            : 'https://static.vecteezy.com/system/resources/previews/004/991/321/original/picture-profile-icon-male-icon-human-or-people-sign-and-symbol-vector.jpg'
                      }
                      alt={user.first_name}
                   />
                   <Box sx={{ display: 'flex', gap: 2 }}>
                      <Typography variant='h5'>{user.first_name}</Typography>
                      <Typography variant='h5'>{user.last_name}</Typography>
                   </Box>
                   <Box>
                      <Chip label={`User Id: ${user.id}`} />
                   </Box>
                </Box>
                <Typography variant='h5' sx={{ mt: 2 }}>
                   Manufacturers
                </Typography>
                <Box>
                   {manufacturer && (
                      <Box
                         sx={{
                            display: 'flex',
                            gap: 2,
                            alignItems: 'center',
                            borderBottom: '1px solid #f3f3f3',
                            '& img': {
                               width: '50px',
                               height: 'auto',
                            },
                            mt: 2,
                         }}
                      >
                         <img src={manufacturer.logo} alt={manufacturer.name} />
                         <Typography variant='h6'>{manufacturer.name}</Typography>
                      </Box>
                   )}
 
                   {editMode && editUserId === user.id && (
                      <Box sx={{ mt: 2 }}>
                         <Typography id='demo-simple-select-label' variant='h6'>
                            Select Manufacturer
                         </Typography>
                         <Select
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            value={selectedManufacturer}
                            label='Age'
                            onChange={handleChange}
                            sx={{
                               minWidth: '250px',
                            }}
                         >
                            {manufacturers.map(({ id, name }) => (
                               <MenuItem value={id}>{name}</MenuItem>
                            ))}
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                         </Select>
                      </Box>
                   )}
 
                   <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <PrimaryButton
                         variant='contained'
                         onClick={() =>
                            editMode ? saveHandler() : editHandler(user.id)
                         }
                         sx={{ mt: 2 }}
                      >
                         {editMode && editUserId === user.id ? 'Save' : 'Edit'}
                      </PrimaryButton>
                      {editMode && (
                         <PrimaryButton
                            variant='contained'
                            onClick={() => setEditMode(false)}
                            sx={{ mt: 2 }}
                         >
                            Cancel
                         </PrimaryButton>
                      )}
                   </Box>
                </Box>
             </Box>
          ))}
       </Container>
    );
 };
 
 export default ManufacturerManagement;
 