import React, { useEffect, useState } from "react";
import { Box, Typography, Stack } from "@mui/material";
import SectionHeader from "./SectionHeader";
import { useNavigate } from "react-router-dom";
import HomeSectionTitle from "../../../components/HomeSectionTitle/HomeSectionTitle";
import TrendingCard from "./TrendingCard";
import axios from "axios";

const blogs = [
  {
    title: "Energizing Homes",
    description:
      "Discover the transformative impact of solar batteries in home energy storage. Our comprehensive guide delves into the role these innovative batteries play, offering insights into efficient energy management and the evolution of sustainable home power solutions.",
    route: "/blogs/energize",
    img: "https://solruf-node-static-files.s3.ap-south-1.amazonaws.com/977902bded620bac0ac34e2330779f9b.jpeg",
    author: "Sagar Baisla",
    timeAgo: "1 months ago",
  },
  {
    title:
      "Illuminating Tomorrow: Solar Inverters, Solar Bulbs, and Solar Panels",
    description:
      "Dive into the future of solar technology with a focus on solar inverters, solar bulbs, and solar panels. This guide unveils the latest innovations shaping the solar tech industry, shedding light on how these advancements are revolutionizing energy efficiency, sustainability, and the way we illuminate our world.",
    route: "/blogs/illuminating",
    img: "https://solruf-node-static-files.s3.ap-south-1.amazonaws.com/cf76cbbad3f58ed393e8a65c19d41caf-min.png",
    author: "Sumit Agarwal",
    timeAgo: "3 weeks ago",
  },
];

const truncateHTML = (html, length) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  const text = tempDiv.innerText;
  return text.length > length ? text.slice(0, length) + "..." : html;
};

const extractDate = (timestamp) => {
  const date = new Date(timestamp);
  return date.toLocaleDateString(); // This will return the date in the local date format
};

const getTimeAgo = (timestamp) => {
  const now = new Date();
  const givenDate = new Date(timestamp);

  // Helper function to strip time part from a date
  const stripTime = (date) =>
    new Date(date.getFullYear(), date.getMonth(), date.getDate());

  // Strip time parts for accurate comparison
  const strippedNow = stripTime(now);
  const strippedGivenDate = stripTime(givenDate);

  if (strippedNow.getTime() === strippedGivenDate.getTime()) {
    return "Today";
  }

  const diffTime = Math.abs(now - givenDate);
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  const diffMonths = Math.floor(diffDays / 30); // Rough estimate of a month
  const diffYears = Math.floor(diffDays / 365); // Rough estimate of a year

  if (diffYears > 0) {
    return `${diffYears} year${diffYears > 1 ? "s" : ""} ago`;
  } else if (diffMonths > 0) {
    return `${diffMonths} month${diffMonths > 1 ? "s" : ""} ago`;
  } else {
    return `${diffDays} day${diffDays > 1 ? "s" : ""} ago`;
  }
};

const FourBlog = ({ home }) => {
  const navigate = useNavigate();

  const [blogsData, setBlogsData] = useState(null);

  useEffect(() => {
    axios
      .get(
        "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@socialmedia_90251"
      )
      .then((response) => {
        setBlogsData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching blog data:", error);
      });
  }, []);

  console.log(blogsData);

  if (!blogsData) {
    return <div>Loading...</div>; // You can return a loader or placeholder here
  }

  const title1 =
    blogsData.items[0].title.length > 34
      ? blogsData.items[0].title.slice(0, 34) + "..."
      : blogsData.items[0].title;
  const imageMatch1 = blogsData.items[0].description
    .toString()
    .match(/<img[^>]+src="([^">]+)"/);
  const image1 = imageMatch1 ? imageMatch1[1] : null;
  const description1 = blogsData.items[0].description.replace(
    /<img[^>]*>/g,
    ""
  );
  const truncatedDescription1 = truncateHTML(description1, 260);
  const pubDate1 = extractDate(blogsData.items[0].pubDate);
  const timeAgo1 = getTimeAgo(blogsData.items[0].pubDate);

  const title2 =
    blogsData.items[1].title.length > 34
      ? blogsData.items[1].title.slice(0, 34) + "..."
      : blogsData.items[1].title;

  const imageMatch2 = blogsData.items[1].description
    .toString()
    .match(/<img[^>]+src="([^">]+)"/);
  const image2 = imageMatch2 ? imageMatch2[1] : null;
  const description2 = blogsData.items[1].description.replace(
    /<img[^>]*>/g,
    ""
  );
  const truncatedDescription2 = truncateHTML(description2, 260);
  const pubDate2 = extractDate(blogsData.items[1].pubDate);
  const timeAgo2 = getTimeAgo(blogsData.items[1].pubDate);

  return (
    <Box sx={{ my: 12 }}>
      {home && (
        <HomeSectionTitle fixed="Check out our Top Blogs" route="/blogs" />
      )}

      {!home && <SectionHeader title="New & Trendy" />}

      <Box sx={{ mt: home ? 8 : 0, display: ["none", "block"] }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: {
              xs: "column",
              md: "row",
            },
          }}
        >
          <Box
            sx={{
              "& img": {
                maxWidth: "100%",
                objectFit: "cover",
                width: "100%",
                height: "100%",
              },
              width: 600,
              maxWidth: "100%",
              height: 380,
              flex: 1,
              // background: 'red',
              overflow: "hidden",
            }}
          >
            <img src={image1} alt="" />
          </Box>
          <Box
            sx={{
              width: 600,
              maxWidth: "100%",
              height: 380,
              flex: 1,

              backgroundImage: `
                     linear-gradient(
                       rgba(255, 255, 255, 0.9), 
                       rgba(255, 255, 255, 0.9)
                     ),
                     url("https://i.ibb.co/9WQ3V2B/Rectangle-42.png")`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              //  p: 3,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // p: 2,
            }}
          >
            <Box sx={{ width: "70%" }}>
              <Typography variant="body2" fontWeight={600} color="#4D4D4D">
                SOLRUF &nbsp;&nbsp;&nbsp; - {timeAgo1}
              </Typography>
              <Typography variant="h5" fontWeight={600} color="#000000" my={1}>
                {title1}
              </Typography>

              <Typography variant="body1" fontWeight={400} color={"#4D4D4D"}>
                {truncatedDescription1}
              </Typography>
              <Stack
                direction="row"
                justifyContent={"space-between"}
                sx={{ mt: 2 }}
              >
                {/* <Typography variant="body2" fontWeight={600} color="#4D4D4D">
                  10 Min Read
                </Typography> */}
                <Typography
                  variant="body2"
                  fontWeight={600}
                  color="#000000"
                  sx={{
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  // onClick={() => navigate("/blogs/energize")}
                  onClick={() => window.open(blogsData.items[0].link, "_blank")}
                >
                  Read More
                </Typography>
              </Stack>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: {
              xs: "column",
              md: "row",
            },
          }}
        >
          <Box
            sx={{
              width: 600,
              maxWidth: "100%",
              height: 380,
              flex: 1,

              backgroundImage: `
                     linear-gradient(
                       rgba(255, 255, 255, 0.9), 
                       rgba(255, 255, 255, 0.9)
                     ),
                     url("https://i.ibb.co/9WQ3V2B/Rectangle-42.png")`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "70%" }}>
              <Typography variant="body2" fontWeight={600} color="#4D4D4D">
                SOLRUF &nbsp;&nbsp;&nbsp; - {timeAgo2}
              </Typography>
              <Typography variant="h5" fontWeight={600} my={1} color="#000000">
                {title2}
              </Typography>

              <Typography variant="body1" fontWeight={400} color={"#4D4D4D"}>
                {truncatedDescription2}
              </Typography>
              <Stack
                direction="row"
                justifyContent={"space-between"}
                sx={{ mt: 2 }}
              >
                {/* <Typography variant="body2" fontWeight={600} color="#666f73">
                  10 Min Read
                </Typography> */}
                <Typography
                  variant="body2"
                  fontWeight={600}
                  color="#000000"
                  sx={{
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  // onClick={() => navigate("/blogs/roadmap")}
                  onClick={() => window.open(blogsData.items[1].link, "_blank")}
                >
                  Read More
                </Typography>
              </Stack>
            </Box>
          </Box>
          <Box
            sx={{
              "& img": {
                maxWidth: "100%",
                objectFit: "cover",
                width: "100%",
                height: "100%",
                objectPosition: "0 25%", // Position the image at the bottom
              },
              width: 600,
              height: 380,
              maxWidth: "100%",
              flex: 1,
              // background: 'red',
              overflow: "hidden",
            }}
          >
            <img src={image2} alt="" />
          </Box>
        </Box>
      </Box>

      {/* <Stack
        direction="column"
        columnGap={{ xs: 1, sm: 1 }}
        sx={{
          mt: 8,
          justifyContent: "center",
          alignItems: "center",
          display: ["flex", "none"],
        }}
        flexWrap="wrap"
      >
        {blogs.map((el) => (
          <TrendingCard
            title={el.title}
            desc={el.description.slice(0, 150)}
            route={el.route}
            img={el.img}
            timeAgo={el.timeAgo}
            author={el.author}
          />
        ))}
      </Stack> */}
      <Stack
        direction="column"
        columnGap={{ xs: 1, sm: 1 }}
        sx={{
          mt: 8,
          justifyContent: "center",
          alignItems: "center",
          display: ["flex", "none"],
        }}
        flexWrap="wrap"
      >
        {blogsData.items.map((el) => {
          const imageMatch = el.description
            .toString()
            .match(/<img[^>]+src="([^">]+)"/);
          const image = imageMatch ? imageMatch[1] : null;
          const description = el.description.replace(/<img[^>]*>/g, "");
          const truncatedDescription = truncateHTML(description, 260);
          const timeAgo = getTimeAgo(el.pubDate);
          return (
            <TrendingCard
              title={el.title}
              desc={truncatedDescription}
              route={el.link}
              img={image}
              timeAgo={timeAgo}
              author="SOLRUF"
            />
          );
        })}
      </Stack>
    </Box>
  );
};

export default FourBlog;
